<template>
  <el-button
    class="basicButton"
    type="text"
    @click.prevent="clicked"
    :class="[styles, { disabled: disabled }]"
  >
    {{ title }}
  </el-button>
</template>

<script>
export default {
  name: "BasicButton",
  props: {
    styles: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked() {
      if (!this.disabled) {
        this.$emit("button-clicked");
      }
    },
  },
};
</script>

<style lang="scss">
.el-button.basicButton.el-button--text {
  border: none;
  background-color: inherit;
  font-family: $fontPrimary;
  font-size: 14px;
  color: $greyText;
  padding: 0;
  &.primary span {
    color: $special;
  }
  &.not-allowed {
    cursor: default;
  }
}
</style>
