import RequestDateTime from "@/components/timeslots/common/RequestDateTime.vue";

import ValidationErrorIcon from "@/assets/icons/svg/ValidationErrorIcon.vue";
import TwoButtonsModal from "@/components/UI/modals/TwoButtonsModal.vue";
import FinalStatus from "./FinalStatus.vue";
import CustomSelect from "@/components/UI/controls/form/CustomSelect.vue";
import { parsedPhone } from "@/core";

export default {
  components: {
    CustomSelect,
    ValidationErrorIcon,
    RequestDateTime,
    TwoButtonsModal,
    FinalStatus,
  },
  props: {
    timeslot: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    autofill: {
      type: Array,
      default: () => [],
    },
    axisCount: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isDeleteModalOpen: false,
      clearTruckFields: false,
    };
  },
  computed: {
    areInputsDisabled() {
      return !this.timeslot.plate_truck.length;
    },
    // бэк возвращает ни разу не обработанный список подсказок,
    // поэтому во всех опциях приведение к уникальности через Set
    truckOptions() {
      let uniqueTruckNumbers = new Set(
        this.autofill.map((item) => item.plate_truck)
      );
      return Array.from(uniqueTruckNumbers).map((item) => ({ value: item }));
    },
    nameOptions() {
      if (this.areInputsDisabled) {
        return [];
      }
      let drivers = this.autofill.filter(
        (item) => item.plate_truck === this.timeslot.plate_truck
      );
      let uniqueDrivers = new Set(drivers.map((item) => item.driver_name));
      return Array.from(uniqueDrivers).map((item) => ({ value: item }));
    },
    phoneOptions() {
      if (this.areInputsDisabled) {
        return [];
      }
      let drivers = this.autofill.filter(
        (item) => item.plate_truck === this.timeslot.plate_truck
      );
      let uniquePhones = new Set(drivers.map((item) => item.driver_phone));
      return Array.from(uniquePhones).map((item) => ({
        value: parsedPhone(item),
      }));
    },
    trailerOptions() {
      if (this.areInputsDisabled) {
        return [];
      }
      let drivers = this.autofill.filter(
        (item) => item.plate_truck === this.timeslot.plate_truck
      );
      return drivers.map((item) => ({ value: item.plate_trailer }));
    },
    successTitle() {
      return this.timeslot.isPaid
        ? "Таймслот забронирован и оплачен"
        : "Таймслот забронирован";
    },
  },
  methods: {
    deleteTimeslot() {
      this.$emit("delete");
      this.isDeleteModalOpen = false;
    },
  },
};
