<template>
  <section>
    <div class="carrier" @click="isModalOpen = true">
      <div>{{ buttonTitle }}</div>
      <div v-if="isCarrierVerified">кликните для изменения</div>
    </div>
    <div v-if="isModalOpen" class="modal">
      <div class="modal__header" @click="isModalOpen = false">
        <img src="@/assets/images/fat-close.svg" alt="close" />
        <div class="font1-20-24 modal__header-title">
          Информация о поставщике
        </div>
      </div>

      <div class="modal__input" @click="$refs.carrier.focus()">
        <div
          :class="[
            'auto-prompt',
            { filled: carrierInn.length || isCarrierFocused },
          ]"
        >
          ИНН поставщика/перевозчика
        </div>
        <el-autocomplete
          ref="carrier"
          v-model="carrierInn"
          type="tel"
          v-mask="'############'"
          :class="[
            'auto-input',
            { filled: carrierInn.length || isCarrierFocused },
          ]"
          placement="top-start"
          :fetch-suggestions="querySearchInn"
          @focus="isCarrierFocused = true"
          @blur="isCarrierFocused = false"
          @input="checkInnLength"
          @select="setCarrier"
        >
          <template #default="{ item }">
            <span>{{ item.label }}&nbsp;</span>
            <b>{{ item.value }}</b>
          </template>
        </el-autocomplete>
      </div>

      <MainButton
        title="Проверить"
        :disabled="isCarrierButtonDisabled"
        class="modal__button"
        @button-clicked="checkInn"
      />
    </div>
  </section>
</template>

<script>
import carrierInnMixin from "./carrierInnMixin";

export default {
  name: "CarrierInnMobile",
  mixins: [carrierInnMixin],
};
</script>

<style lang="scss" scoped>
.carrier {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 48px;
  padding: 8px 14px;
  font-family: $fontPrimary;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: $special;
  text-align: center;
  border: 1px solid $special;
  border-radius: 56px;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 80px 16px 16px;
  background: #fff;
  overflow: auto;
  z-index: 7;

  &__header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    gap: 18px;
    padding-top: 16px;
    background: #fff;
    z-index: 15;

    &-title {
      font-weight: 500;
      color: $mainBlack;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 48px;
    padding-left: 16px;
    background: $bckgrnd;
    border-radius: 8px;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .auto-input {
      width: 100%;
      height: 0;
      padding: 0;
      border-width: 0;
      outline: none;
      font-size: 0;
      line-height: 0;
      background: $bckgrnd;
      transition: height 0.3s ease;
      color: $mainInputValue;
      &.filled {
        height: 18px;
        line-height: 18px;
        font-size: 16px;
        font-weight: 500;
      }
      :deep(.el-input) {
        font-size: 16px;
      }
      :deep(.el-input__inner) {
        height: inherit;
        padding: 0;
        border: none;
        line-height: inherit;
        color: $mainBlack;
        background-color: inherit;
      }
    }
    .auto-prompt {
      font-size: 14px;
      color: $greyText;
      transition: all 0.3s ease;
      &.filled {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__button {
    margin-top: 24px;
    width: 100%;
  }
}
</style>
