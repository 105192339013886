<template>
  <div class="timeslot" @click="$emit('toTimeslots')">
    <div class="timeslot__header">
      <div class="header__main">
        <TimeslotTruckNumber :truck-number="timeslot.plate_truck" />

        <div class="header__main-badges">
          <TimeslotStatus
            :title="paymentBadge.title"
            :styles="paymentBadge.styles"
          />

          <TimeslotStatus
            :title="statusBadge.title"
            :styles="statusBadge.styles"
          />
        </div>
      </div>
      <div class="font2-12-16 header__time">Время действия</div>

      <TimeslotDateTime :timeslot="timeslot" />
    </div>

    <div class="font2-14-18 timeslot__body">
      <TimeslotTerminalName :terminal-name="timeslot.terminal_name" />

      <TimeslotCultureName :culture-name="timeslot.culture_name" />

      <TimeslotExporterName :exporter-name="timeslot.exporter_name" />
    </div>

    <div v-if="isFull" class="timeslot__buttons">
      <CancelButton
        title="Удалить"
        class="timeslot__buttons-short"
        @button-clicked="openDeleteModal = true"
      />

      <MainButton
        title="Перенести"
        class="timeslot__buttons-short"
        @button-clicked="openRescheduleModal = true"
      />

      <MainButton
        v-if="!timeslot.is_paid"
        :title="paymentButtonText"
        class="timeslot__buttons-long"
        @button-clicked="paymentHandler"
      />
    </div>

    <TimeslotDeleteModal
      v-if="openDeleteModal"
      :timeslot="timeslot"
      :is-loading="isLoading"
      @close="openDeleteModal = false"
      @accept="deleteTimeslot"
    />

    <TimeslotRescheduleModal
      v-if="openRescheduleModal"
      :timeslot="timeslot"
      @close="openRescheduleModal = false"
      @success="rescheduledTimeslot"
    />
  </div>
</template>

<script>
import TimeslotDateTime from "@/components/timeslots/common/TimeslotDateTime";
import TimeslotTruckNumber from "@/components/timeslots/common/TimeslotTruckNumber";
import TimeslotCultureName from "@/components/timeslots/common/TimeslotCultureName";
import TimeslotTerminalName from "@/components/timeslots/common/TimeslotTerminalName.vue";
import TimeslotExporterName from "@/components/timeslots/common/TimeslotExporterName.vue";
import TimeslotStatus from "@/components/timeslots/common/TimeslotStatus.vue";
import TimeslotDeleteModal from "@/components/UI/modals/TimeslotDeleteModal.vue";
import TimeslotRescheduleModal from "@/components/UI/modals/TimeslotRescheduleModal/TimeslotRescheduleModal";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import CancelButton from "@/components/UI/controls/buttons/CancelButton.vue";
import { statusStyles } from "@/components/timeslots/common/statusStyles";
import { formattedNumberRu } from "@/core";

import Api from "@/api";
export default {
  name: "TimeslotCard",
  components: {
    TimeslotDateTime,
    TimeslotTruckNumber,
    TimeslotTerminalName,
    TimeslotCultureName,
    TimeslotExporterName,
    TimeslotStatus,
    TimeslotDeleteModal,
    TimeslotRescheduleModal,
    MainButton,
    CancelButton,
  },
  props: {
    timeslot: {
      type: Object,
      default: () => ({}),
    },
    isFull: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openDeleteModal: false,
      openRescheduleModal: false,
      isLoading: false,
    };
  },
  computed: {
    paymentBadge() {
      return {
        title: this.timeslot.is_paid ? "Оплачен" : "Не оплачен",
        styles: this.timeslot.is_paid ? "green" : "red",
      };
    },
    statusBadge() {
      return {
        title: this.timeslot.status,
        styles:
          statusStyles.find((item) => item.title === this.timeslot.status)
            ?.style || "",
      };
    },
    paymentButtonText() {
      return this.timeslot.is_paid
        ? ""
        : `Оплатить ${formattedNumberRu(
            this.timeslot.timeslot_price_cop / 100
          )} ₽`;
    },
  },
  methods: {
    async deleteTimeslot() {
      try {
        this.isLoading = true;
        let params = {
          terminal_id: this.timeslot.terminal_id,
          timeslot_id: this.timeslot.id,
        };

        await Api.timeslots.deleteTimeslot(params);
        this.$emit("deleted");
      } catch (error) {
        this.errorInformer(error.data[0].message, 65);
      } finally {
        this.openDeleteModal = false;
        this.isLoading = false;
      }
    },
    rescheduledTimeslot() {
      this.$emit("rescheduled");
      this.openRescheduleModal = false;
    },
    paymentHandler() {
      this.$emit(
        "pay",
        [
          {
            terminal_id: this.timeslot.terminal_id,
            timeslot_id: this.timeslot.id,
          },
        ],
        this.timeslot.timeslot_price_cop
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.timeslot {
  padding: 24px;
  border: 1px solid $backgroundGray;
  border-radius: 20px;
  background: #fff;
  @media (max-width: 1439px) {
    padding: 16px;
  }
  &:hover {
    border: 1px solid $special;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-bottom: 12px;
    border-bottom: 1px solid $backgroundGray;
    .header__main {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-badges {
        display: flex;
        gap: 4px;
      }
    }
    .header__time {
      font-weight: 500;
      color: $greyText;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 12px;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;
    padding: 16px 0 0;
    border-top: 1px solid $backgroundGray;
    @media (max-width: 1279px) {
      justify-content: space-between;
      gap: 0;
      row-gap: 8px;
      &-short {
        width: 49%;
      }
      &-long {
        width: 100%;
      }
    }
  }
}
</style>
