var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select"},[_c('div',{class:['select__main', { disabled: _vm.disabled }],on:{"click":_vm.toggleDropdown}},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{class:[
          'select__main-title font2-14-18',
          { filled: _vm.selectedItem.length },
        ]},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),(_vm.selectedItem.length)?_c('div',{staticClass:"select__main-value font2-16-20"},[_vm._v(" "+_vm._s(_vm.selectedItem)+" ")]):_vm._e()]),_c('div',{class:['select__icon', { open: _vm.isDropdown }, { disabled: _vm.disabled }],on:{"click":function($event){$event.stopPropagation();return _vm.iconClick.apply(null, arguments)}}})]),_c('div',{class:[
      'select__dropdown',
      { open: _vm.isDropdown },
      { mobile: _vm.isMobile },
      { 'no-scroll': _vm.options.length < 6 },
    ],style:(_vm.isDropdown && !_vm.isMobile ? `height: ${_vm.options.length * 48}px;` : '')},[(_vm.isDropdown && _vm.isMobile)?_c('div',{class:['select__dropdown-header', { open: _vm.isDropdown }]},[_c('img',{attrs:{"src":require("@/assets/images/fat-close.svg"),"alt":"close"},on:{"click":function($event){_vm.isDropdown = false}}}),_c('div',{staticClass:"font1-20-24 header__title"},[_vm._v(_vm._s(_vm.placeholder))])]):_vm._e(),_vm._l((_vm.options),function(item){return _c('div',{key:item.id,class:[
        'select__dropdown-item font2-16-20',
        { active: item.id === _vm.result },
      ],on:{"click":function($event){return _vm.setResult(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }