import auth from "@/api/auth";
import home from "@/api/home";
import timeslots from "@/api/timeslots";
import profile from "@/api/profile";
import cities from "@/api/cities";
import contacts from "@/api/contacts";
import terminals from "@/api/terminals";
import handbook from "@/api/handbook";
import statistics from "@/api/statistics";
import unpaid from "@/api/unpaid";

export default {
  auth,
  home,
  timeslots,
  profile,
  cities,
  contacts,
  terminals,
  handbook,
  statistics,
  unpaid,
};
