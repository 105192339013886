<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 154 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.0133 2.21326C33.6111 2.21326 34.8816 2.63676 35.8056 3.48376C36.7296 4.33076 37.1916 5.48575 37.1916 6.94876V7.19902C37.1916 8.68127 36.7296 9.85551 35.8056 10.7025C34.8816 11.5495 33.6111 11.973 32.0133 11.973H26.5078V17.1128H23.4086V2.21326H32.0133ZM33.4763 8.50801C33.8421 8.21926 34.0153 7.77652 34.0153 7.19902V6.94876C34.0153 6.39051 33.8421 5.96701 33.4763 5.65901C33.1106 5.37026 32.5908 5.21625 31.9171 5.21625H26.4886V8.95076H31.9171C32.5908 8.95076 33.1106 8.79676 33.4763 8.50801Z"
      :fill="fill"
    />
    <path
      d="M40.9831 16.6893C40.0398 16.2273 39.2891 15.5536 38.7886 14.6873C38.2688 13.8211 38.0186 12.8008 38.0186 11.6073V11.3186C38.0186 10.1058 38.2688 9.06633 38.7693 8.18083C39.2698 7.29533 40.0013 6.62158 40.9446 6.15958C41.8878 5.69758 43.0236 5.44733 44.3326 5.44733C45.6223 5.44733 46.7388 5.67833 47.6821 6.15958C48.6253 6.62158 49.3376 7.29533 49.8381 8.18083C50.3386 9.06633 50.5888 10.1058 50.5888 11.3186V11.6073C50.5888 12.7816 50.3386 13.8211 49.8381 14.6873C49.3376 15.5536 48.6061 16.2273 47.6821 16.6893C46.7388 17.1513 45.6223 17.3823 44.3326 17.3823C43.0621 17.3823 41.9263 17.1513 40.9831 16.6893ZM46.6811 13.6478C47.2393 13.1666 47.5281 12.4736 47.5281 11.5881V11.3378C47.5281 10.4138 47.2586 9.70158 46.7003 9.20108C46.1421 8.70058 45.3528 8.45034 44.3326 8.45034C43.2931 8.45034 42.5038 8.70058 41.9456 9.18183C41.3873 9.68234 41.1178 10.3946 41.1178 11.3186V11.5688C41.1178 12.4543 41.4066 13.1473 41.9648 13.6286C42.5231 14.1098 43.3123 14.3601 44.3326 14.3601C45.3528 14.3793 46.1228 14.1291 46.6811 13.6478Z"
      :fill="fill"
    />
    <path
      d="M60.0987 5.73604V8.6428H57.7887C56.499 8.6428 55.575 8.9893 55.0552 9.70155V17.0936H52.0715V5.7168H54.8627L54.9397 6.75629C55.806 6.06329 56.884 5.7168 58.1737 5.7168H60.0987V5.73604Z"
      :fill="fill"
    />
    <path
      d="M68.1455 8.62356V12.9356C68.1455 13.3398 68.261 13.6671 68.4727 13.8788C68.6845 14.0906 68.9925 14.2061 69.3967 14.2061H71.8415V17.0936H69.0117C67.8182 17.0936 66.8942 16.7471 66.2012 16.0348C65.5082 15.3226 65.1617 14.3793 65.1617 13.1666V8.62356H61.6967V5.71681H64.4687C64.7575 5.71681 64.95 5.65905 65.0655 5.54356C65.181 5.42805 65.2387 5.19706 65.2387 4.88906V2.71381H68.1455V5.73606H72.4575V8.64281H68.1455V8.62356Z"
      :fill="fill"
    />
    <path
      d="M73.7668 5.27401V2.21326H87.4921V5.27401H82.1983V17.0935H79.0606V5.27401H73.7668Z"
      :fill="fill"
    />
    <path
      d="M96.8081 5.73604V8.6428H94.4981C93.2083 8.6428 92.2843 8.9893 91.7646 9.70155V17.0936H88.7808V5.7168H91.5721L91.6491 6.75629C92.5153 6.06329 93.5933 5.7168 94.8831 5.7168H96.8081V5.73604Z"
      :fill="fill"
    />
    <path
      d="M108.127 6.77548C109.167 7.66098 109.687 8.91223 109.687 10.51V17.0935H106.895L106.799 16.0347C105.798 16.9202 104.374 17.3822 102.545 17.3822C101.043 17.3822 99.869 17.0742 99.0605 16.4775C98.2328 15.8807 97.8286 15.0337 97.8286 13.9172C97.8286 12.05 99.0221 10.9527 101.39 10.664L106.645 10.0287C106.53 9.48973 106.222 9.08548 105.721 8.79673C105.221 8.50798 104.547 8.37323 103.681 8.37323C102.834 8.37323 102.179 8.50798 101.698 8.77748C101.217 9.04698 100.966 9.41273 100.966 9.87473V9.95173H97.9055V9.87473C97.9055 8.98923 98.1558 8.19998 98.6371 7.54548C99.1183 6.87173 99.7921 6.37123 100.678 6.00548C101.563 5.63973 102.583 5.46648 103.758 5.46648C105.625 5.44723 107.069 5.88998 108.127 6.77548ZM105.144 14.283C105.856 13.975 106.376 13.5707 106.684 13.0317V12.3195L102.16 12.8585C101.736 12.897 101.409 13.0125 101.178 13.1857C100.928 13.359 100.812 13.5707 100.812 13.821C100.812 14.4177 101.428 14.7257 102.68 14.7257C103.604 14.745 104.431 14.591 105.144 14.283Z"
      :fill="fill"
    />
    <path
      d="M122.199 6.96808C123.123 7.98833 123.585 9.48984 123.585 11.4918V17.0936H120.582V11.4918C120.582 10.5101 120.332 9.75933 119.851 9.23958C119.369 8.71983 118.638 8.45034 117.714 8.45034C117.059 8.45034 116.482 8.60434 115.962 8.93159C115.442 9.25884 115.038 9.70158 114.769 10.2598V17.0936H111.766V5.71683H114.538L114.615 6.81408C115.519 5.92858 116.694 5.48583 118.118 5.44733C119.908 5.44733 121.256 5.96708 122.199 6.96808Z"
      :fill="fill"
    />
    <path
      d="M136.444 8.52734L129.706 14.3023H136.444V17.0936H125.644V14.3023L132.382 8.54659H125.644V5.73608H136.444V8.52734Z"
      :fill="fill"
    />
    <path
      d="M138.581 3.8495C138.235 3.503 138.062 3.02175 138.062 2.44425C138.062 1.86675 138.235 1.40475 138.581 1.039C138.928 0.692498 139.371 0.5 139.929 0.5C140.487 0.5 140.949 0.673248 141.276 1.039C141.623 1.3855 141.777 1.86675 141.777 2.44425C141.777 3.02175 141.604 3.48375 141.257 3.8495C140.911 4.196 140.468 4.3885 139.91 4.3885C139.371 4.3885 138.928 4.21525 138.581 3.8495ZM141.411 17.0935H138.427V5.71675H141.411V17.0935Z"
      :fill="fill"
    />
    <path
      d="M149.688 8.62356V12.9356C149.688 13.3398 149.804 13.6671 150.015 13.8788C150.227 14.0906 150.535 14.2061 150.939 14.2061H153.384V17.0936H150.554C149.361 17.0936 148.437 16.7471 147.744 16.0348C147.051 15.3226 146.704 14.3793 146.704 13.1666V8.62356H143.239V5.71681H146.011C146.3 5.71681 146.493 5.65905 146.608 5.54356C146.724 5.42805 146.781 5.19706 146.781 4.88906V2.71381H149.707V5.73606H154V8.64281H149.688V8.62356Z"
      :fill="fill"
    />
    <path
      d="M3.773 2.23236V4.79262C3.773 5.42787 3.29175 5.94762 2.67575 6.00537C2.63725 6.00537 0 6.00537 0 6.00537V17.0741H11.242V14.5331C11.242 13.8979 11.7233 13.3781 12.3393 13.3204C12.3778 13.3204 15.015 13.3204 15.015 13.3204V2.25161H3.773V2.23236ZM9.85601 6.21711C10.4913 6.21711 11.011 6.73686 11.011 7.37211V11.9344C11.011 12.5696 10.4913 13.0894 9.85601 13.0894H5.13975C4.5045 13.0894 3.98475 12.5696 3.98475 11.9344V7.37211C3.98475 6.73686 4.5045 6.21711 5.13975 6.21711H9.85601Z"
      fill="#4CC0CC"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "white",
    },
    width: {
      type: String,
      default: "154",
    },
    height: {
      type: String,
      height: "18",
    },
  },
};
</script>
