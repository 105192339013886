var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select"},[_c('div',{class:['select__main', { disabled: _vm.disabled }],on:{"click":_vm.toggleDropdown}},[_vm._m(0),_c('div',[_c('div',{class:['select__main-title font2-14-18', { filled: _vm.value.length }]},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]),(_vm.value.length)?_c('div',{staticClass:"select__main-value font2-16-20"},[_vm._v(" "+_vm._s(_vm.value)+" ")]):_vm._e()])]),_c('div',{class:[
      'select__dropdown',
      { open: _vm.isDropdown },
      { mobile: _vm.isMobile },
      { 'no-scroll': _vm.options.length < 6 },
    ]},[(_vm.isDropdown && _vm.isMobile)?_c('div',{class:['select__dropdown-header', { open: _vm.isDropdown }]},[_c('img',{attrs:{"src":require("@/assets/images/fat-close.svg"),"alt":"close"},on:{"click":function($event){_vm.isDropdown = false}}}),_c('div',{staticClass:"font1-20-24 header__title"},[_vm._v(_vm._s(_vm.placeholder))])]):_vm._e(),_vm._l((_vm.options),function(time){return _c('div',{key:time.value,class:[
        'select__dropdown-item font2-14-18',
        { active: time.label === _vm.value },
      ],on:{"click":function($event){return _vm.setResult(time)}}},[_vm._v(" "+_vm._s(time.label)+" ")])})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select__main-prefix"},[_c('img',{attrs:{"src":require("@/assets/images/Clock.svg"),"alt":"clock"}})])
}]

export { render, staticRenderFns }