<template>
  <section
    class="confirm"
    v-loading="isLoading"
    element-loading-background="transparent"
  >
    <h1 class="confirm__title font1-40-48">Подтверждение</h1>

    <div class="confirm__content">
      <div v-if="phone">
        <span class="confirm__content-text"
          >Мы отправили СМС с кодом на номер
        </span>
        <span class="confirm__content-number">
          {{ parsedPhone }}
        </span>
      </div>
    </div>

    <div class="confirm__back font1-14-16" @click="backToNumber">
      Изменить номер
    </div>

    <div v-if="trueCode" class="confirm__code">{{ trueCode }}</div>

    <div class="confirm__form">
      <MainInput
        v-model="code"
        v-mask="'######'"
        title="Код из СМС"
        :class="['confirm__form-input', { 'has-error': hasError }]"
        @input="checkCodeLength"
      />
      <template v-if="hasError">
        <div class="customErrors">
          <ValidationErrorIcon />
          <div class="customErrors__text">Код неверный</div>
        </div>
      </template>

      <TimerButton
        class="confirm__form-timer font1-14-16"
        :timerInterval="timer"
        @button-clicked="getCodeAgainDesktop"
      />
    </div>
  </section>
</template>

<script>
import MainInput from "@/components/UI/controls/form/MainInput.vue";
import confirmMixin from "@/views/AuthViews/confirmView/confirmMixin";

export default {
  name: "DesktopConfirmView",
  components: {
    MainInput,
  },
  mixins: [confirmMixin],
  data() {
    return {
      code: "",
    };
  },
  computed: {
    hasError() {
      return this.code.length === this.codeLength && !this.validResponse;
    },
  },
  methods: {
    getCodeAgainDesktop() {
      this.code = "";
      this.getCodeAgain();
    },
    checkCodeLength(value) {
      this.code = value;
      this.validResponse = true;
      if (this.code.length === this.codeLength) {
        this.dispatchCode(this.code);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  width: 450px;
  &__title {
    width: fit-content;
    color: #fff;
    background-color: $mainBlack;
    position: relative;
    line-height: 48px;
    margin-bottom: 16px;
  }
  &__content {
    width: fit-content;
    color: #fff;
    font-size: 16px;
    background-color: $mainBlack;
    position: relative;
    margin-bottom: 16px;
  }
  &__back {
    position: relative;
    width: fit-content;
    background-color: $mainBlack;
    color: $special;
    font-weight: 500;
    cursor: pointer;
  }
  &__code {
    margin-top: 16px;
    color: #fff;
    font-size: 14px;
  }
  .checkForm {
    margin-top: 32px;
  }

  &__form {
    position: relative;

    &-input {
      margin-top: 32px;

      &.has-error :deep(.input) {
        border: 1px solid $error;
      }
    }

    &-timer {
      width: fit-content;
      margin-top: 22px;
      background-color: $mainBlack;
      position: relative;
    }
  }
}
</style>
