import { SET_IS_MOBILE } from "@/store/actions";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      desktopWidth: 1280,
    };
  },
  created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapMutations({
      setMobile: SET_IS_MOBILE,
    }),
    handleResize() {
      return window.innerWidth < this.desktopWidth
        ? this.setMobile(true)
        : this.setMobile(false);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
