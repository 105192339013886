<template>
  <el-checkbox v-model="checked" class="checkbox" @change="changed">
    Подтверждаю условия удаления таймслота
  </el-checkbox>
</template>

<script>
export default {
  name: "TimeslotsCheckbox",
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    changed() {
      this.$emit("changed", this.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  width: 100%;
}

.el-checkbox {
  display: flex;
  align-items: center;
}

:deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background: $error;
  border-color: $error;
}

:deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: $error;
}

:deep .el-checkbox__inner {
  width: 24px;
  height: 24px;
  &::after {
    width: 6px;
    height: 12px;
    left: 8px;
    top: 2px;
  }
  &:hover {
    border-color: $error;
  }
}

:deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: $textSecondary;
}

:deep .el-checkbox__label {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: $textSecondary;
}
</style>
