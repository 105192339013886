import TimeslotConfirmationTimer from "@/components/timeslots/common/TimeslotConfirmationTimer";
import MainButton from "@/components/UI/controls/buttons/MainButton";
import ConfirmGeneralModal from "./components/ConfirmGeneralModal.vue";
import { mapActions, mapMutations } from "vuex";
import { SET_LOADING, FETCH_PROFILE } from "@/store/actions";
import {
  numAutoValidator,
  numTrailerValidator,
} from "@/constants/rules/GetTimeslotRules";
import { diffDefaultFromNow } from "@/core";

import Api from "@/api";
export default {
  components: {
    MainButton,
    TimeslotConfirmationTimer,
    ConfirmGeneralModal,
  },
  props: {
    terminal: {
      type: Object,
      default: () => ({}),
    },
    timeslotsResponseData: {
      type: Object,
      default: () => ({}),
    },
    timeslotsRequestData: {
      type: Object,
      default: () => ({}),
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    textValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      timeslotsList: [],
      autofillList: [],
      axisCountList: [],
      isGeneralModalOpen: false,
    };
  },
  computed: {
    confirmationDuration() {
      return diffDefaultFromNow(this.timeslotsResponseData.confirm_until);
    },
    canConfirmAll() {
      let hasError = false;
      this.timeslotsList.forEach((timeslot) => {
        if (timeslot.isDeleted) return;
        if (!timeslot.plate_truck || numAutoValidator(timeslot.plate_truck)) {
          hasError = true;
        }
        if (this.settings.timeslot_confirm_fields.driver_phone) {
          if (
            !timeslot.driver_name ||
            !timeslot.driver_phone ||
            timeslot.driver_phone.length !== 18
          ) {
            hasError = true;
          }
        }
        if (this.settings.timeslot_confirm_fields.plate_trailer) {
          if (
            !timeslot.plate_trailer ||
            numTrailerValidator(timeslot.plate_trailer) ||
            !timeslot.axis_count
          ) {
            hasError = true;
          }
        }
      });
      return !hasError;
    },
    canFinishJob() {
      let deleted = this.timeslotsList.filter(
        (item) => item.isDeleted === true
      );
      let confirmed = this.timeslotsList.filter(
        (item) => item.isConfirmed === true
      );
      return deleted.length + confirmed.length === this.timeslotsList.length;
    },
  },
  watch: {
    // когда не осталось неподтвержденных и неудаленных таймслотов - завершаем процесс
    canFinishJob(value) {
      if (value) {
        this.$emit("success");
      }
    },
  },
  async created() {
    this.setLoading(true);
    try {
      // получаем список машин для автозаполнения
      let { data } = await Api.timeslots.getAutofillsList(this.terminal.id);
      this.autofillList = data;
      // при необходимости запрашиваем варианты количества осей
      if (this.settings.timeslot_confirm_fields.plate_trailer) {
        let response = await Api.handbook.getAxisCount();
        this.axisCountList = response.data.map((item) => ({
          id: item.count,
          name: item.title,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
    // формируем поля таймслота в зависимости от настроек терминала
    this.timeslotsResponseData.timeslots.forEach((item) => {
      let timeslot = {
        ...item,
        isDeleted: false,
        isConfirmed: false,
        isPaid: false,
        plate_truck: "",
        hasApiError: false,
        apiErrorText: "",
      };
      if (this.settings.timeslot_confirm_fields.driver_phone) {
        timeslot.driver_phone = "";
        timeslot.driver_name = "";
      }
      if (this.settings.timeslot_confirm_fields.plate_trailer) {
        timeslot.plate_trailer = "";
        timeslot.axis_count = "";
      }
      this.timeslotsList.push(timeslot);
    });
  },
  methods: {
    ...mapActions({
      refreshBalance: FETCH_PROFILE,
    }),
    ...mapMutations({
      setLoading: SET_LOADING,
    }),
    setField(index, field) {
      this.timeslotsList[index][field.name] = field.value;
      // для номера авто при изменении дополнительно обнуляем ошибку бэка
      if (field.name === "plate_truck") {
        this.timeslotsList[index].hasApiError = false;
        this.timeslotsList[index].apiErrorText = "";
      }
    },
    // подтверждение таймслотов
    async toConfirm(postponed = 0) {
      this.isGeneralModalOpen = false;
      this.setLoading(true);

      let unsubmittedTimeslots = this.timeslotsList.filter(
        (item) => item.isConfirmed !== true && item.isDeleted !== true
      );

      for (const timeslot of unsubmittedTimeslots) {
        // перед отправкой обнуляем ошибки таймслота
        timeslot.hasApiError = false;
        timeslot.apiErrorText = "";

        // формируем поля в зависимости от настроек терминала
        let params = new FormData();
        params.append("terminal_id", timeslot.terminal_id);
        params.append("timeslot_id", timeslot.timeslotId);
        params.append("plate_truck", timeslot.plate_truck);
        // признак постоплаты
        params.append("postpone_payment", postponed);
        if (this.settings.timeslot_confirm_fields.driver_phone) {
          params.append("driver_name", timeslot.driver_name);
          params.append("driver_phone", timeslot.driver_phone);
        }
        if (this.settings.timeslot_confirm_fields.plate_trailer) {
          params.append("plate_trailer", timeslot.plate_trailer);
          params.append("axis_count", timeslot.axis_count);
        }
        if (this.settings.timeslot_confirm_fields.carrier_id) {
          params.append("carrier_id", this.timeslotsRequestData.carrier_id);
        }

        try {
          await Api.timeslots.submitTimeslot(params);
          timeslot.isConfirmed = true;
          // признак оплаченности
          timeslot.isPaid = !postponed;
        } catch (e) {
          timeslot.hasApiError = true;
          timeslot.apiErrorText = e.data[0].message;
        }
      }
      // обновляем баланс пользователя после бронирования (если не пост-оплата)
      if (!postponed) await this.refreshBalance();
      this.setLoading(false);
    },

    // возврат к предыдущему шагу
    back() {
      this.$emit("previous-step");
    },
  },
};
