<template>
  <section :class="['tableCards', { confirmStep: isConfirmStep }]">
    <KeepAlive>
      <TerminalSelection v-if="currentStep === 1" @next="dispatchTimeslots" />
    </KeepAlive>

    <KeepAlive>
      <DispatchTimeslots
        v-if="currentStep === 2"
        :current-terminal="terminal"
        :clear="needClear"
        @next="confirmTimeslots"
        @previous-step="back"
      />
    </KeepAlive>

    <Component
      v-if="currentStep === 3"
      :is="currentConfirmComponent"
      :terminal="terminal"
      :timeslots-response-data="timeslotsResponseData"
      :timeslots-request-data="timeslotsRequestData"
      :settings="settings"
      :text-values="handbookTextValues"
      @close="$emit('success')"
      @success="$emit('success')"
      @previous-step="back"
    />
  </section>
</template>

<script>
import TerminalSelection from "@/components/timeslots/getTimeslots/components/terminalSelection/TerminalSelection.vue";
import DispatchTimeslots from "@/components/timeslots/getTimeslots/components/dispatchTimeslots/DispatchTimeslots.vue";
import ConfirmTimeslotsDesktop from "@/components/timeslots/getTimeslots/components/confirmTimeslots/ConfirmTimeslotsDesktop.vue";
import ConfirmTimeslotsMobile from "@/components/timeslots/getTimeslots/components/confirmTimeslots/ConfirmTimeslotsMobile.vue";

export default {
  name: "TimeslotsTable",
  components: {
    TerminalSelection,
    DispatchTimeslots,
    ConfirmTimeslotsDesktop,
    ConfirmTimeslotsMobile,
  },
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      terminal: {},
      needClear: false,
      timeslotsResponseData: [],
      timeslotsRequestData: {},
      settings: {},
      handbookTextValues: {},
    };
  },
  computed: {
    isConfirmStep() {
      return this.isMobile && this.currentStep === 3;
    },
    currentConfirmComponent() {
      return this.isMobile ? ConfirmTimeslotsMobile : ConfirmTimeslotsDesktop;
    },
  },
  methods: {
    // переход от первой формы ко второй
    dispatchTimeslots(data) {
      this.terminal = { ...data.terminal };
      this.needClear = true;
      this.$emit("step");
      this.$nextTick(() => (this.needClear = false));
    },
    // переход от второй таблицы к третьей
    confirmTimeslots(
      response = [],
      request = {},
      settings = {},
      textValues = {}
    ) {
      this.timeslotsResponseData = response;
      this.timeslotsRequestData = request;
      this.settings = settings;
      this.handbookTextValues = textValues;
      this.$emit("step");
    },

    back() {
      this.$emit("previous-step");
    },
  },
};
</script>

<style lang="scss" scoped>
.tableCards {
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
  @media (max-width: 1279px) {
    height: 100vh;
    padding: 0 16px;
    background: #fff;
  }

  &.confirmStep {
    height: 100vh;
    background-color: $bckgrnd;
    @media (max-width: 1279px) {
      min-height: 100vh;
      height: auto;
    }
  }
}
</style>
