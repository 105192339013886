// eslint-disable-next-line no-undef
const lsUserPhone = process.env.VUE_APP_LS_KEY + "-user-phone";

export const setUserPhone = (profile) =>
  localStorage.setItem(lsUserPhone, JSON.stringify(profile));

export const getUserPhone = () => JSON.parse(localStorage.getItem(lsUserPhone));

export const removeUserPhone = () => {
  localStorage.removeItem(lsUserPhone);
};

// eslint-disable-next-line no-undef
const lsUserAccess = process.env.VUE_APP_LS_KEY + "-user-access";

export const setUserAccess = (access) => {
  localStorage.setItem(lsUserAccess, JSON.stringify(access));
};

export const getUserAccess = () =>
  JSON.parse(localStorage.getItem(lsUserAccess));

export const removeUserAccess = () => {
  localStorage.removeItem(lsUserAccess);
};

// eslint-disable-next-line no-undef
const lsTerminalTimezone = process.env.VUE_APP_LS_KEY + "-terminal-timezone";

export const setTerminalTimezone = (zone) => {
  localStorage.setItem(lsTerminalTimezone, zone);
};

export const getTerminalTimezone = () =>
  JSON.parse(localStorage.getItem(lsTerminalTimezone));

export const removeTerminalTimezone = () => {
  localStorage.removeItem(lsTerminalTimezone);
};

// eslint-disable-next-line no-undef
const lsActiveTimeslot = process.env.VUE_APP_LS_KEY + "-active-timeslot";

export const setActiveTimeslot = (zone) => {
  localStorage.setItem(lsActiveTimeslot, zone);
};

export const getActiveTimeslot = () =>
  JSON.parse(localStorage.getItem(lsActiveTimeslot));

export const removeActiveTimeslot = () => {
  localStorage.removeItem(lsActiveTimeslot);
};
