<template>
  <section class="unpaid" v-loading="isLoading">
    <UnpaidFilter @search="sendQuery" @clear="currentTruck = null" />

    <div class="unpaid__body">
      <UnpaidTruckCard
        v-if="currentTruck"
        :truck="currentTruck"
        @toggle-favorites="openFavoritesModal = true"
        @pay="preparePayment"
      />

      <EmptyUnpaid
        v-if="!currentTruck && !isLoading"
        title="Для просмотра неоплаченных таймслотов введите номер авто"
        :is-search-page="true"
      />
    </div>

    <ConfirmPaymentModal
      v-if="openPaymentModal"
      :sum-text="paymentSumText"
      @close="openPaymentModal = false"
      @accept="payTimeslots"
    />

    <TwoButtonsModal
      v-if="openFavoritesModal"
      :title="favoriteTexts.title"
      :description="favoriteTexts.description"
      left-button="Отмена"
      :right-button="favoriteTexts.rightButton"
      :success="favoriteTexts.success"
      @close="openFavoritesModal = false"
      @accept="toggleFavorites"
    />

    <NoMoneyModal v-if="notEnoughMoney" @close="notEnoughMoney = false" />
  </section>
</template>

<script>
import UnpaidFilter from "./UnpaidFilter.vue";
import UnpaidTruckCard from "@/components/unpaid/UnpaidTruckCard.vue";
import NoMoneyModal from "@/components/UI/modals/NoMoneyModal.vue";
import EmptyUnpaid from "@/components/unpaid/EmptyUnpaid.vue";
import { mapActions } from "vuex";
import { FETCH_PROFILE } from "@/store/actions";

import Api from "@/api";
import ConfirmPaymentModal from "@/components/UI/modals/ConfirmPaymentModal.vue";
import { formattedNumberRu } from "@/core";
import TwoButtonsModal from "@/components/UI/modals/TwoButtonsModal.vue";
export default {
  name: "UnpaidSearchTruck",
  components: {
    TwoButtonsModal,
    NoMoneyModal,
    UnpaidFilter,
    UnpaidTruckCard,
    EmptyUnpaid,
    ConfirmPaymentModal,
  },
  data() {
    return {
      currentTruck: null,
      isLoading: false,
      notEnoughMoney: false,
      openPaymentModal: false,
      openFavoritesModal: false,
      paidTimeslots: [],
      paymentSumText: "",
    };
  },
  computed: {
    favoriteTexts() {
      if (!this.currentTruck) return null;
      let loved = this.currentTruck.is_in_favorites;
      return {
        title: loved ? "Удалить из Избранных?" : "Добавить в Избранные?",
        description: loved
          ? 'Информация по этой машине не будет отображаться в списке "Мои авто"'
          : 'Состояние оплат таймслотов на эту машину будет отображаться в списке "Мои авто"',
        rightButton: loved ? "Удалить" : "Добавить",
        success: !loved,
      };
    },
  },
  methods: {
    ...mapActions({
      refreshBalance: FETCH_PROFILE,
    }),
    async sendQuery(plateTruck) {
      try {
        this.isLoading = true;
        const { data } = await Api.unpaid.searchTruck(plateTruck);
        this.currentTruck = data.items[0];
      } catch (error) {
        console.log(error.data);
      } finally {
        this.isLoading = false;
      }
    },

    // обработка удаления из Избранных
    async toggleFavorites() {
      try {
        this.openFavoritesModal = false;
        this.isLoading = true;
        const method = this.currentTruck.is_in_favorites
          ? "removeFromFavorites"
          : "addToFavorites";
        await Api.unpaid[method]({
          plate_truck: this.currentTruck.plate_truck,
        });
        await this.sendQuery(this.currentTruck.plate_truck);
      } catch (error) {
        console.log(error.data);
      } finally {
        this.isLoading = false;
      }
    },

    //  блок списания за таймслоты
    preparePayment(timeslots, sum) {
      this.paidTimeslots = timeslots;
      this.paymentSumText = `${formattedNumberRu(sum / 100)} ₽`;
      this.openPaymentModal = true;
    },
    async payTimeslots() {
      try {
        this.openPaymentModal = false;
        this.isLoading = true;
        // оплачиваем таймслоты
        await Api.timeslots.payTimeslots(this.paidTimeslots);
        // обновляем остаток баланса
        await this.refreshBalance();
        // перезагружем карточку машины
        await this.sendQuery(this.currentTruck.plate_truck);
        let info =
          this.paidTimeslots.length > 1
            ? "Таймслоты успешно оплачены"
            : "Таймслот успешно оплачен";
        this.successInformer(info, 65);
      } catch (error) {
        if (error.data[0].field === "balance") {
          this.notEnoughMoney = true;
        } else if (error.data[0].field === "result") {
          // обработка варианта, при котором кто-то уже оплатил таймлот(-ы),
          // а мы с тех пор не обновляли экран
          // обновляем остаток баланса
          await this.refreshBalance();
          // перезагружем карточку машины
          await this.sendQuery(this.currentTruck.plate_truck);
          this.errorInformer(error.data[0].message, 65);
        } else {
          this.errorInformer(error.data[0].message, 65);
        }
      } finally {
        this.isLoading = false;
        this.paidTimeslots = [];
        this.paymentSumText = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unpaid {
  padding-top: 64px;
  @media (max-width: 1279px) {
    padding-top: 96px;
  }

  &__body {
    padding: 24px 32px;
    @media (max-width: 1279px) {
      padding: 24px 16px 102px;
    }
  }
}
</style>
