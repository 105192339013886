<template><component :is="currentView" /></template>

<script>
import DesktopTimeslotsView from "@/views/TimeslotsView/DesktopTimeslotsView";
import MobileTimeslotsView from "@/views/TimeslotsView/MobileTimeslotsView";

export default {
  name: "TimeslotsView",
  computed: {
    currentView() {
      return this.isMobile ? MobileTimeslotsView : DesktopTimeslotsView;
    },
  },
};
</script>
