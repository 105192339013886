import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import Api from "@/api";

export default {
  components: { MainButton },
  props: {
    carriersList: {
      type: Array,
      default: () => [],
    },
    forceCarrier: {
      type: Object,
      default: () => ({}),
    },
    isVerified: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carrierInn: "",
      carrierName: "",
      isCarrierButtonDisabled: true,
      isCarrierFocused: false,
      // это чисто для мобилы
      isModalOpen: false,
    };
  },
  computed: {
    isCarrierVerified: {
      get() {
        return this.isVerified;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    buttonTitle() {
      return this.isCarrierVerified
        ? `Поставщик: ${this.carrierName},`
        : "Укажите ИНН поставщика/перевозчика ";
    },
  },
  watch: {
    forceCarrier(value) {
      if (value.id) {
        this.carrierInn = value.inn;
        this.isCarrierVerified = false;
        // без nextTick не отрабатывает изменение isCarrierVerified до запроса
        this.$nextTick(async () => {
          await this.checkInn();
          this.isCarrierButtonDisabled = false;
        });
      }
    },
  },

  activated() {
    if (this.clear) {
      this.carrierInn = "";
      this.carrierName = "";
    }
  },
  methods: {
    async checkInn() {
      if (this.isCarrierVerified && !this.isMobile) {
        // нажатие на кнопку Изменить - сброс значения
        this.carrierInn = "";
        this.isCarrierVerified = false;
        this.carrierName = "";
      } else {
        // нажатие на кнопку Проверить - запрос на поиск организации по ИНН
        try {
          this.isLoading = true;
          let { data } = await Api.timeslots.checkCarrierInn(this.carrierInn);
          this.carrierName = data.name;
          this.$emit("change", true, data.id);
          this.isModalOpen = false;
          // не выводим информер, если прокинули данные из выбранного поставщика
          if (!this.forceCarrier.id) {
            this.successInformer(
              `По данному ИНН найдено: ${this.carrierName}`,
              65
            );
          }
        } catch {
          this.errorInformer("По данному ИНН ничего не найдено", 65);
        } finally {
          this.isLoading = false;
        }
      }
    },
    // обработка ввода символов в инпут
    checkInnLength() {
      // сбрасываем верификацию
      this.isCarrierVerified = false;
      // разрешаем кнопку проверки при правильной длине ИНН
      this.isCarrierButtonDisabled = ![10, 12].includes(this.carrierInn.length);
    },
    // выбор перевозчика из списка автозаполнения
    setCarrier(carrier) {
      this.carrierName = carrier.label;
      this.$emit("change", true, carrier.id);
      this.isModalOpen = false;
    },
    // автопоиск для common_supplier
    querySearchInn(queryString, cb) {
      const results = queryString
        ? this.carriersList.filter(this.createFilter(queryString))
        : this.carriersList;

      cb(results);
    },
    createFilter(queryString) {
      return (link) => link.value.indexOf(queryString) !== -1;
    },
  },
};
