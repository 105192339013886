import PhoneInput from "@/components/UI/controls/form/PhoneInput";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";

export default {
  components: { PhoneInput, MainButton },
  data() {
    return {
      phone: "",
    };
  },
  computed: {
    canLogin() {
      return this.phone.length === 18;
    },
  },
  methods: {
    async checkTerms() {
      this.$emit("get-phone", this.phone);
    },
    setPhone(value) {
      this.phone = value;
    },
  },
};
