<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0311 3.66154H3.96891L7.84251 6.82553C7.93547 6.90145 8.06453 6.90146 8.15749 6.82553L12.0311 3.66154Z"
      fill="#3D4A5D"
    />
    <path
      d="M2.55556 4.59878V12.0615C2.55556 12.2145 2.67163 12.3385 2.81481 12.3385H13.1852C13.3284 12.3385 13.4444 12.2145 13.4444 12.0615V4.59878L9.10241 8.14538C8.4517 8.67689 7.5483 8.67689 6.89759 8.14538L2.55556 4.59878Z"
      fill="#3D4A5D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 3.93846C1 2.86788 1.81252 2 2.81481 2H13.1852C14.1875 2 15 2.86788 15 3.93846V12.0615C15 13.1321 14.1875 14 13.1852 14H2.81481C1.81252 14 1 13.1321 1 12.0615V3.93846ZM2.55556 12.0615V4.59878L3.96891 3.66154H12.0311L13.4444 4.59878V12.0615C13.4444 12.2145 13.3284 12.3385 13.1852 12.3385H2.81481C2.67163 12.3385 2.55556 12.2145 2.55556 12.0615Z"
      fill="#3D4A5D"
    />
  </svg>
</template>

<script>
export default {
  name: "MailIcon",
};
</script>

<style scoped></style>
