<template>
  <div class="loginView">
    <component :is="currentView" @get-phone="checkTerms" />
    <PrivacyPolicyModal
      v-if="isModalOpen"
      :user="user"
      @agreed="sendQuery"
      @close="isModalOpen = false"
    />
  </div>
</template>

<script>
import DesktopLoginView from "@/views/AuthViews/loginView/DesktopLoginView";
import MobileLoginView from "@/views/AuthViews/loginView/MobileLoginView";
import PrivacyPolicyModal from "@/components/UI/modals/PrivacyPolicy/PrivacyPolicyModal";
import { setUserPhone } from "@/core";
import API from "@/api";

export default {
  name: "LoginView",
  components: { DesktopLoginView, MobileLoginView, PrivacyPolicyModal },
  computed: {
    currentView() {
      return this.isMobile ? MobileLoginView : DesktopLoginView;
    },
  },
  data() {
    return {
      user: null,
      isModalOpen: false,
    };
  },
  methods: {
    async checkTerms(phone) {
      const { data } = await API.auth.checkTermsAgreement({
        phone_number: phone,
      });
      this.user = data;
      if (this.user.is_accepted) {
        this.sendQuery(this.user.phone_number);
      } else {
        this.isModalOpen = true;
      }
    },
    async sendQuery() {
      this.isModalOpen = false;
      const phone = new FormData();
      phone.append("phone", this.user.phone_number);

      try {
        const { data } = await API.auth.dispatchPhone(phone);
        setUserPhone(data);
        await this.$router.push({ name: "confirm" });
      } catch (error) {
        this.errorInformer(error.data[0].message);
      }
    },
  },
};
</script>
