<template>
  <component
    :title="isRequestMode ? 'Перенос таймслота' : 'Подтверждение переноса'"
    :is="currentWrapper"
    @close="$emit('close')"
  >
    <div class="reschedule">
      <div
        v-if="isRequestMode && !isMobile"
        class="font1-24-30 reschedule__title"
      >
        Перенос таймслота
      </div>
      <RescheduleRequestForm
        v-if="isRequestMode"
        :timeslot="timeslot"
        @found="goToConfirm"
        @close="$emit('close')"
      />
      <RescheduleConfirmForm
        v-else
        :timeslot="availableTimeslot"
        :old-id="timeslot.id"
        @confirmed="$emit('success')"
        @close="$emit('close')"
      />
    </div>
  </component>
</template>

<script>
import ModalWrapper from "@/components/UI/ModalWrapper";
import FullscreenMobileModal from "@/components/UI/modals/FullscreenMobileModal.vue";
import RescheduleRequestForm from "@/components/UI/modals/TimeslotRescheduleModal/RescheduleRequestForm";
import RescheduleConfirmForm from "@/components/UI/modals/TimeslotRescheduleModal/RescheduleConfirmForm";
import { mapGetters } from "vuex";
import { GET_FULL_TERMINAL_LIST } from "@/store/actions";
import { setTerminalTimezone, removeTerminalTimezone } from "@/core";

export default {
  name: "TimeslotRescheduleModal",
  components: {
    ModalWrapper,
    FullscreenMobileModal,
    RescheduleRequestForm,
    RescheduleConfirmForm,
  },
  props: {
    timeslot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isRequestMode: true,
      availableTimeslot: null,
    };
  },
  computed: {
    ...mapGetters({
      terminalsList: GET_FULL_TERMINAL_LIST,
    }),
    currentWrapper() {
      return this.isMobile ? FullscreenMobileModal : ModalWrapper;
    },
  },
  mounted() {
    const currentTerminal = this.terminalsList.find(
      (item) => item.id === this.timeslot.terminal_id
    );
    setTerminalTimezone(currentTerminal.timezone_utc_offset);
  },
  beforeDestroy() {
    removeTerminalTimezone();
  },
  methods: {
    goToConfirm(availableTimeslot) {
      this.availableTimeslot = availableTimeslot;
      this.isRequestMode = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.reschedule {
  width: 440px;
  @media (max-width: 1279px) {
    width: 100%;
  }

  &__title {
    margin-bottom: 24px;
    color: $mainBlack;
    @media (max-width: 1279px) {
      text-align: center;
    }
  }
}
:deep .form__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  &-item.el-button.is-plain {
    width: 48%;
    font-size: 16px;
    @media (max-width: 1279px) {
      width: 100%;
    }
    &.long {
      width: 100%;
    }
  }
}
</style>
