<template>
  <section class="timeslots">
    <MobileHeader title="Таймслоты" />

    <MobileTimeslotsHeader class="timeslots__switcher" active="timeslots" />

    <div class="timeslots__items">
      <el-collapse-transition>
        <PayAllTimeslots
          v-if="unpaid.count"
          class="timeslots__unpaid"
          :unpaid-text="unpaidText"
          @mass-pay="handlePay(unpaid.timeslots, unpaid.sum)"
        />
      </el-collapse-transition>

      <div v-if="terminals.length && !loading">
        <div
          v-for="(terminal, index) in terminals"
          :key="index"
          class="terminal"
        >
          <div class="terminal__header" @click="toggleTerminal(terminal)">
            <div>
              <div class="font2-16-20 terminal__header-title">
                {{ terminal.terminal_name }}
              </div>
              <div class="font2-12-16 terminal__header-count">
                Количество таймслотов:&nbsp;{{ terminal.timeslots_count }}
              </div>
            </div>
            <div
              :class="['terminal__header-icon', { open: terminal.isOpen }]"
            />
          </div>
          <el-collapse-transition>
            <div v-show="terminal.isOpen" class="terminal__timeslots">
              <TimeslotCard
                v-for="timeslot in terminal.list"
                :key="timeslot.id"
                :ref="timeslot.id"
                :timeslot="timeslot"
                :is-full="true"
                @deleted="refreshList('Таймслот успешно удален')"
                @rescheduled="refreshList('Таймслот успешно перенесен')"
                @pay="handlePay"
              />
            </div>
          </el-collapse-transition>
        </div>
      </div>

      <TimeslotsEmpty
        v-if="!terminals.length && !loading"
        title="У вас пока нет активных таймслотов"
        class="timeslots__empty"
      />

      <ConfirmPaymentModal
        v-if="openPaymentModal"
        :sum-text="paymentSumText"
        @close="openPaymentModal = false"
        @accept="sendPayQuery"
      />

      <NoMoneyModal v-if="notEnoughMoney" @close="notEnoughMoney = false" />

      <div v-if="loading" class="timeslots__loading" v-loading="loading" />
    </div>
  </section>
</template>

<script>
import MobileTimeslotsHeader from "@/components/timeslots/common/MobileTimeslotsHeader";
import MobileHeader from "@/components/mobileHeader/MobileHeader";
import timeslotsMixin from "@/views/TimeslotsView/timeslotsMixin";

export default {
  name: "MobileTimeslotsView",
  components: {
    MobileTimeslotsHeader,

    MobileHeader,
  },
  mixins: [timeslotsMixin],
};
</script>

<style lang="scss" scoped>
.timeslots {
  &__items {
    min-height: 100vh;
    padding: 120px 0 60px;
    background: $bckgrnd;
  }
  &__empty {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
  }
  &__loading {
    height: 300px;
    margin-top: 64px;
    :deep .el-loading-mask {
      background-color: $bckgrnd;
    }
  }

  .terminal {
    margin-bottom: 8px;
    padding: 16px;
    background: #fff;
    border-radius: 16px;

    &__header {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      &-title {
        margin-bottom: 2px;
        font-weight: 700;
        color: $mainBlack;
      }

      &-count {
        font-weight: 500;
        color: $greyText;
      }

      &-icon {
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        transition: transform 0.3s ease;
        cursor: pointer;
        background-image: url("~@/assets/images/angle-down-icon.svg");
        &.open {
          transform: rotate(180deg);
        }
      }
    }

    &__timeslots {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 16px;
    }
  }
}
</style>
