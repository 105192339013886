export const numAutoValidator = (plate) => {
  if (plate && plate.length) {
    let numAuto = plate.toLowerCase();
    return (
      !/^[a-zA-zА-Яа-я]{2}[0-9]{6,7}$/.test(numAuto) &&
      !/^[a-zA-zА-Яа-я]{1}[0-9]{3}[a-zA-zА-Яа-я]{2}[0-9]{2,3}$/.test(numAuto) &&
      !/^[0-9]{4}[a-zA-zА-Яа-я]{3}$/.test(numAuto) &&
      !/^[a-zA-zА-Яа-я]{1}[0-9]{4}[a-zA-zА-Яа-я]{2}$/.test(numAuto)
    );
  }
  return false;
};

export const numTrailerValidator = (numAuto) =>
  !/^[a-zA-zА-Яа-я]{2}[0-9]{6}$/.test(numAuto);

export const rulesFormAddTimeslot = {
  plate_truck: [
    {
      validator: (rule, value, callback) => {
        !value || !value.length ? callback(new Error()) : callback();
      },
      message: "Заполните номер авто",
      trigger: "blur",
    },
    {
      validator: (rule, value, callback) => {
        numAutoValidator(value) ? callback(new Error()) : callback();
      },
      message:
        'Формат номера должен быть "м213тт99" или "1212ттт" или "e2233кк"',
      trigger: "blur",
    },
  ],
  driver_phone: [
    {
      required: true,
      message: "Введите Ваш номер телефона",
      trigger: "blur",
    },
    {
      min: 18,
      max: 18,
      message: "Некорректный номер телефона",
      trigger: "blur",
    },
  ],
  driver_name: [
    {
      min: 2,
      message: "Укажите данные водителя",
      trigger: "blur",
    },
  ],
  plate_trailer: [
    {
      validator: (rule, value, callback) => {
        !value || !value.length ? callback(new Error()) : callback();
      },
      message: "Заполните номер прицепа",
      trigger: "blur",
    },
    {
      validator: (rule, value, callback) => {
        numTrailerValidator(value) ? callback(new Error()) : callback();
      },
      message: 'Формат номера должен быть "мт212121"',
      trigger: "blur",
    },
  ],
  axis_count: [
    {
      required: true,
      message: "Укажите количество осей",
      trigger: "blur",
    },
  ],
};

export const confirmTimeslotMessages = {
  plate_truck:
    'Формат номера должен быть "м213тт99" или "1212ттт" или "e2233кк"',
  plate_trailer: 'Формат номера должен быть "мт212121"',
  driver_phone: "Некорректный номер телефона",
  driver_name: "Укажите данные водителя",
};
