<template>
  <section><component :is="currentView" /></section>
</template>

<script>
import DesktopUnpaidView from "@/views/UnpaidView/DesktopUnpaidView.vue";
import MobileUnpaidView from "@/views/UnpaidView/MobileUnpaidView.vue";

export default {
  name: "PaymentView",
  computed: {
    currentView() {
      return this.isMobile ? MobileUnpaidView : DesktopUnpaidView;
    },
  },
};
</script>
