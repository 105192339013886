// формат числа с пробелами между разрядами (напр., 50 250 151)
export const formattedNumberRu = (number, fraction = 0) =>
  Intl.NumberFormat("ru-RU", { minimumFractionDigits: 0 }).format(
    Number(number).toFixed(fraction)
  );

// скачивание файла по ссылке
export const downloadPdfFile = (url) => {
  const linkElement = document.createElement("a");
  linkElement.href = url;
  linkElement.setAttribute("target", "_blank");
  linkElement.setAttribute("type", "application/pdf");
  linkElement.setAttribute("download", "");
  document.body.appendChild(linkElement);
  linkElement.click();
  linkElement.remove();
};

// переход по ссылке в текущем окне
export const redirectToUrl = (url) => {
  const linkElement = document.createElement("a");
  linkElement.href = url;
  document.body.appendChild(linkElement);
  linkElement.click();
  linkElement.remove();
};

// номер телефона из БД в формате маски
export const parsedPhone = (phone) =>
  `${phone.slice(0, 2)} (${phone.slice(2, 5)}) ${phone.slice(
    5,
    8
  )}-${phone.slice(8, 10)}-${phone.slice(10)}`;

// номер телефона в формате "чисто цифры"
export const purePhone = (phone) => phone.replace(/\D/g, "");
