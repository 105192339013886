<template>
  <div class="inn">
    <div class="inn__input" @click="$refs.carrier.focus()">
      <div
        :class="[
          'auto-prompt',
          { filled: carrierInn.length || isCarrierFocused },
        ]"
      >
        ИНН поставщика/перевозчика
      </div>
      <el-autocomplete
        ref="carrier"
        v-model="carrierInn"
        v-mask="'############'"
        :class="[
          'auto-input',
          { filled: carrierInn.length || isCarrierFocused },
        ]"
        placement="top-start"
        :fetch-suggestions="querySearchInn"
        @focus="isCarrierFocused = true"
        @blur="isCarrierFocused = false"
        @input="checkInnLength"
        @select="setCarrier"
      >
        <template #default="{ item }">
          <span>{{ item.label }}&nbsp;</span>
          <b>{{ item.value }}</b>
        </template>
      </el-autocomplete>
    </div>
    <MainButton
      :title="isCarrierVerified ? 'Изменить' : 'Проверить'"
      :disabled="isCarrierButtonDisabled"
      class="inn__button"
      @button-clicked="checkInn"
    />
    <div v-if="carrierName && isCarrierVerified" class="font2-12-16 inn__name">
      Поставщик:&nbsp;{{ carrierName }}
    </div>
  </div>
</template>

<script>
import carrierInnMixin from "./carrierInnMixin";
export default {
  name: "CarrierInnDesktop",
  mixins: [carrierInnMixin],
};
</script>

<style lang="scss" scoped>
.inn {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 16px;
  background: $bckgrnd;
  border-radius: 10px;

  &__input {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .auto-input {
      width: 100%;
      height: 0;
      padding: 0;
      border-width: 0;
      outline: none;
      font-size: 0;
      line-height: 0;
      background: $bckgrnd;
      transition: height 0.3s ease;
      color: $mainInputValue;
      &.filled {
        height: 18px;
        line-height: 18px;
        font-size: 16px;
        font-weight: 500;
      }
      :deep(.el-input) {
        font-size: inherit;
      }
      :deep(.el-input__inner) {
        height: inherit;
        padding: 0;
        border: none;
        line-height: inherit;
        color: $mainBlack;
        background-color: inherit;
      }
    }
    .auto-prompt {
      font-size: 14px;
      color: $greyText;
      transition: all 0.3s ease;
      &.filled {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__button.el-button--primary.is-plain {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__name {
    position: absolute;
    bottom: -16px;
    font-weight: 500;
    color: $special;
  }
}
</style>
