<template>
  <section class="wrapper">
    <div class="document">
      <div class="title">
        <h1>
          Согласие на получение рекламной информации (в том числе в форме
          рекламной рассылки)
        </h1>
      </div>

      <div class="block">
        <p class="item">
          Действуя свободно, своей волей и в своем интересе, а также подтверждая
          свою дееспособность, в соответствии с Федеральным законом от
          13.03.2006 № 38-ФЗ «О рекламе»,
        </p>
      </div>

      <div class="block">
        <p class="item">
          Я даю свое согласие ООО «Порт транзит», 121205, г. Москва, Внутренний
          территориальный городской муниципальный округ Можайский, тер. Сколково
          инновационного центра, б-р Большой, д. 42, стр. 1, пом. 138 (далее —
          Общество), на получение рекламной информации (в том числе в форме
          рекламной рассылки) о специальных/персональных предложениях, акциях,
          новых услугах, событиях, скидках и иных сведений, направляемых
          посредством:
        </p>
        <ul class="list">
          <li>push-уведомлений;</li>
          <li>e-mail рассылок на адрес электронной почты;</li>
          <li>SMS-сообщений;</li>
          <li>
            сайта
            <span :href="baseUrl" target="_blank" class="link">
              https://www.zernovozam.ru
            </span>
          </li>
        </ul>
      </div>

      <div class="block">
        <p class="item">
          Отказаться от получения рекламной информации (в том числе в форме
          рекламной рассылки) можно в любой момент любым из следующих способов:
        </p>
        <ul class="list">
          <li>
            Посредством направления в свободной форме письменного заявления по
            адресу Общества, указанному выше, с обязательным указанием фамилии,
            имени и отчества, номера мобильного телефона и адреса электронной
            почты;
          </li>
          <li>
            Посредством отписки от рекламной рассылки через кнопку «Отписаться»,
            расположенную в каждом направляемом письме на адрес электронной
            почты;
          </li>
          <li>
            В Личном кабинете на сайте
            <span :href="baseUrl" target="_blank" class="link">
              https://www.zernovozam.ru
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import urlMixin from "@/views/Documents/urlMixin";
export default {
  name: "AdvertisingAgreement",
  mixins: [urlMixin],
};
</script>

<style lang="scss" scoped>
@import "./styles";
</style>
