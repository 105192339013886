import MainButton from "@/components/UI/controls/buttons/MainButton.vue";

export default {
  components: { MainButton },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
};
