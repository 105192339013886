<template>
  <section class="header">
    <div
      v-for="item in timeslotTabs"
      :key="item.name"
      :class="['font2-14-18 header__item', { active: active === item.route }]"
      @click="navigation(item.route)"
    >
      {{ item.name }}
    </div>
  </section>
</template>

<script>
import { timeslotTabs } from "@/constants/timeslot-tabs";
export default {
  name: "MobileTimeslotsHeader",
  props: {
    active: {
      type: String,
      default: "timeslots",
    },
  },
  data() {
    return {
      timeslotTabs,
    };
  },
  methods: {
    navigation(mode) {
      if (mode !== this.active) {
        this.$router.push({ name: mode });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 48px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: #fff;
  border-bottom: 1px solid $backgroundGray;
  z-index: 1;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 38px;
    font-size: clamp(14px, 1.2rem, 16px);
    font-weight: 700;
    color: $greyText;
    border-bottom: 1px solid $greyBanner;
    cursor: pointer;
    &:hover,
    &.active {
      color: $mainBlack;
      border-bottom: 2px solid $special;
    }
  }
}
</style>
