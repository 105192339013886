<template>
  <section class="mobile__modal">
    <div :class="['mobile__modal-content', bgc]" @click.stop>
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: "MobileModalWrapper",
  props: {
    bgc: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: rgba(33, 40, 50, 0.22);
  z-index: 20;

  &-content {
    position: relative;
    background: #fff;
    width: 100%;
    padding: 16px 16px 40px 16px;
    border-radius: 12px 12px 0 0;
  }
  .bckgrnd {
    background-color: $bckgrnd;
  }
}
</style>
