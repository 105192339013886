<template>
  <ModalWrapper @close="$emit('close')">
    <div class="content">
      <div class="font1-24-30 content__title">{{ title }}</div>

      <div v-if="description.length" class="font2-16-20 content__description">
        {{ description }}
      </div>

      <Component
        :is="currentButtonComponent"
        :title="buttonTitle"
        @button-clicked="$emit('accept')"
      />
    </div>
  </ModalWrapper>
</template>

<script>
import ModalWrapper from "@/components/UI/ModalWrapper";
import AlarmButton from "@/components/UI/controls/buttons/AlarmButton.vue";
import MainButton from "@/components/UI/controls/buttons/MainButton";
export default {
  name: "OneButtonMobileModal",
  components: {
    ModalWrapper,
    AlarmButton,
    MainButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    buttonTitle: {
      type: String,
      default: "",
    },
    success: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currentButtonComponent() {
      return this.success ? MainButton : AlarmButton;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 368px;
  text-align: center;
  @media (max-width: 500px) {
    width: 304px;
  }
  &__title {
    margin-bottom: 14px;
    font-weight: 500;
    color: $mainBlack;
  }
  &__description {
    margin-bottom: 26px;
    font-weight: 500;
    color: $greyText;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 47%;
      height: 44px;
      border-radius: 40px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      &.mod-cancel {
        border: 1px solid $greyBanner;
        color: $greyText;
        &:hover {
          background: $greyBanner;
          color: #fff;
        }
        @media (max-width: 1279px) {
          border: 1px solid $special;
          color: $special;
          &:hover {
            background: #fff;
            color: $special;
          }
        }
      }
      &.mod-accept {
        color: $error;
        border: 1px solid $error;
        &:hover {
          background: $error;
          color: #fff;
        }
        @media (max-width: 1279px) {
          color: #fff;
          background-color: $error;
          border: 1px solid $error;
        }
      }
    }
    .disabled {
      pointer-events: none;
      opacity: 0.4;
    }
    :deep .button {
      width: 47%;
    }
  }
}
@media (max-width: 450px) {
  .content {
    width: 250px;
    &__title {
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 6px;
    }
    &__description {
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 18px;
    }
    &__buttons {
      margin-top: 0;
    }
  }
}
</style>
