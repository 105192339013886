import { Request } from "@/core";
import { contacts } from "@/constants/urls";

export default {
  getContacts() {
    return Request.get(contacts.getContacts);
  },

  sendForm(data) {
    return Request.post(contacts.sendForm, data);
  },
};
