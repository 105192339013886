<template>
  <section v-if="content" class="banner">
    <div class="banner__content">
      <img src="@/assets/images/warning.svg" alt="icon" />

      <div class="banner__content-info">
        <div class="font2-12-16 info__data">{{ date }}</div>
        <div class="font2-14-18 info__text">
          {{ content.text }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { parsedDateTime } from "@/core";
export default {
  name: "HomeMessage",
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    date() {
      return parsedDateTime(this.content.created_at);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 12px 20px 12px 12px;
  background: $greyBanner;
  border-radius: 16px;
  &__content {
    flex: 1;
    display: flex;
    align-items: center;
    &-info {
      width: 75%;
      margin-left: 12px;
      .info__data {
        font-weight: 500;
        color: $textSecondary;
      }
      .info__text {
        font-weight: 700;
        color: $mainBlack;
      }
    }
  }
  &__close {
    cursor: pointer;
  }
}
</style>
