<template>
  <section v-loading="isLoading" class="archive">
    <MobileHeader title="Таймслоты" />

    <MobileTimeslotsHeader active="archiveTimeslots" />

    <div class="archive__filters">
      <DatePicker
        :date="startDate"
        :options="startPickerOptions"
        placeholder="Период с"
        class="archive__filters-picker"
        @select="setStartDate"
      />

      <DatePicker
        :date="finishDate"
        :options="finishPickerOptions"
        :disabled="!startDate"
        placeholder="Период по"
        class="archive__filters-picker"
        @select="setFinishDate"
      />

      <CustomSelect
        :model="currentTerminalId"
        placeholder="Терминал"
        :options="terminalsOptions"
        :disabled="!startDate && !finishDate"
        class="archive__filters-select"
        @select="setTerminal"
      />

      <MainButton
        title="Поиск"
        :disabled="!isButtonEnabled"
        class="archive__filters-button"
        @button-clicked="sendQuery"
      />
    </div>

    <div v-if="timeslots.length && isAfterQuery" class="archive__list">
      <TimeslotCard
        v-for="timeslot in timeslots"
        :key="timeslot.id"
        :timeslot="timeslot"
      />
    </div>

    <ArchiveEmpty v-if="!isAfterQuery" />

    <ArchiveEmpty
      v-if="!timeslots.length && isAfterQuery"
      :is-after-search="true"
    />
  </section>
</template>

<script>
import MobileTimeslotsHeader from "@/components/timeslots/common/MobileTimeslotsHeader";
import MobileHeader from "@/components/mobileHeader/MobileHeader";
import DatePicker from "@/components/UI/controls/form/DatePicker.vue";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import archiveTimeslotsMixin from "@/views/ArchiveTimeslotsView/archiveTimeslotsMixin";

export default {
  name: "MobileArchiveView",
  components: {
    MainButton,
    MobileTimeslotsHeader,
    MobileHeader,
    DatePicker,
  },
  mixins: [archiveTimeslotsMixin],
};
</script>

<style lang="scss" scoped>
.archive {
  &__filters {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 16px;
    padding: 110px 16px 24px;
    background: #fff;

    &-picker {
      width: 49%;
      :deep .el-date-editor.el-input {
        width: 100%;
      }
    }

    &-select {
      width: 100%;
    }

    &-button {
      width: 100%;
      margin-top: 24px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px 16px 90px;
  }
}
</style>
