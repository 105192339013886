<template>
  <div class="totalUnpaid">
    <div>
      <div class="font2-14-18 totalUnpaid__title">
        У ваших авто есть неоплаченные таймслоты
      </div>
      <div class="font2-12-16 totalUnpaid__description">
        {{ unpaidText }}
      </div>
    </div>
    <MainButton
      title="Оплатить все"
      class="totalUnpaid__button"
      @button-clicked="$emit('mass-pay')"
    />
  </div>
</template>

<script>
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";

export default {
  name: "PayAllTimeslots",
  components: { MainButton },
  props: {
    unpaidText: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.totalUnpaid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  background: $greyBanner;
  border-radius: 16px;
  @media (max-width: 1279px) {
    margin: 0 16px 16px;
    padding: 12px;
  }

  &__title {
    margin-bottom: 4px;
    font-weight: 700;
    color: $mainBlack;
  }
  &__description {
    font-weight: 500;
    color: $textSecondary;
  }
  &__button.el-button--primary.is-plain {
    flex-shrink: 0;
    width: 115px;
    height: 32px;
    padding: 0;
  }
}
</style>
