<template>
  <el-button round plain class="font1-14-16 mod-cancel" @click="clicked">
    {{ title }}
  </el-button>
</template>

<script>
export default {
  name: "CancelButton",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {
    clicked() {
      if (!this.disabled) {
        this.$emit("button-clicked");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button.is-plain.mod-cancel {
  padding: 12px 40px;
  height: 44px;
  border: 1px solid $greyBanner;
  font-weight: 500;
  color: $mainBlack;

  &:hover {
    background: $greyBanner;
    color: #fff;
  }

  @media (max-width: 1279px) {
    &:hover {
      background: #fff;
      color: $mainBlack;
    }
  }
}
</style>
