<template>
  <div class="confirm">
    <div class="font1-20-24 confirm__title">Подтверждение таймслотов</div>
    <div class="font2-14-18 confirm__description">
      Введите информацию по авто и нажмите "Подтвердить"
    </div>
    <TimeslotConfirmationTimer
      :duration="confirmationDuration"
      v-on="$listeners"
    />

    <div class="confirm__list">
      <div
        v-for="(timeslot, index) in timeslotsList"
        :key="index"
        class="confirm__list-item"
      >
        <ConfirmCardDesktop
          :timeslot="timeslot"
          :settings="settings"
          :index="index + 1"
          :autofill="autofillList"
          :axis-count="axisCountList"
          @set="setField(index, $event)"
          @delete="timeslot.isDeleted = true"
        />
      </div>
    </div>

    <div class="confirm__buttons">
      <MainButton styles="back" @button-clicked="back">
        <ChevronIconBackGray /> <span>Назад</span>
      </MainButton>
      <MainButton
        title="Подтвердить"
        :disabled="!canConfirmAll"
        @button-clicked="isGeneralModalOpen = true"
      />
    </div>
    <ConfirmGeneralModal
      v-if="isGeneralModalOpen"
      :terminal-name="terminal.name"
      :timeslots="timeslotsList"
      :postponed="settings.has_postponed_payments"
      :text-values="textValues"
      @confirm="toConfirm"
      @close="isGeneralModalOpen = false"
    />
  </div>
</template>

<script>
import ChevronIconBackGray from "@/assets/icons/svg/ChevronIconBackGray.vue";
import ConfirmCardDesktop from "./components/ConfirmCardDesktop.vue";
import confirmTimeslotsMixin from "./confirmTimeslotsMixin";

export default {
  name: "ConfirmTimeslotsDesktop",
  components: { ConfirmCardDesktop, ChevronIconBackGray },
  mixins: [confirmTimeslotsMixin],
};
</script>

<style lang="scss" scoped>
.confirm {
  &__title {
    margin-bottom: 12px;
    font-weight: 500;
    color: $mainBlack;
  }
  &__description {
    margin-bottom: 20px;
    color: $textSecondary;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    padding-bottom: 80px;
  }
}
</style>
