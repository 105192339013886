<template>
  <div class="confirmation font2-14-18">
    <div class="confirmation__description">
      <img src="@/assets/images/dark-attention.svg" alt="warning" />
      <div class="confirmation__description-warning">
        Осталось на подтверждение
      </div>
    </div>
    <div class="confirmation__timer">{{ timerText }}</div>
  </div>
</template>

<script>
// 10 минут по умолчанию на подтверждение
const HUMMER_TIME = 600;
export default {
  name: "TimeslotConfirmationTimer",
  props: {
    duration: {
      type: Number,
      default: HUMMER_TIME,
    },
  },
  data() {
    return {
      timerValue: 0,
    };
  },
  computed: {
    timerText() {
      let minutes = Math.trunc(this.timerValue / 60);
      let minutesText = minutes < 10 ? "0" + String(minutes) : minutes;
      let seconds = this.timerValue % 60;
      let secondsText = seconds < 10 ? "0" + String(seconds) : seconds;

      return `00:${minutesText}:${secondsText}`;
    },
  },
  mounted() {
    this.timerValue = this.duration;
    this.timerId = setInterval(() => {
      this.timerValue--;
    }, 1000);
    setTimeout(() => {
      clearInterval(this.timerId);
      this.$emit("close");
    }, this.timerValue * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
};
</script>

<style lang="scss" scoped>
.confirmation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  padding: 0 12px;
  background: $greyBanner;
  border-radius: 8px;
  &__description {
    display: flex;
    align-items: center;
    &-warning {
      margin-left: 6px;
    }
  }
  &__timer {
    font-weight: 700;
  }
}
</style>
