import { Request } from "@/core";
import { unpaid } from "@/constants/urls";

export default {
  getTrucksList() {
    return Request.get(unpaid.getTrucksList);
  },

  searchTruck(number) {
    return Request.get(`${unpaid.searchTruck}?plate_truck=${number}`);
  },

  addToFavorites(data) {
    return Request.post(unpaid.addTruckToFavorites, data);
  },

  removeFromFavorites(data) {
    return Request.post(unpaid.removeTruckFromFavorites, data);
  },
};
