/* eslint-disable no-undef */
const IS_PROD = process.env.NODE_ENV === "production";

export const ENV = {
  apiUrl: IS_PROD
    ? process.env.VUE_APP_API_URL_PROD
    : process.env.VUE_APP_API_URL,
  frontUrl: IS_PROD
    ? process.env.VUE_APP_URL_PROD
    : process.env.VUE_APP_URL_TEST,
};
