<template>
  <Component
    :is="currentWrapper"
    title="Удаление таймслота"
    leftButton="Отменить"
    rightButton="Подтвердить"
    :disabled="!isChecked"
    @close="$emit('close')"
    @accept="$emit('accept')"
  >
    <div class="deleting" v-loading="isLoading">
      <div class="font2-16-20 deleting__description">
        Если вы удалите таймслот, <span>машина не сможет приехать</span> по нему
      </div>
      <TimeslotInfo :timeslot="timeslot" class="deleting__card" />
      <TimeslotsCheckbox @changed="setCheckbox" />
    </div>
  </Component>
</template>

<script>
import TwoButtonsModal from "@/components/UI/modals/TwoButtonsModal.vue";
import FullscreenMobileModal from "@/components/UI/modals/FullscreenMobileModal.vue";
import TimeslotsCheckbox from "@/components/UI/controls/form/TimeslotsCheckbox";
export default {
  name: "TimeslotDeleteModal",
  components: {
    TwoButtonsModal,
    FullscreenMobileModal,
    // рекурсивно используемый компонент, по-другому ошибка выдается
    TimeslotInfo: () => import("@/components/timeslots/list/TimeslotCard.vue"),
    TimeslotsCheckbox,
  },
  props: {
    timeslot: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  computed: {
    currentWrapper() {
      return this.isMobile ? FullscreenMobileModal : TwoButtonsModal;
    },
  },
  methods: {
    setCheckbox(value) {
      this.isChecked = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.deleting {
  width: 368px;
  @media (max-width: 1279px) {
    width: 100%;
  }
  &__title {
    margin-bottom: 14px;
    color: $mainBlack;
  }
  &__description {
    margin-bottom: 24px;
    color: $textSecondary;
    span {
      color: $error;
    }
  }
  &__card {
    border: 1px solid $backgroundGray;
    margin-bottom: 24px;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 47%;
      height: 44px;
      border: 1px solid $greyBanner;
      border-radius: 40px;
      font-weight: 500;
      color: $greyText;
      cursor: pointer;
      &:hover {
        background: $greyBanner;
        color: #fff;
      }
      &.disabled {
        cursor: not-allowed;
        &:hover {
          background: #fff;
          color: $greyText;
        }
      }
      &.active {
        color: $error;
        border: 1px solid $error;
        &:hover {
          background: $error;
          color: #fff;
        }
      }
    }
  }
}
.deletingMob {
  width: 100%;
  text-align: center;
  background-color: $bckgrnd;
  &__title {
    font-weight: 400;
    font-size: 20px;
    font-family: $fontPrimary;
    margin-bottom: 8px;
    color: $mainBlack;
  }
  &__description {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 24px;
    color: $textSecondary;
    span {
      color: $error;
    }
  }
  &__card {
    border: 1px solid $backgroundGray;
    margin-bottom: 24px;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 47%;
      height: 44px;
      border: 1px solid $special;
      border-radius: 40px;
      font-weight: 400;
      font-size: 14px;
      color: $special;
      cursor: pointer;
      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
      &.active {
        color: $error;
        border: 1px solid $error;
      }
    }
    .confirmItem {
      background-color: $error;
      border: 1px solid $error;
      color: #fff;
    }
  }
}
</style>
