import TimeslotCard from "@/components/timeslots/list/TimeslotCard.vue";
import PayAllTimeslots from "@/components/timeslots/common/PayAllTimeslots.vue";
import TimeslotsEmpty from "@/components/timeslots/list/TimeslotsEmpty";
import NoMoneyModal from "@/components/UI/modals/NoMoneyModal.vue";
import ConfirmPaymentModal from "@/components/UI/modals/ConfirmPaymentModal.vue";
import {
  formattedNumberRu,
  getActiveTimeslot,
  removeActiveTimeslot,
  setActiveTimeslot,
} from "@/core";
import { mapActions } from "vuex";
import { FETCH_PROFILE } from "@/store/actions";

import Api from "@/api";

export default {
  components: {
    TimeslotCard,
    TimeslotsEmpty,
    PayAllTimeslots,
    NoMoneyModal,
    ConfirmPaymentModal,
  },
  data() {
    return {
      terminals: [],
      activeTimeslot: null,
      loading: false,
      openRescheduleModal: false,
      openPaymentModal: false,
      unpaid: {
        count: 0,
        sum: 0,
        timeslots: [],
      },
      paymentTimeslots: [],
      paymentSumText: "",
      notEnoughMoney: false,
    };
  },
  computed: {
    unpaidText() {
      return this.unpaid.count
        ? `${this.unpaid.count} шт | Сумма: ${formattedNumberRu(
            this.unpaid.sum / 100
          )}₽`
        : "";
    },
  },
  async mounted() {
    // запрос имеющихся таймслотов
    await this.fetchTimeslots();
  },
  methods: {
    ...mapActions({
      updateBalance: FETCH_PROFILE,
    }),
    // загрузка списка таймслотов
    async fetchTimeslots() {
      this.loading = true;
      const { data } = await Api.home.getTimeslots();
      // все терминалы после начальной загрузки свёрнуты
      this.terminals = data.map((item) => ({
        ...item,
        isOpen: false,
      }));
      // если переход на конкретный таймслот - разворачиваем терминал и показываем его
      if (getActiveTimeslot()) {
        let activeTimeslot = getActiveTimeslot();
        let terminalIndex = this.terminals.findIndex((terminal) => {
          return terminal.list.find(
            (timeslot) => timeslot.id === activeTimeslot
          );
        });
        if (terminalIndex !== -1) {
          this.terminals[terminalIndex].isOpen = true;
        }
        this.$nextTick(() => {
          // без задержки не успевает перестроиться ДОМ и оно недокручивает
          setTimeout(() => {
            let el = this.$refs[activeTimeslot];
            if (el)
              el[0].$el.scrollIntoView({ behavior: "smooth", block: "center" });
          }, 500);
          removeActiveTimeslot();
        });
      }

      // считаем неоплаченные ТС
      this.unpaid = {
        count: 0,
        sum: 0,
        timeslots: [],
      };
      this.terminals.forEach((item) => {
        item.list.forEach((timeslot) => {
          if (!timeslot.is_paid) {
            this.unpaid.count++;
            this.unpaid.sum += timeslot.timeslot_price_cop;
            this.unpaid.timeslots.push({
              terminal_id: timeslot.terminal_id,
              timeslot_id: timeslot.id,
            });
          }
        });
      });
      this.loading = false;
    },
    toggleTerminal(terminal) {
      terminal.isOpen = !terminal.isOpen;
    },
    // перезагрузка списка после удаления/переноса
    async refreshList(text) {
      await this.fetchTimeslots();
      this.successInformer(text, 65);
    },
    // оплата таймслотов (всех или одного)
    handlePay(timeslots, sum) {
      this.paymentTimeslots = timeslots;
      this.paymentSumText = `${formattedNumberRu(sum / 100)} ₽`;
      this.openPaymentModal = true;
    },
    async sendPayQuery() {
      this.loading = true;
      this.openPaymentModal = false;
      try {
        await Api.timeslots.payTimeslots(this.paymentTimeslots);
        await this.updateBalance();
        let info =
          this.paymentTimeslots.length > 1
            ? "Таймслоты успешно оплачены"
            : "Таймслот успешно оплачен";
        // если оплачивался один таймслот - разворачиваем его на экране
        if (this.paymentTimeslots.length === 1) {
          setActiveTimeslot(this.paymentTimeslots[0].timeslot_id);
        }
        await this.refreshList(info);
      } catch (error) {
        if (error.data[0].field === "balance") {
          this.notEnoughMoney = true;
        } else if (error.data[0].field === "result") {
          // обработка варианта, при котором кто-то уже оплатил таймлот(-ы),
          // а мы с тех пор не обновляли экран
          if (this.paymentTimeslots.length === 1) {
            setActiveTimeslot(this.paymentTimeslots[0].timeslot_id);
          }
          await this.updateBalance();
          await this.fetchTimeslots();
          this.errorInformer(error.data[0].message, 65);
        } else {
          this.errorInformer(error.data[0].message, 65);
        }
      } finally {
        this.loading = false;
        this.paymentTimeslots = [];
        this.paymentSumText = [];
      }
    },
  },
};
