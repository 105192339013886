export const timeslotTabs = [
  {
    name: "Активные",
    route: "timeslots",
  },
  {
    name: "История",
    route: "archiveTimeslots",
  },
  {
    name: "Получение",
    route: "getTimeslots",
  },
];
