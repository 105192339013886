<template>
  <div :class="['truckCard', { active: isActive }]">
    <div class="font1-20-24 truckCard__title">{{ truck.number }}</div>

    <div class="font2-12-16 truckCard__subtitle">Последние данные получены</div>

    <div v-if="!truck.latitude" class="font2-14-18 truckCard__error">
      <RedAlertIcon />
      <span>От ГЛОНАСС не поступают данные</span>
    </div>

    <TruckGlonassInfo v-else :truck="truck" />
  </div>
</template>

<script>
import RedAlertIcon from "@/assets/icons/svg/RedAlert.vue";
import TruckGlonassInfo from "@/components/trucks/TruckGlonassInfo.vue";

export default {
  name: "TruckCard",
  components: { TruckGlonassInfo, RedAlertIcon },
  props: {
    truck: {
      type: Object,
      default: () => ({}),
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    console.log(this.truck.number);
  },
};
</script>

<style lang="scss" scoped>
.truckCard {
  padding: 16px;
  background: #fff;
  border: 1px solid $backgroundGray;
  border-radius: 16px;
  &.active {
    border: 1px solid $special;
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 500;
    color: $mainBlack;
  }
  &__subtitle {
    margin-bottom: 6px;
    font-weight: 500;
    color: $greyText;
  }
  &__error {
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: 500;
    color: $error;
  }
}
</style>
