<template>
  <svg
    @click="$emit('click')"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.25 12.2594C2.25 11.7361 2.66776 11.3119 3.18309 11.3119C3.69841 11.3119 4.11617 11.7361 4.11617 12.2594V13.5392C4.11617 13.7136 4.25542 13.855 4.4272 13.855H13.5728C13.7446 13.855 13.8838 13.7136 13.8838 13.5392V12.2594C13.8838 11.7361 14.3016 11.3119 14.8169 11.3119C15.3322 11.3119 15.75 11.7361 15.75 12.2594V13.5392C15.75 14.7602 14.7752 15.75 13.5728 15.75H4.4272C3.22477 15.75 2.25 14.7602 2.25 13.5392V12.2594ZM6.31121 7.80287L8.03597 9.55428L8.03597 3.1975C8.03597 2.67421 8.45373 2.25 8.96906 2.25C9.48438 2.25 9.90214 2.67421 9.90214 3.1975L9.90214 9.55428L11.6269 7.80287C11.9913 7.43285 12.5821 7.43285 12.9465 7.80287C13.3109 8.17289 13.3109 8.77282 12.9465 9.14284L10.5086 11.6184C9.65832 12.4818 8.27979 12.4818 7.42954 11.6184L4.99163 9.14284C4.62723 8.77282 4.62723 8.17289 4.99163 7.80287C5.35602 7.43285 5.94682 7.43285 6.31121 7.80287Z"
      fill="#4CC0CC"
    />
  </svg>
</template>

<script>
export default {
  name: "SaveBillIcon",
};
</script>

<style scoped></style>
