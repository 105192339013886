<template>
  <section class="errorPage__wrapper">
    <div class="errorPage__divBox">
      <div class="errorPage__divBox-one"></div>
      <div class="errorPage__divBox-two"></div>
      <div class="errorPage__divBox-tree"></div>
    </div>

    <div class="errorPage__content">
      <span class="errorPage__content-title">404</span>

      <p class="font2-18-24 errorPage__content-text">
        Извините, мы не можем найти эту страницу! <br />
        Что-то пошло не так или страница больше не существует
      </p>

      <div
        class="font1-18-24 errorPage__content-link"
        @click="$router.push({ name: 'home' })"
      >
        <span>Перейти на главную</span>

        <ArrowUpRight />
      </div>
    </div>
  </section>
</template>

<script>
import ArrowUpRight from "@/assets/icons/svg/ArrowUpRight.vue";
export default {
  name: "Page404View",
  components: { ArrowUpRight },
};
</script>

<style lang="scss" scoped>
.errorPage__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $mainBlack;
  border-left: 1px solid $textSecondary;
  .errorPage__divBox {
    &-one,
    &-two,
    &-tree {
      position: absolute;
      top: 0;
      right: 0;
      border-bottom: 1px solid $special;
      border-left: 1px solid $special;
    }
    &-one {
      width: 73vw;
      height: 76vh;
      border-radius: 0 0 0 200px;
    }
    &-two {
      width: 62vw;
      height: 60vh;
      border-radius: 0 0 0 150px;
    }
    &-tree {
      width: 52vw;
      height: 44vh;
      border-radius: 0 0 0 100px;
    }
  }
  .errorPage__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    &-title {
      position: relative;
      padding: 20px 20px 0;
      margin-bottom: 20px;
      font-family: $fontPrimary;
      font-size: 250px;
      line-height: 200px;
      font-weight: 700;
      background-color: $mainBlack;
      color: $special;
      @media (max-width: 1279px) {
        font-size: 140px;
      }
    }
    &-text {
      position: relative;
      padding: 20px;
      margin-bottom: 25px;
      text-align: center;
      background-color: $mainBlack;
      color: #fff;
      @media (max-width: 1279px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    &-link {
      position: relative;
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: $mainBlack;
      cursor: pointer;
      span {
        margin-right: 14px;
        font-family: $fontPrimary;
        font-weight: 500;
        color: $special;
        &:hover {
          color: $hover;
        }
      }
    }
  }
}
</style>
