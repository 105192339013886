<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_9736_10843)">
      <rect width="28" height="28" fill="none" />
      <path
        d="M12.8333 22.1667C17.988 22.1667 22.1667 17.988 22.1667 12.8333C22.1667 7.67868 17.988 3.5 12.8333 3.5C7.67868 3.5 3.5 7.67868 3.5 12.8333C3.5 17.988 7.67868 22.1667 12.8333 22.1667Z"
        stroke="#A7ADB6"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.5 24.4998L19.425 19.4248"
        stroke="#A7ADB6"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9736_10843">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SsearchIcon",
};
</script>
