<template>
  <div class="font1-14-16 button" @click="$emit('button-clicked')">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "AlarmButton",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-weight: 500;
  color: #fff;
  background: $error;
  border-radius: 50px;
  cursor: pointer;
  &:hover {
    background: $errorHover;
  }
  &:active {
    background: $errorHover;
  }
}
</style>
