<template>
  <section class="status">
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :class="['status__icon', { deleted: isDeleted }]"
    >
      <path
        d="M5.56372 9.66596L2.67885 6.78109C2.56043 6.66267 2.41912 6.62621 2.25491 6.67171C2.0907 6.71721 1.98818 6.81974 1.94735 6.97928C1.90651 7.13882 1.9453 7.28232 2.06372 7.40978L5.56372 10.9098L12.5637 3.90978C12.6457 3.81849 12.6845 3.71363 12.6801 3.59522C12.6757 3.4768 12.6301 3.37428 12.5432 3.28765C12.4562 3.20103 12.3559 3.15771 12.2422 3.15771C12.1284 3.15771 12.0259 3.19869 11.9346 3.28065L5.56372 9.66596Z"
      />
    </svg>
    <div :class="['font2-14-18 status__title', { deleted: isDeleted }]">
      {{ title }}
    </div>
  </section>
</template>

<script>
export default {
  name: "FinalStatus",
  props: {
    title: {
      type: String,
      default: "",
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  gap: 2px;
  &__title {
    font-weight: 500;
    color: $success;
    @media (max-width: 1279px) {
      display: none;
    }
    &.deleted {
      color: $errorHover;
    }
  }
  &__icon {
    width: 14px;
    height: 14px;
    margin-top: 2px;
    fill: $success;
    @media (max-width: 1279px) {
      width: 24px;
      height: 24px;
    }
    &.deleted {
      fill: $errorHover;
    }
  }
}
</style>
