import YandexMapComponent from "@/components/trucks/YandexMap/YandexMapComponent.vue";
import TruckCard from "@/components/trucks/TruckCard.vue";
import EmptyTrucks from "@/components/trucks/EmptyTrucks.vue";
import Api from "@/api";
export default {
  components: {
    EmptyTrucks,
    YandexMapComponent,
    TruckCard,
  },
  data() {
    return {
      isLoading: false,
      trucksList: [],
      yandexMapList: [],
      yandexMapBounds: [],
      activeTruck: "",
    };
  },
  async mounted() {
    this.isLoading = true;
    try {
      const { data } = await Api.profile.getUserTrucks();
      this.trucksList = data;

      // готовим массив для карты
      this.trucksList.forEach((item) => {
        if (item.latitude && item.longitude) {
          const { truck_id, number, latitude, longitude } = item;
          this.yandexMapList.push({
            truck_id,
            number,
            coords: [latitude, longitude],
          });
        }
      });
      if (this.yandexMapList.length) {
        // определяем границы карты
        if (this.yandexMapList.length === 1) {
          // если только одна машина, то хардкодим размеры карты относительно машины
          this.yandexMapBounds = [
            [
              this.yandexMapList[0].coords[0] - 4,
              this.yandexMapList[0].coords[1] - 4,
            ],
            [
              this.yandexMapList[0].coords[0] + 4,
              this.yandexMapList[0].coords[1] + 4,
            ],
          ];
        } else {
          // иначе вычисляем границы карты
          let latitudes = this.yandexMapList.map((item) => item.coords[0]);
          let longitudes = this.yandexMapList.map((item) => item.coords[1]);
          this.yandexMapBounds = [
            [Math.min(...latitudes), Math.min(...longitudes)],
            [Math.max(...latitudes), Math.max(...longitudes)],
          ];
        }
      }
    } catch (e) {
      console.log(e.data);
    } finally {
      this.isLoading = false;
    }
  },
};
