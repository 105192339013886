export const contactRules = {
  name: [
    {
      required: true,
      message: "Поле обязательно для заполнения",
      trigger: "submit",
    },
  ],
  phone: [
    {
      required: true,
      message: "Поле обязательно для заполнения",
      trigger: "submit",
    },
    {
      min: 18,
      max: 18,
      message: "Некорректный номер",
      trigger: "submit",
    },
  ],
  message: [
    {
      required: true,
      message: "Поле обязательно для заполнения",
      trigger: "submit",
    },
  ],
};
