import axios from "axios";
import { ENV, getUserAccess } from "@/core";
import { Message } from "element-ui";

const getParamsUrl = (settings) => {
  let params = "";

  for (let key in settings) {
    if (Boolean(settings[key]) || settings[key] === 0) {
      params += params ? "&" : "?";
      params += `${key}=${settings[key]}`;
    }
  }

  return params;
};

const Request = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: ENV.apiUrl,
  timeout: 60000,
});

Request.interceptors.request.use(
  async (config) => {
    const newConfig = config;
    const userAccess = getUserAccess();
    if (userAccess) {
      newConfig.headers.Authorization = `Bearer ${userAccess.token}`;
    }
    return newConfig;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

Request.interceptors.response.use(
  (res) => res,
  (error) => {
    if (429 === error.response?.status) {
      Message.error({
        message:
          "Превышен лимит запросов. Попробуйте загрузить страницу позднее",
        showClose: true,
        duration: 5000,
      });
    }
    return Promise.reject(error.response);
  }
);

export { Request, getParamsUrl };
