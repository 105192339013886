<template>
  <MobileModalWrapper @close="$emit('close')">
    <div class="content">
      <div class="content__header">
        <img
          src="@/assets/images/fat-close.svg"
          alt="close"
          @click="$emit('close')"
        />
        <div class="font1-20-24 content__header-title">Политика и Оферта</div>
      </div>
      <div class="font2-16-20 content__terms">
        Нажимая “Принимаю”, вы соглашаетесь с
        <a href="/privacy-policy" class="content__terms-link" target="_blank">
          Политикой конфиденциальности
        </a>
        приложения и
        <a href="/public-oferta" class="content__terms-link" target="_blank">
          Офертой
        </a>
      </div>

      <MainButton
        title="Принимаю"
        :styles="isMobile ? 'mobile' : ''"
        @button-clicked="$emit('submit')"
      />
    </div>
  </MobileModalWrapper>
</template>

<script>
import MobileModalWrapper from "@/components/UI/MobileModalWrapper.vue";
import privacyMixin from "@/components/UI/modals/PrivacyPolicy/privacyMixin";

export default {
  name: "PrivacyPolicyModal",
  components: { MobileModalWrapper },
  mixins: [privacyMixin],
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 18px;
    margin-bottom: 4px;
    &-title {
      font-weight: 500;
      color: $mainBlack;
    }
  }

  &__terms {
    margin-bottom: 24px;
    font-weight: 500;
    color: $textSecondary;
    &-link {
      color: $special;
    }
  }
}
</style>
