import {
  GET_PROFILE,
  FETCH_PROFILE,
  SET_PROFILE,
  GET_UPDATE_OPERATIONS,
  SET_UPDATE_OPERATIONS,
} from "@/store/actions";
import API from "@/api";

export default {
  state: {
    profile: null,
    updateOperations: false,
  },
  getters: {
    [GET_PROFILE]: (state) => state.profile,
    [GET_UPDATE_OPERATIONS]: (state) => state.updateOperations,
  },
  actions: {
    [FETCH_PROFILE]: async ({ commit }) => {
      try {
        const { data } = await API.profile.getProfile();
        commit(SET_PROFILE, data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    [SET_PROFILE]: (state, profile) => {
      state.profile = profile;
    },
    [SET_UPDATE_OPERATIONS]: (state, payload) => {
      state.updateOperations = payload;
    },
  },
};
