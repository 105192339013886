<template>
  <section>
    <MobileHeader
      title="Оплата ТС"
      :has-chevron="true"
      @back="$router.push({ name: 'moreMenu' })"
    />

    <UnpaidHeader
      :tab-items="tabItems"
      :active="activeTab"
      @change="changeTab"
    />

    <UnpaidSearchTruck v-if="isSearchMode" />

    <UnpaidTrucksList v-else />
  </section>
</template>

<script>
import MobileHeader from "@/components/mobileHeader/MobileHeader.vue";
import unpaidMixin from "@/views/UnpaidView/unpaidMixin";
export default {
  name: "MobileUnpaidView",
  components: { MobileHeader },
  mixins: [unpaidMixin],
};
</script>
