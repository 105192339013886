<template>
  <div class="empty">
    <div class="empty__icon">
      <img src="@/assets/images/no-timeslots.svg" alt="empty" />
    </div>
    <div class="font2-16-20 empty__text">{{ title }}</div>
    <MainButton
      title="Получить таймслот"
      class="empty__button mod-round"
      @button-clicked="$router.push({ name: 'getTimeslots' })"
    />
  </div>
</template>

<script>
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";

export default {
  name: "TimeslotsEmpty",
  components: { MainButton },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 66px;
    background: #fff;
    border-radius: 50%;
  }
  &__text {
    width: 226px;
    color: $greyText;
    text-align: center;
  }
  &__button {
    width: 226px;
  }
}
</style>
