<template>
  <el-form
    v-loading="isLoading"
    :model="dispatchTimeslots"
    class="dispatchTimeslots"
  >
    <div class="font1-20-24 dispatchTimeslots__header">
      {{ currentTerminal.name }}
    </div>

    <span class="font2-14-18 dispatchTimeslots__subTitle">
      Количество грузовиков (не более 5)
    </span>

    <div class="dispatchTimeslots__tracks">
      <div
        v-for="item in tracks"
        :key="item.id"
        :class="[
          'dispatchTimeslots__tracks-item',
          { currentItem: dispatchTimeslots.trucks_count == item.id },
        ]"
        @click="setFormField('trucks_count', item.id)"
      >
        {{ item.id }}
      </div>
    </div>

    <div class="dispatchTimeslots__selectors">
      <el-form-item class="item">
        <DatePicker
          :date="dispatchTimeslots.startDate"
          :options="pickerOptions"
          placeholder="Дата прибытия"
          class="dispatchTimeslots__dates-day"
          @select="setFormField('startDate', $event)"
        />
      </el-form-item>

      <el-form-item class="item">
        <TimeSelect
          :time="dispatchTimeslots.startTime"
          :options="timeOptions"
          :disabled="timeDisabled"
          placeholder="Время прибытия"
          @select="setFormField('startTime', $event)"
        />
      </el-form-item>

      <el-form-item class="item">
        <CustomSelect
          :model="dispatchTimeslots.exporter_id"
          placeholder="Экспортер"
          :options="exportersList"
          @select="setExporter"
        />
      </el-form-item>

      <el-form-item v-if="needSupplier" class="item">
        <CustomSelect
          :model="dispatchTimeslots.supplier_id"
          placeholder="Квота на поставщика"
          :options="suppliersList"
          :disabled="supplierDisabled"
          @select="setSupplier"
        />
      </el-form-item>

      <el-form-item class="item">
        <CustomSelect
          :model="dispatchTimeslots.culture_id"
          placeholder="Культура"
          :options="culturesList"
          @select="setFormField('culture_id', $event)"
        />
      </el-form-item>

      <el-form-item v-if="needTruckType" class="item">
        <CustomSelect
          :model="dispatchTimeslots.truck_type_code"
          placeholder="Выберите тип авто"
          :options="truckTypesList"
          @select="setFormField('truck_type_code', $event)"
        />
      </el-form-item>
    </div>

    <Component
      v-if="needCarrierInn"
      :is="currentCarrierComponent"
      :carriers-list="carriersList"
      :force-carrier="forceCarrier"
      :is-verified="isCarrierVerified"
      :clear="clear"
      class="dispatchTimeslots__carrier"
      @change="setCarrierVerified"
    />

    <div class="dispatchTimeslots__buttons">
      <MainButton
        v-if="isMobile"
        title="Запросить таймслот"
        :disabled="!canContinue"
        class="dispatchTimeslots__buttons-next"
        @button-clicked="next"
      />
      <template v-else>
        <MainButton
          class="dispatchTimeslots__buttons-back"
          styles="back"
          @button-clicked="back"
        >
          <ChevronIconBackGray /> <span>Назад</span>
        </MainButton>

        <SecondaryButton
          class="dispatchTimeslots__buttons-next"
          title="Продолжить"
          :disabled="!canContinue"
          @button-clicked="next"
        >
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33341 12.3334L6.66675 7.00008L1.33341 1.66675"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </SecondaryButton>
      </template>
    </div>

    <TwoButtonsModal
      v-if="isAvailableTimeslotsModal && !isMobile"
      title="Нет доступных таймслотов"
      description="Измените параметры запроса"
      left-button="Вернуться на главную"
      right-button="Изменить"
      :success="true"
      @close="$router.push({ name: 'home' })"
      @accept="isAvailableTimeslotsModal = false"
    />

    <OneButtonMobileModal
      v-if="isAvailableTimeslotsModal && isMobile"
      title="Нет доступных таймслотов"
      description="Измените параметры запроса"
      button-title="Понятно"
      :success="false"
      class="no-timeslots"
      @accept="isAvailableTimeslotsModal = false"
      @close="isAvailableTimeslotsModal = false"
    />

    <NoMoneyModal v-if="notEnoughMoney" @close="notEnoughMoney = false" />
  </el-form>
</template>

<script>
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import CustomSelect from "@/components/UI/controls/form/CustomSelect.vue";
import DatePicker from "@/components/UI/controls/form/DatePicker.vue";
import TimeSelect from "@/components/UI/controls/form/TimeSelect";
import CarrierInnDesktop from "@/components/timeslots/getTimeslots/components/dispatchTimeslots/components/CarrierInnDesktop.vue";
import CarrierInnInnMobile from "@/components/timeslots/getTimeslots/components/dispatchTimeslots/components/CarrierInnInnMobile.vue";
import ChevronIconBackGray from "@/assets/icons/svg/ChevronIconBackGray.vue";
import SecondaryButton from "@/components/UI/controls/buttons/SecondaryButton.vue";
import TwoButtonsModal from "@/components/UI/modals/TwoButtonsModal.vue";
import NoMoneyModal from "@/components/UI/modals/NoMoneyModal.vue";

import {
  currentHourTz,
  pickerOptions,
  isPickerDateToday,
  setTerminalTimezone,
  defaultFromTzDate,
} from "@/core";
import { GET_PROFILE } from "@/store/actions";
import { mapGetters } from "vuex";
import Api from "@/api";
import OneButtonMobileModal from "@/components/UI/modals/OneButtonMobileModal.vue";

export default {
  name: "DispatchTimeslots",
  components: {
    OneButtonMobileModal,
    NoMoneyModal,
    TwoButtonsModal,
    SecondaryButton,
    MainButton,
    CustomSelect,
    DatePicker,
    TimeSelect,
    CarrierInnDesktop,
    ChevronIconBackGray,
  },
  props: {
    currentTerminal: {
      type: Object,
      default: () => {},
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tracks: [{ id: "1" }, { id: "2" }, { id: "3" }, { id: "4" }, { id: "5" }],
      isLoading: false,
      terminalSettings: {},
      culturesList: [],
      exportersList: [],
      suppliersList: [],
      carriersList: [],
      forceCarrier: {},
      isForceClearSupplier: false,
      truckTypesList: [],
      isCarrierVerified: false,
      isAvailableTimeslotsModal: false,
      notEnoughMoney: false,
      pickerOptions,

      dispatchTimeslots: {
        trucks_count: "1",
        startDate: "",
        startTime: "",
        culture_id: "",
        exporter_id: "",

        supplier_id: "",
        truck_type_code: "",
        carrier_id: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      userProfile: GET_PROFILE,
    }),
    fields() {
      return this.terminalSettings.timeslot_request_fields || {};
    },
    needTruckType() {
      return this.fields.truck_type;
    },
    needSupplier() {
      return this.fields.supplier_id;
    },
    needCarrierInn() {
      return this.fields.carrier_id;
    },
    currentCarrierComponent() {
      return this.isMobile ? CarrierInnInnMobile : CarrierInnDesktop;
    },
    timeDisabled() {
      return !this.dispatchTimeslots.startDate;
    },
    supplierDisabled() {
      return !this.dispatchTimeslots.exporter_id;
    },
    timeOptions() {
      if (!this.dispatchTimeslots.startDate) {
        return [];
      }
      let options = [];
      let startHour = isPickerDateToday(this.dispatchTimeslots.startDate)
        ? currentHourTz()
        : 0;
      for (let i = startHour; i < 24; i++) {
        let start = i < 10 ? "0" + String(i) : i;
        let finish = i + 1 < 10 ? "0" + String(i + 1) : i + 1;
        options.push({
          value: `${start}:00-${finish}:00`,
          label: `${start}:00-${finish}:00`,
        });
      }
      return options;
    },
    buttonStyle() {
      return this.isMobile ? "mobile" : "next";
    },
    // проверка заполнения всех необходимых полей формы
    canContinue() {
      const a = this.dispatchTimeslots;
      let result = a.startDate && a.startTime && a.culture_id && a.exporter_id;
      if (this.needSupplier) result = result && a.supplier_id !== "";
      if (this.needTruckType) result = result && a.truck_type_code;
      if (this.needCarrierInn)
        result = result && a.carrier_id && this.isCarrierVerified;

      return result;
    },
  },
  async activated() {
    if (this.clear) {
      this.dispatchTimeslots.trucks_count = "1";
      this.dispatchTimeslots.terminal_id = "";
      this.dispatchTimeslots.culture_id = "";
      this.dispatchTimeslots.exporter_id = "";
      this.dispatchTimeslots.supplier_id = "";
      this.dispatchTimeslots.carrier_id = "";
      this.dispatchTimeslots.startDate = "";
      this.dispatchTimeslots.startTime = "";
      this.isCarrierVerified = false;

      // сохраняем таймзону терминала для всех операций с датой/временем
      setTerminalTimezone(this.currentTerminal.timezone_utc_offset);

      try {
        this.isLoading = true;
        const params = { terminal_id: this.currentTerminal.id };
        // получаем настройки терминала с необходимыми для формы полями
        let response = await Api.terminals.getTerminalSettings(params);
        this.terminalSettings = response.data;

        // получаем список экспортеров терминала
        response = await Api.terminals.getTerminalExporters(params);
        this.exportersList = response.data;

        // получаем список культур терминала
        response = await Api.terminals.getTerminalCultures(params);
        this.culturesList = response.data;

        // список типов авто, если нужен
        if (this.needTruckType) {
          response = await Api.handbook.getTruckTypes(params);
          this.truckTypesList = response.data.map((item) => ({
            ...item,
            id: item.code,
          }));
        }

        // список подсказок для ИНН перевозчика (если есть поле)
        if (this.needCarrierInn) {
          response = await Api.timeslots.getCarrierAutofill();
          this.carriersList = response.data.map((item) => ({
            id: item.id,
            label: item.name,
            value: item.inn,
          }));
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
  methods: {
    setCarrierVerified(value, id = "") {
      this.dispatchTimeslots.carrier_id = id;
      this.isCarrierVerified = value;
    },
    setFormField(type, value) {
      this.dispatchTimeslots[type] = value;
    },
    // при выборе экспортера запрашиваем список его поставщиков
    async setExporter(id) {
      if (id) {
        this.dispatchTimeslots.exporter_id = id;
        this.dispatchTimeslots.supplier_id = "";
        if (this.needSupplier) {
          this.isLoading = true;
          const { data } = await Api.terminals.getExporterSuppliers({
            terminal_id: this.currentTerminal.id,
            exporter_id: id,
          });
          if (data.length) {
            this.suppliersList = [{ name: "Без квоты", id: 0 }, ...data];
          } else {
            this.suppliersList = [{ name: "Без квоты", id: 0 }];
            this.dispatchTimeslots.supplier_id = 0;
          }
          this.isLoading = false;
        }
      }
    },
    // при выборе поставщика автоматом пробрасываем его данные в перевозчика
    // (если на терминале нужно указывать перевозчика)
    setSupplier(id) {
      this.dispatchTimeslots.supplier_id = id;
      if (this.needCarrierInn) {
        this.forceCarrier = this.suppliersList.find((item) => item.id === id);
      }
    },
    back() {
      this.$emit("previous-step");
    },
    async next() {
      let request = new FormData();
      request.append("terminal_id", this.currentTerminal.id);
      request.append("trucks_count", this.dispatchTimeslots.trucks_count);
      request.append("culture_id", this.dispatchTimeslots.culture_id);
      request.append("exporter_id", this.dispatchTimeslots.exporter_id);
      request.append(
        "time",
        defaultFromTzDate(
          `${
            this.dispatchTimeslots.startDate
          } ${this.dispatchTimeslots.startTime.slice(0, 6)}`
        )
      );
      if (this.needTruckType)
        request.append("truck_type", this.dispatchTimeslots.truck_type_code);
      if (this.needSupplier && this.dispatchTimeslots.supplier_id)
        request.append("supplier_id", this.dispatchTimeslots.supplier_id);
      if (this.needCarrierInn)
        request.append("carrier_id", this.dispatchTimeslots.carrier_id);

      try {
        this.isLoading = true;
        const { data } = await Api.timeslots.requestTimeslots(request);

        // собираем текстовые названия всяких полей, они нужны в модалке подтверждения на следующем шаге
        const handbookTextValues = {
          supplierName: this.dispatchTimeslots.supplier_id
            ? this.suppliersList.find(
                (item) => item.id === this.dispatchTimeslots.supplier_id
              ).name
            : null,
          cultureName: this.culturesList.find(
            (item) => item.id === this.dispatchTimeslots.culture_id
          ).name,
          truckTypeName: this.needTruckType
            ? this.truckTypesList.find(
                (item) => item.id === this.dispatchTimeslots.truck_type_code
              ).name
            : null,
        };
        this.$emit(
          "next",
          data,
          this.dispatchTimeslots,
          this.terminalSettings,
          handbookTextValues
        );
      } catch (error) {
        if (error.data[0].field === "balance") {
          this.notEnoughMoney = true;
        } else if (error.data[0].field === "no_ts") {
          this.isAvailableTimeslotsModal = true;
        } else {
          this.errorInformer(error.data[0].message, 65);
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dispatchTimeslots {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1279px) {
    padding: 72px 0 12.4rem;
  }

  &__header {
    margin-bottom: 24px;
    font-weight: 500;
    color: $mainBlack;
  }
  &__subTitle {
    margin-bottom: 12px;
    font-weight: 500;
    color: $textSecondary;
  }
  .el-form-item {
    margin-bottom: 24px;
  }
  &__tracks {
    height: 50px;
    background-color: $bckgrnd;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
    margin-bottom: 24px;
    &-item {
      height: 42px;
      width: 19.5%;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      cursor: pointer;
      transition: all, 0.2s;
      @media (min-width: 1280px) {
        border: 1px solid $backgroundGray;
      }
      &:hover {
        border: 1px solid $special;
      }
    }
    & .currentItem {
      background-color: $backgroundBlue;
      color: $special;
      border: 1px solid $special;
      @media (max-width: 1279px) {
        background-color: $special;
        color: #fff;
      }
    }
  }
  &__selectors {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 16px;
    @media (max-width: 1279px) {
      flex-direction: column;
    }
    .item {
      width: 48.5%;
      margin-bottom: 0;
      @media (max-width: 1279px) {
        width: 100%;
      }
      .picker :deep .el-date-editor.el-input {
        width: 100%;
      }
    }
  }

  &__carrier {
    margin-top: 24px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    @media (max-width: 1279px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 88px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      padding: 0 16px;
      background: #fff;
      z-index: 9;
    }
    &-next {
      width: 200px;
      @media (max-width: 1279px) {
        width: 100%;
      }
    }
  }

  :deep .two-button-modal {
    width: 440px;
    @media (max-width: 1279px) {
      width: 268px;
    }
  }

  .no-timeslots :deep .content {
    width: 268px;
  }
}
</style>
