<template>
  <section class="getTimeslots" v-loading="isLoading">
    <MobileHeader
      title="Получение ТС"
      :has-chevron="currentStep !== 1"
      @back="previousStep"
    />

    <MobileTimeslotsHeader
      v-if="currentStep === 1"
      class="getTimeslots__switcher"
      active="getTimeslots"
    />

    <TimeslotsTable
      class="getTimeslots__body-table"
      :currentStep="currentStep"
      @step="nextStep"
      @previous-step="previousStep"
      @success="finish"
    />

    <TwoButtonsModal
      v-if="isLeaveModalOpen"
      title="Уходя с этой страницы"
      description="Вы отказываетесь от неподтвержденных таймслотов"
      left-button="Отменить"
      right-button="Отказаться"
      :success="false"
      @close="isLeaveModalOpen = false"
      @accept="forceBack"
    />
  </section>
</template>

<script>
import MobileHeader from "@/components/mobileHeader/MobileHeader";
import MobileTimeslotsHeader from "@/components/timeslots/common/MobileTimeslotsHeader";
import getTimeslotsMixin from "@/views/GetTimeslotsView/getTimeslotsMixin";

export default {
  name: "MobileGetTimeslots",
  components: {
    MobileTimeslotsHeader,
    MobileHeader,
  },
  mixins: [getTimeslotsMixin],
};
</script>
