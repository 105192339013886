<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#EDF1F2" />
    <g clip-path="url(#clip0_3750_4865)">
      <path
        d="M21.3334 22V20.6667C21.3334 19.9594 21.0525 19.2811 20.5524 18.781C20.0523 18.281 19.374 18 18.6667 18H13.3334C12.6262 18 11.9479 18.281 11.4478 18.781C10.9477 19.2811 10.6667 19.9594 10.6667 20.6667V22"
        stroke="#A7ADB6"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.9999 15.3333C17.4727 15.3333 18.6666 14.1394 18.6666 12.6667C18.6666 11.1939 17.4727 10 15.9999 10C14.5272 10 13.3333 11.1939 13.3333 12.6667C13.3333 14.1394 14.5272 15.3333 15.9999 15.3333Z"
        stroke="#A7ADB6"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3750_4865">
        <rect width="16" height="16" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AvatarIcon",
};
</script>
