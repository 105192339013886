<template>
  <svg
    @click="$emit('icon-clicked')"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="10" fill="#4CC0CC" />
    <path
      d="M13.75 26.25L26.25 13.75"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.75 13.75H26.25V26.25"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "WorkBannerButtonMobile",
};
</script>

<style scoped></style>
