import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import { getUserAccess, removeUserAccess, setUserAccess } from "@/core";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const unauthorisedRoutes = ["login", "confirm"];
  const documentRoutes = [
    "PrivacyPolicy",
    "AdvertisingAgreement",
    "LicenseAgreement",
    "PersonalData",
    "PublicOferta",
  ];

  if (unauthorisedRoutes.includes(to.name)) {
    removeUserAccess();
    return next();
  }

  if (documentRoutes.includes(to.name)) {
    return next();
  }

  // при редиректе извне с токеном - запоминаем пользователя
  if (to.query.token) {
    setUserAccess({ token: to.query.token });
    await router.replace({
      ...to,
      query: "",
    });
    return next();
  }

  const userAccess = getUserAccess();
  if (!userAccess) {
    return next("/login");
  }

  return next();
});

export default router;
