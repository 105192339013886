<template>
  <section class="getTimeslots" v-loading="isLoading">
    <TimeslotsHeader active="getTimeslots" />

    <div class="getTimeslots__body">
      <TimeslotsSteps
        class="getTimeslots__body-steps"
        :currentStep="currentStep"
      />

      <TimeslotsTable
        class="getTimeslots__body-table"
        :currentStep="currentStep"
        @step="nextStep"
        @previous-step="previousStep"
        @success="finish"
      />
    </div>

    <TwoButtonsModal
      v-if="isLeaveModalOpen"
      title="Уходя с этой страницы"
      description="Вы отказываетесь от неподтвержденных таймслотов"
      left-button="Отменить"
      right-button="Отказаться"
      :success="false"
      @close="isLeaveModalOpen = false"
      @accept="forceBack"
    />
  </section>
</template>

<script>
import TimeslotsHeader from "@/components/timeslots/common/TimeslotsHeader";
import TimeslotsSteps from "@/components/timeslots/getTimeslots/TimeslotsSteps.vue";
import getTimeslotsMixin from "@/views/GetTimeslotsView/getTimeslotsMixin";

export default {
  name: "DesktopGetTimeslots",
  components: {
    TimeslotsHeader,
    TimeslotsSteps,
  },
  mixins: [getTimeslotsMixin],
};
</script>

<style lang="scss" scoped>
.getTimeslots {
  &__body {
    margin: 97px 32px 30px;
    display: grid;
    grid-template: minmax(430px, 1fr) / 1fr 2fr;
    column-gap: 24px;
  }
  .haveNoMoneyModal {
    .modal__slot {
      &-subTitle {
        font-size: 1.4rem;
      }
      &-content {
        margin-top: 26px;
        font-size: 1.4rem;
        .necessary,
        .onAccount,
        .lacks {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .text {
            font-family: $fontSecondary;
            font-size: 1.4rem;
            color: $greyText;
          }
          .number {
            font-weight: 700;
            font-size: 1.8rem;
            color: $textSecondary;
          }
        }
      }
    }
  }
}
</style>
