export const contactsFields = {
  detail: [
    {
      key: "Наименование",
      value: "name",
    },
    {
      key: "Фактический адрес",
      value: "address",
    },
    {
      key: "ОГРН",
      value: "ogrn",
    },
    {
      key: "ИНН",
      value: "inn",
    },
  ],
  bank: [
    {
      key: "Банк",
      value: "bank_name",
    },
    {
      key: "БИК",
      value: "bik",
    },
    {
      key: "Р/с",
      value: "checking_account",
    },
    {
      key: "К/с",
      value: "correspondent_account",
    },
  ],
};
