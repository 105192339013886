export const statusStyles = [
  {
    title: "В пути",
    style: "special",
  },
  {
    title: "На парковке",
    style: "purple",
  },
  {
    title: "В буфере у КПП",
    style: "purple",
  },
  {
    title: "Закончил рейс",
    style: "green",
  },
  {
    title: "Опоздал",
    style: "grey",
  },
  {
    title: "Отказ",
    style: "red",
  },
];
