<template>
  <section class="more">
    <MobileHeader title="Больше" />

    <div class="more__links">
      <div
        v-for="item in linksList"
        :key="item.title"
        class="more__links-link"
        @click="newRoute(item.name)"
      >
        <svg class="link__icon">
          <use :xlink:href="`#${item.icon}`" />
        </svg>

        <div class="font2-16-20 link__title">
          {{ item.title }}
        </div>
      </div>
    </div>

    <MoreMenuSvgSprite />
  </section>
</template>

<script>
import MobileHeader from "@/components/mobileHeader/MobileHeader";
import MoreMenuSvgSprite from "@/assets/icons/svg/MoreMenuSvgSprite";

import { linksList } from "@/constants/moreLinksList";
export default {
  name: "MoreMenuView",
  components: {
    MobileHeader,
    MoreMenuSvgSprite,
  },
  data() {
    return {
      linksList,
    };
  },
  methods: {
    newRoute(route) {
      if (route !== this.$route.name) {
        this.$router.push({ name: route });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.more {
  height: 100vh;
  padding-top: 72px;
  background-color: $bckgrnd;
  &__links {
    padding: 0 16px;
    background-color: #fff;
    &-link {
      display: flex;
      align-items: center;
      gap: 12px;
      height: 48px;
      &:not(:first-child) {
        border-top: 1px solid $greyBanner;
      }
      .link__icon {
        width: 16px;
        height: 16px;
      }
      .link__title {
        font-weight: 500;
        color: $textSecondary;
      }
    }
  }
}
</style>
