<template>
  <section class="wrapper">
    <div class="document">
      <div class="title">
        <h1>Согласие на обработку персональных данных</h1>
      </div>

      <div class="block">
        <p class="item">
          Действуя свободно, своей волей и в своем интересе, а также подтверждая
          свою дееспособность, в соответствии с Федеральным законом от
          27.07.2006 № 152-ФЗ «О персональных данных” (далее — 152-ФЗ),
        </p>
      </div>

      <div class="block">
        <p class="item">
          Я даю свое согласие ООО «Порт транзит», ИНН 2315190510, КПП 773101001,
          ОГРН 1142315005722, адрес места нахождения: 121205, город Москва,
          Внутренний территориальный городской муниципальный округ Можайский,
          тер. Сколково инновационного центра, б-р Большой, д. 42, стр. 1, пом.
          138, (далее – Оператор), на обработку своих персональных данных на
          следующих условиях:
        </p>
      </div>

      <div class="table">
        <div class="table__row">
          <div class="table__row-column">
            Цель обработки персональных данных
          </div>
          <div class="table__row-column">
            Направление рекламных рассылок, Сбор установленных документов для
            оформления пропуска на территорию зоны таможенного контроля Передача
            данных компании ООО "Порт транзит Экспедирование" для возможности
            рассмотрения предложений о сотрудничестве
          </div>
        </div>
        <div class="table__row">
          <div class="table__row-column">Перечень персональных данных</div>
          <div class="table__row-column">
            Фамилия, имя, отчество;<br />
            Номер контактного телефона;<br />
            Адрес электронной почты <br />
            Цифровая копия водительского удостоверения <br />
            Цифровая копия паспорта Данные с водительского удостоверения и
            паспорта
          </div>
        </div>
        <div class="table__row">
          <div class="table__row-column">
            Перечень действий с персональными данными
          </div>
          <div class="table__row-column">
            Сбор, запись, систематизация, накопление, хранение, уточнение
            (обновление, изменение), извлечение, использование, передача
            (предоставление, доступ), блокирование, удаление, уничтожение
          </div>
        </div>
        <div class="table__row">
          <div class="table__row-column">
            Третьи лица, осуществляющие обработку персональных данных по
            поручению Оператора
          </div>
          <div class="table__row-column">ООО "Порт транзит Экспедирование"</div>
        </div>
        <div class="table__row">
          <div class="table__row-column">Срок действия согласия</div>
          <div class="table__row-column">
            До получения отзыва согласия на обработку персональных данных
          </div>
        </div>
      </div>

      <div class="block">
        <p class="item">
          Согласие может быть отозвано субъектом персональных данных или его
          представителем путем письменного уведомления, направленного Оператору
          по адресу 353960, Россия, Краснодарский край, г. Новороссийск, с.
          Цемдолина, ул. Ленина, 7«М» почтовым отправлением или электронного
          сообщения на адрес электронной почты : info@zernovozam.ru.
        </p>
      </div>

      <div class="block">
        <p class="item">
          После получения отзыва согласия на обработку персональных данных
          Оператор прекращает направление рекламных рассылок субъекту
          персональных данных.
        </p>
      </div>

      <div class="block">
        <p class="item">
          В случае отзыва субъектом персональных данных или его представителем
          согласия на обработку персональных данных Оператор вправе продолжить
          обработку персональных данных без согласия субъекта персональных
          данных при наличии оснований, указанных в пунктах 2 - 11 части 1
          статьи 6 152-ФЗ.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import urlMixin from "@/views/Documents/urlMixin";
export default {
  name: "PersonalData",
  mixins: [urlMixin],
};
</script>

<style lang="scss" scoped>
@import "./styles";
.table__row-column {
  width: 50%;
}
</style>
