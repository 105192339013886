<template>
  <div class="font2-16-20 truck">
    {{ truckNumber }}
  </div>
</template>

<script>
export default {
  name: "TimeslotTruckNumber",
  props: {
    truckNumber: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.truck {
  font-weight: 700;
  color: $textSecondary;
}
</style>
