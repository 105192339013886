<template>
  <Component :is="currentWrapper" v-on="$listeners">
    <MobileHeader
      v-if="isMobile"
      :title="isMethodStep ? 'Пополнение баланса' : 'Оплата по счету'"
      :has-chevron="true"
      @back="handleBack"
    />
    <section class="payment" v-loading="isLoading">
      <KeepAlive>
        <PaymentMethodForm
          v-if="isMethodStep"
          @pay="startPay"
          @back="$emit('close')"
        />
      </KeepAlive>

      <BillForm
        v-if="!isMethodStep"
        @back="isMethodStep = true"
        @download="createBill('download', $event)"
        @send="createBill('send', $event)"
      />
    </section>
  </Component>
</template>

<script>
import ModalWrapper from "@/components/UI/ModalWrapper.vue";
import FullscreenModalWrapper from "@/components/UI/FullscreenModalWrapper.vue";
import PaymentMethodForm from "./components/PaymentMethodForm.vue";
import BillForm from "./components/BillForm.vue";
import { downloadPdfFile, redirectToUrl } from "@/core";
import { mapMutations } from "vuex";

import Api from "@/api";
import MobileHeader from "@/components/mobileHeader/MobileHeader.vue";
import { SET_UPDATE_OPERATIONS } from "@/store/actions";
export default {
  name: "PaymentModal",
  components: {
    MobileHeader,
    ModalWrapper,
    FullscreenModalWrapper,
    PaymentMethodForm,
    BillForm,
  },
  data() {
    return {
      isMethodStep: true,
      paymentSum: 0,
      isLoading: false,
    };
  },
  computed: {
    currentWrapper() {
      return this.isMobile ? FullscreenModalWrapper : ModalWrapper;
    },
  },
  methods: {
    ...mapMutations({
      setForceUpdate: SET_UPDATE_OPERATIONS,
    }),
    handleBack() {
      if (this.isMethodStep) this.$emit("close");
      else this.isMethodStep = true;
    },
    startPay(method, sum) {
      this.paymentSum = sum;
      this[method]();
    },
    // обработчики оплат через Ю-Кассу
    paySbp() {
      this.paymentYooCash("makeSbpPayment");
    },
    payOther() {
      this.paymentYooCash("makeYooCashPayment");
    },
    async paymentYooCash(method) {
      const params = {
        amount_rub: this.paymentSum,
        redirect_back_url: window.location.href,
      };
      this.isLoading = true;
      try {
        const { data } = await Api.profile[method](params);
        redirectToUrl(data.payment_confirmation_url);
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },

    // обработчики выставления счета
    payBill() {
      this.isMethodStep = false;
    },
    async createBill(mode, fields) {
      this.isLoading = true;
      try {
        let params = {
          org_inn: fields.org_inn,
          org_name: fields.org_name,
          amount_rub: this.paymentSum,
        };
        const { data } = await Api.profile.createBillRequest(params);

        if (mode === "send") {
          params = {
            operation_id: data.operation_id,
            email: fields.email,
          };
          await Api.profile.sendBillEmail(params);
          this.successInformer("Счет успешно отправлен");
          this.setForceUpdate(true);
          this.$emit("close");
        } else {
          const response = await Api.profile.downloadBill(data.operation_id);
          downloadPdfFile(response.data.url);
          this.successInformer("Счет успешно загружается");
          this.setForceUpdate(true);
          this.$emit("close");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  width: 440px;
  @media (max-width: 1279px) {
    width: 100%;
    padding: 64px 16px;
  }
}
</style>
