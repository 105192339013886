<template>
  <div :class="['font1-14-16 button', { disabled: disabled }]" @click="onClick">
    <span>{{ title }}</span>
    <slot />
  </div>
</template>

<script>
export default {
  name: "SecondaryButton",
  props: {
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit("button-clicked");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 44px;
  font-weight: 500;
  color: $special;
  background: #fff;
  border: 1px solid $special;
  border-radius: 50px;
  cursor: pointer;
  svg {
    stroke: $special;
  }
  &:hover {
    background: $hover;
    border: 1px solid $hover;
    color: #fff;
    svg {
      stroke: #fff;
    }
  }
  &:active {
    background: $visited;
    border: 1px solid $visited;
    color: #fff;
    svg {
      stroke: #fff;
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      color: $special;
      background: #fff;
      border: 1px solid $special;
      svg {
        stroke: $special;
      }
    }
    &:active {
      color: $special;
      background: #fff;
      border: 1px solid $special;
      svg {
        stroke: $special;
      }
    }
  }
}
</style>
