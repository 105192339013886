import { Request, getParamsUrl } from "@/core";
import { statistics } from "@/constants/urls";

export default {
  getStatuses() {
    return Request.get(statistics.getStatuses);
  },

  getStatistics(data) {
    const paramsFormatted = getParamsUrl(data);
    return Request.get(`${statistics.getStatistics}${paramsFormatted}`);
  },
};
