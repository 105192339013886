import Vue from "vue";
import Vuex from "vuex";

import Auth from "@/store/modules/auth";
import Profile from "@/store/modules/profile";
import Terminal from "@/store/modules/terminal";
import Timeslots from "@/store/modules/timeslots";
import screenSize from "@/store/modules/screenSize";

Vue.use(Vuex);

Vue.config.productionTip = false;

export default new Vuex.Store({
  modules: {
    Auth,
    Profile,
    Terminal,
    Timeslots,
    screenSize,
  },
});
