<template>
  <TwoButtonsModal
    title="Вы уверены, что хотите оплатить? "
    description="Деньги будут списаны с баланса"
    left-button="Отмена"
    right-button="Оплатить"
    :success="true"
    v-on="$listeners"
  >
    <div class="font2-16-20 payment">
      <span>Сумма списания:&nbsp;</span>
      <span class="font1-24-30 payment__sum">
        {{ sumText }}
      </span>
    </div>
  </TwoButtonsModal>
</template>

<script>
import TwoButtonsModal from "@/components/UI/modals/TwoButtonsModal.vue";

export default {
  name: "ConfirmPaymentModal",
  components: { TwoButtonsModal },
  props: {
    sumText: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  margin-top: -14px;
  font-weight: 500;
  color: $greyText;
  @media (max-width: 1279px) {
    margin-bottom: 16px;
    text-align: center;
  }
  &__sum {
    color: $special;
  }
}
</style>
