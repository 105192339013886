<template>
  <div :class="['input', { 'is-focus': isFocused }]">
    <div class="input__content" @click="start">
      <div :class="['input__content-prompt', { filled: isLabelTop }]">
        {{ title }}
      </div>
      <input
        ref="input"
        v-model="inputValue"
        type="number"
        :class="['input__content-input', { filled: isLabelTop }]"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DoubleInputNumber",
  props: {
    title: {
      type: String,
      default: "",
    },
    model: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit("input", Number(value));
      },
    },
    isLabelTop() {
      return this.isFocused || this.inputValue;
    },
  },
  methods: {
    start() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.is-focus {
  border: none;
  @media (max-width: 1279px) {
    border: 1px solid $select;
  }
}

.input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-right: 17px;
  border-radius: 12px;
  background: $bckgrnd;
  @supports (-webkit-touch-callout: none) {
    @media (max-width: 767px) {
      height: 62px;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 12px;
    cursor: pointer;

    &-input {
      width: 100%;
      height: 0;
      padding: 0;
      border-width: 0;
      outline: none;
      font-size: 0;
      background: $bckgrnd;
      transition: height 0.3s ease;
      color: $mainBlack;
      &.filled {
        height: 18px;
        font-size: 16px;
        font-weight: 500;
        @supports (-webkit-touch-callout: none) {
          @media (max-width: 767px) {
            height: 30px;
          }
        }
      }
    }

    &-prompt {
      font-size: 14px;
      color: $greyText;
      transition: all 0.3s ease;
      &.filled {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
