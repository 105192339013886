<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.55556 8C2.55556 4.99312 4.99312 2.55556 8 2.55556C11.0069 2.55556 13.4444 4.99312 13.4444 8C13.4444 11.0069 11.0069 13.4444 8 13.4444C4.99312 13.4444 2.55556 11.0069 2.55556 8ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM8 4.11086C8.42955 4.11086 8.77778 4.45908 8.77778 4.88864V9.0147C8.77778 9.44426 8.42955 9.79248 8 9.79248C7.57045 9.79248 7.22222 9.44426 7.22222 9.0147V4.88864C7.22222 4.45908 7.57045 4.11086 8 4.11086ZM8.69136 11.1118C8.69136 11.4933 8.38183 11.8027 8 11.8027C7.61817 11.8027 7.30864 11.4933 7.30864 11.1118C7.30864 10.7302 7.61817 10.4209 8 10.4209C8.38183 10.4209 8.69136 10.7302 8.69136 11.1118Z"
      fill="#E13050"
    />
  </svg>
</template>

<script>
export default {
  name: "RedAlertIcon",
};
</script>
