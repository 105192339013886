<template>
  <section class="operations-item">
    <div class="leftInfo">
      <div
        class="leftInfo__icon"
        :style="{
          'background-image': 'url(' + imageUrl(iconName) + ')',
        }"
      />
      <div>
        <div class="font2-16-20 leftInfo__title">
          <span>{{ operation.title }}</span>
          <SaveBillIcon
            v-if="operation.type === 'bill-payment'"
            class="leftInfo__title-icon"
            @click="downloadBill(operation.operation_id)"
          />
        </div>
        <div class="font2-14-18 leftInfo__description">
          {{ operation.description }}
        </div>
      </div>
    </div>
    <div class="rightInfo">
      <div class="font2-14-18 rightInfo__status">{{ status }}</div>
      <div :class="['font2-16-20 rightInfo__sum', sum.style]">
        {{ sum.value }}
      </div>
      <div class="font2-12-16 rightInfo__time">
        {{ operation.time }}
      </div>
    </div>
  </section>
</template>

<script>
import SaveBillIcon from "@/assets/icons/svg/SaveBillIcon.vue";
import { formattedNumberRu, downloadPdfFile } from "@/core";
import Api from "@/api";

export default {
  name: "OperationsItem",
  components: { SaveBillIcon },
  props: {
    operation: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    iconName() {
      if (this.operation.type === "yoocash-payment") {
        return ["sbp", "bank_card"].includes(this.operation.yoocash_type)
          ? this.operation.yoocash_type
          : "yoo-money";
      }
      return this.operation.type;
    },
    sum() {
      let value = formattedNumberRu(this.operation.amount_cop / 100) + " ₽";
      let style = "";
      if (this.operation.type === "purchase") {
        value = "- " + value;
      } else if (this.operation.status === "Успешно") {
        value = "+ " + value;
        style = "success";
      } else if (this.operation.status === "Не успешно") {
        style = "decline";
      }
      return {
        style,
        value,
      };
    },
    status() {
      return this.operation.type === "purchase"
        ? this.operation.amount_description
        : this.operation.status;
    },
  },
  methods: {
    imageUrl(icon) {
      // eslint-disable-next-line no-undef
      return require(`@/assets/images/CabinetOperations/${icon}.svg`);
    },
    async downloadBill(id) {
      try {
        this.isLoading = true;
        const { data } = await Api.profile.downloadBill(id);
        downloadPdfFile(data.url);
      } catch (error) {
        console.log(error.data);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.operations-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px;
  margin-top: 16px;
  @media (max-width: 1279px) {
    align-items: flex-start;
  }

  .leftInfo {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 1279px) {
      align-items: flex-start;
    }

    &__icon {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      background-color: $bckgrnd;
      border-radius: 10px;
    }

    &__title {
      display: flex;
      align-items: center;
      font-weight: 700;
      color: $mainBlack;
      &-icon {
        margin-left: 4px;
        cursor: pointer;
      }
    }

    &__description {
      margin-top: 2px;
      font-weight: 500;
      color: $greyText;
      @media (max-width: 1279px) {
        margin-top: 6px;
      }
    }
  }

  .rightInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    @media (max-width: 1279px) {
      flex-direction: column;
      align-items: flex-end;
      gap: 6px;
    }

    &__status {
      font-weight: 500;
      color: $greyText;
      @media (max-width: 1279px) {
        order: 2;
      }
    }

    &__sum {
      width: 90px;
      font-weight: 700;
      color: $mainBlack;
      text-align: right;
      @media (max-width: 1279px) {
        order: 1;
      }
      &.success {
        color: $special;
      }
      &.decline {
        color: $error;
      }
    }

    &__time {
      order: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 19px;
      margin-top: 2px;
      background: $backgroundGray;
      font-weight: 700;
      color: $textSecondary;
      border-radius: 6px;
      line-height: 13px;
      @media (max-width: 1279px) {
        width: 44px;
      }
    }
  }
}
</style>
