<template>
  <section class="font2-16-20 truck">
    <div class="truck__header">
      <div class="font1-24-30 truck__header-plate">{{ truck.plate_truck }}</div>

      <div class="truck__header-icon" @click="$emit('toggle-favorites')">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_11599_12383)">
            <path
              :class="truck.is_in_favorites ? 'active' : 'passive'"
              d="M17.3651 3.84123C16.9395 3.41541 16.4341 3.07761 15.8779 2.84714C15.3217 2.61667 14.7255 2.49805 14.1235 2.49805C13.5214 2.49805 12.9252 2.61667 12.369 2.84714C11.8128 3.07761 11.3074 3.41541 10.8818 3.84123L9.99847 4.72457L9.11514 3.84123C8.25539 2.98149 7.08933 2.49849 5.87347 2.49849C4.65761 2.49849 3.49155 2.98149 2.6318 3.84123C1.77206 4.70098 1.28906 5.86704 1.28906 7.0829C1.28906 8.29876 1.77206 9.46482 2.6318 10.3246L3.51514 11.2079L9.99847 17.6912L16.4818 11.2079L17.3651 10.3246C17.791 9.89894 18.1288 9.39358 18.3592 8.83736C18.5897 8.28115 18.7083 7.68497 18.7083 7.0829C18.7083 6.48083 18.5897 5.88465 18.3592 5.32844C18.1288 4.77222 17.791 4.26686 17.3651 3.84123Z"
              fill="#4CC0CC"
              stroke="#4CC0CC"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_11599_12383">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>

    <template v-if="truck.timeslots.length">
      <div
        v-for="timeslot in truck.timeslots"
        :key="timeslot.is"
        class="truck__timeslot"
      >
        <div>
          {{ fromFullToBrief(timeslot.date) }}
        </div>
        <SecondaryButton
          :title="`Оплатить ТС ${formattedNumberRu(
            timeslot.timeslot_price_cop / 100
          )} ₽`"
          class="truck__timeslot-button"
          @button-clicked="paySingleTimeslot(timeslot)"
        />
      </div>
      <div class="truck__total">
        <MainButton
          :title="`Оплатить все ТС ${formattedNumberRu(
            truck.timeslots_cost / 100
          )} ₽`"
          class="truck__total-button"
          @button-clicked="payTruckTimeslots"
        />
      </div>
    </template>
    <div v-else class="font2-16-22 truck__empty">
      У данного авто нет неоплаченных таймслотов
    </div>
  </section>
</template>

<script>
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import SecondaryButton from "@/components/UI/controls/buttons/SecondaryButton.vue";
import { fromFullToBrief, formattedNumberRu } from "@/core";

export default {
  name: "UnpaidTruckCard",
  components: { MainButton, SecondaryButton },
  props: {
    truck: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fromFullToBrief,
      formattedNumberRu,
    };
  },
  methods: {
    paySingleTimeslot(timeslot) {
      this.$emit(
        "pay",
        [
          {
            timeslot_id: timeslot.id,
            terminal_id: timeslot.terminal_id,
          },
        ],
        timeslot.timeslot_price_cop
      );
    },
    payTruckTimeslots() {
      const allTimeslots = this.truck.timeslots.map((timeslot) => ({
        timeslot_id: timeslot.id,
        terminal_id: timeslot.terminal_id,
      }));
      const totalPrice = this.truck.timeslots.reduce(
        (sum, timeslot) => sum + timeslot.timeslot_price_cop,
        0
      );
      this.$emit("pay", allTimeslots, totalPrice);
    },
  },
};
</script>

<style lang="scss" scoped>
.truck {
  padding: 24px 32px 32px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid $backgroundGray;
  font-weight: 500;
  color: $textSecondary;
  @media (max-width: 1279px) {
    padding: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &-plate {
      color: $mainBlack;
    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid $greyBanner;
      cursor: pointer;
      .active {
        fill: $special;
        stroke: $special;
      }
      .passive {
        fill: none;
        stroke: $greySecondary;
      }
    }
  }

  &__timeslot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    border-bottom: 1px solid $backgroundGray;
    &-button {
      width: 180px;
    }
  }

  &__total {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    &-button {
      width: 244px;
      @media (max-width: 1279px) {
        width: 100%;
      }
    }
  }

  &__empty {
    padding-top: 8px;
    font-weight: 500;
    color: $greyText;
  }
}
</style>
