<template>
  <section class="banner" @click="goToExp">
    <div class="banner__roadLineLeft">
      <div class="banner__roadLineLeft-one"></div>
      <div class="banner__roadLineLeft-two"></div>
      <div class="banner__roadLineLeft-tree"></div>
    </div>

    <div class="banner__roadLineRight">
      <div class="banner__roadLineRight-one"></div>
      <div class="banner__roadLineRight-two"></div>
      <div class="banner__roadLineRight-tree"></div>
    </div>

    <div class="banner__content">
      <div class="banner__content-left">
        <div class="portTranzit">
          <PortTranzit
            class="portTranzit__icon"
            fill="black"
            :width="bannerWidth"
            :height="bannerHeight"
          />
        </div>

        <span class="font2-14-18 content__text">
          {{ content.description }}
        </span>
      </div>

      <div class="banner__content-right">
        <div class="banner__link">
          <MainButton
            v-if="!isMobile"
            styles="banner"
            :title="content.button"
          />
          <LinkButtonIcon v-else class="banner__link-icon" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PortTranzit from "@/assets/icons/svg/PortTranzit";
import LinkButtonIcon from "@/assets/icons/svg/LinkButtonIcon.vue";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";

export default {
  name: "ExpBanner",
  components: { MainButton, PortTranzit, LinkButtonIcon },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    bannerWidth() {
      return this.isMobile ? 162 + "px" : 218 + "px";
    },
    bannerHeight() {
      return this.isMobile ? 20 + "px" : 24 + "px";
    },
  },
  methods: {
    goToExp() {
      window.open(this.content.link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  display: block;
  height: 104px;
  padding: 24px;
  background: $greyBanner;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  &__roadLineLeft {
    &-one,
    &-two,
    &-tree {
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
    }
    &-one {
      height: 170%;
      width: 21.4vw;
      border-radius: 0 0 200px 0;
    }
    &-two {
      height: 110%;
      width: 16.7vw;
      border-radius: 0 0 130px 0;
    }
    &-tree {
      height: 40%;
      width: 11.9vw;
      border-radius: 0 0 60px 0;
    }
  }
  &__roadLineRight {
    &-one,
    &-two,
    &-tree {
      position: absolute;
      bottom: 0;
      right: 0;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
    }
    &-one {
      height: 170%;
      width: 21.4vw;
      border-radius: 160px 0 0 0;
    }
    &-two {
      height: 110%;
      width: 16.7vw;
      border-radius: 110px 0 0 0;
    }
    &-tree {
      height: 50%;
      width: 11.9vw;
      border-radius: 60px 0 0 0;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .portTranzit {
        background: $greyBanner;
        position: relative;
        width: fit-content;
        padding: 2px 4px 4px 0;
      }
      .content__text {
        background: $greyBanner;
        position: relative;
        padding: 2px;
      }
    }
    &-right {
      .banner__link {
        position: relative;
        width: fit-content;
      }
    }
  }
}
</style>
