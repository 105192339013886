<template>
  <TwoButtonsModal
    title="Недостаточно средств"
    description="Для бронирования таймслотов"
    left-button="Понятно"
    :right-button="isMobile ? 'Пополнить' : 'Пополнить баланс'"
    :success="true"
    @close="$emit('close')"
    @accept="toPayment"
  />
</template>

<script>
import TwoButtonsModal from "@/components/UI/modals/TwoButtonsModal.vue";
export default {
  name: "NoMoneyModal",
  components: { TwoButtonsModal },
  methods: {
    toPayment() {
      this.$emit("close");
      this.$router.push({ name: this.isMobile ? "balance" : "cabinet" });
    },
  },
};
</script>
