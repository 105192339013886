<template>
  <div :class="['truck', { active: isActive }]">
    <template v-if="truck">
      <div class="font1-24-30 truck__title">{{ truck.number }}</div>

      <div class="font2-14-18 truck__subtitle">Последние данные получены</div>

      <TruckGlonassInfo :truck="truck" size="huge" class="truck__info" />

      <CancelButton
        title="Закрыть"
        class="truck__button"
        @button-clicked="$emit('close')"
      />
    </template>
  </div>
</template>

<script>
import CancelButton from "@/components/UI/controls/buttons/CancelButton.vue";
import TruckGlonassInfo from "@/components/trucks/TruckGlonassInfo.vue";
export default {
  name: "MobileTruckDetail",
  components: { TruckGlonassInfo, CancelButton },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    truck: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.truck {
  position: absolute;
  bottom: calc(-216px - 7rem);
  width: 100%;
  padding: 32px 16px calc(32px + 7rem);
  background: #fff;
  border-radius: 20px 20px 0 0;
  z-index: 101;
  transition: bottom 0.3s ease;

  &.active {
    bottom: 0;
  }

  &__title {
    margin-bottom: 8px;
    color: $mainBlack;
  }
  &__subtitle {
    margin-bottom: 8px;
    color: $greyText;
  }
  &__info {
    margin-bottom: 24px;
  }
  &__button {
    width: 100%;
  }
}
</style>
