<template>
  <section>
    <component
      :is="currentView"
      @change-step="currentStep = $event"
      @finish="finish"
    />

    <TwoButtonsModal
      v-if="isLeaveModalOpen"
      title="Уходя с этой страницы"
      description="Вы отказываетесь от неподтвержденных таймслотов"
      left-button="Отменить"
      right-button="Отказаться"
      :success="false"
      @close="stayHere"
      @accept="forceRoute"
    />
  </section>
</template>

<script>
import DesktopGetTimeslots from "@/views/GetTimeslotsView/DesktopGetTimeslots";
import MobileGetTimeslots from "@/views/GetTimeslotsView/MobileGetTimeslots";
import TwoButtonsModal from "@/components/UI/modals/TwoButtonsModal";
import { removeTerminalTimezone } from "@/core";

export default {
  name: "GetTimeslotsView",
  components: { TwoButtonsModal },
  data() {
    return {
      // переменные для обработки попытки ухода со страницы
      currentStep: 1,
      isLeaveModalOpen: false,
      savedRoute: null,
      canRouteAway: false,
    };
  },
  computed: {
    currentView() {
      return this.isMobile ? MobileGetTimeslots : DesktopGetTimeslots;
    },
  },
  beforeRouteLeave(to, from, next) {
    // не даем уйти со страницы без дополнительного подтверждения
    // (это только для формы подтверждения таймслотов, раньше можно)
    if (!this.canRouteAway && this.currentStep === 3) {
      this.isLeaveModalOpen = true;
      this.savedRoute = to;
      return false;
    } else {
      next();
    }
  },
  methods: {
    // остаемся на странице
    stayHere() {
      this.isLeaveModalOpen = false;
    },
    // уходим со страницы
    forceRoute() {
      // TODO если перед уходом нужны какие-то дополнительные действия - они будут тут
      this.isLeaveModalOpen = false;
      this.canRouteAway = true;
      removeTerminalTimezone();
      this.$router.push(this.savedRoute);
    },
    // уходим по окончанию таймера или клику на модалку успеха
    finish() {
      this.canRouteAway = true;
      removeTerminalTimezone();
      this.$router.push({ name: "timeslots" });
    },
  },
};
</script>
