import { GET_IS_AUTH, SET_USER_DATA } from "@/store/actions";

export default {
  state: {
    user: {},
    privacyLinks: null,
  },
  getters: {
    [GET_IS_AUTH]: (state) => Boolean(Object.keys(state.user).length),
  },
  actions: {
    [SET_USER_DATA]: ({ commit }, data) => {
      commit(SET_USER_DATA, data);
    },
  },
  mutations: {
    [SET_USER_DATA]: (state, user) => {
      state.user = user;
    },
  },
};
