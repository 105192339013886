<template>
  <div class="empty">
    <div class="empty__icon">
      <EmptyTruckIcon />
    </div>
    <div class="font2-16-22 empty__title">{{ title }}</div>
  </div>
</template>

<script>
import EmptyTruckIcon from "@/assets/icons/svg/EmptyTruckIcon.vue";
export default {
  name: "EmptyTrucks",
  components: { EmptyTruckIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 320px;
  height: 100%;
  margin: 0 auto;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
  }

  &__title {
    font-weight: 500;
    color: $greyText;
    text-align: center;
  }
}
</style>
