import ContactsForm from "@/components/UI/controls/contactsForm/ContactsForm";
import { contactsFields } from "@/constants/contacts";
import { mapGetters } from "vuex";
import { GET_PROFILE } from "@/store/actions";
import Api from "@/api";

export default {
  components: { ContactsForm },
  data() {
    return {
      contactsFields,
      contacts: {},
      showSuccessModal: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: GET_PROFILE,
    }),
    phoneRef() {
      return this.contacts.contact_phone?.split(" ").join("") || "";
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      let { data } = await Api.contacts.getContacts();
      this.contacts = data;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    commentSent() {
      this.successInformer("Комментарий успешно отправлен");
    },
  },
};
