<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('icon-clicked')"
  >
    <rect width="40" height="40" rx="8" fill="white" />
    <g clip-path="url(#clip0_5500_90092)">
      <path
        d="M20 14.1667V25.8334"
        stroke="#4CC0CC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.166 20H25.8327"
        stroke="#4CC0CC"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5500_90092">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(10 10)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AddMoneyIcon",
};
</script>

<style scoped></style>
