import {
  GET_TERMINAL_CITIES,
  SET_TERMINAL_CITIES,
  FETCH_TERMINAL_CITIES,
  GET_LOADING,
  SET_LOADING,
} from "@/store/actions";
import API from "@/api";

export default {
  state: {
    cities: [],
    loading: false,
  },
  getters: {
    [GET_LOADING]: (state) => state.loading,
    [GET_TERMINAL_CITIES]: (state) => state.cities,
  },
  actions: {
    [FETCH_TERMINAL_CITIES]: async ({ commit }) => {
      try {
        const { data } = await API.cities.getCities();
        commit(SET_TERMINAL_CITIES, data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    [SET_TERMINAL_CITIES]: (state, cities) => {
      state.cities = cities;
    },
    [SET_LOADING]: (state, mode) => {
      state.loading = mode;
    },
  },
};
