<template>
  <section class="cabinetMobile">
    <MobileHeader title="Профиль" />

    <div class="cabinetMobile__content">
      <CabinetProfile v-if="user" :user="user" />
    </div>
  </section>
</template>

<script>
import MobileHeader from "@/components/mobileHeader/MobileHeader";
import cabinetMixin from "@/views/CabinetView/cabinetMixin";

export default {
  name: "MobileCabinetView",
  components: { MobileHeader },
  mixins: [cabinetMixin],
};
</script>

<style lang="scss" scoped>
.cabinetMobile {
  background: #fff;
  &__content {
    padding: 66px 16px 80px;
  }
}
</style>
