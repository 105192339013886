<template>
  <div class="select">
    <div
      :class="['select__main', { disabled: disabled }]"
      @click="toggleDropdown"
    >
      <div class="select__main-prefix">
        <img src="@/assets/images/Clock.svg" alt="clock" />
      </div>
      <div>
        <div
          :class="['select__main-title font2-14-18', { filled: value.length }]"
        >
          {{ placeholder }}
        </div>
        <div v-if="value.length" class="select__main-value font2-16-20">
          {{ value }}
        </div>
      </div>
    </div>
    <div
      :class="[
        'select__dropdown',
        { open: isDropdown },
        { mobile: isMobile },
        { 'no-scroll': options.length < 6 },
      ]"
    >
      <div
        v-if="isDropdown && isMobile"
        :class="['select__dropdown-header', { open: isDropdown }]"
      >
        <img
          src="@/assets/images/fat-close.svg"
          alt="close"
          @click="isDropdown = false"
        />
        <div class="font1-20-24 header__title">{{ placeholder }}</div>
      </div>

      <div
        v-for="time in options"
        :key="time.value"
        :class="[
          'select__dropdown-item font2-14-18',
          { active: time.label === value },
        ]"
        @click="setResult(time)"
      >
        {{ time.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeSelect",
  props: {
    time: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdown: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.time;
      },
      set(value) {
        this.$emit("select", value);
      },
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  methods: {
    toggleDropdown() {
      if (!this.disabled) {
        this.isDropdown = !this.isDropdown;
      }
    },
    setResult(value) {
      this.value = value.label;
      this.isDropdown = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.isDropdown = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  position: relative;

  &__main {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 17px;
    background: $bckgrnd;
    border-radius: 10px;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &-prefix {
      margin-right: 8px;
    }

    &-title {
      color: $greyText;
      &.filled {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 52px;
    left: 0;
    right: 0;
    height: 0;
    overflow: auto;
    background: #fff;
    box-shadow: 0 0 10px rgba(132, 132, 132, 0.11);
    border-radius: 6px 4px 4px 6px;
    z-index: 15;
    transition: height 0.3s ease;
    &.open {
      height: 200px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: $backgroundGray;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #e0e5e6;
      border-radius: 4px;
    }

    &.no-scroll {
      overflow-y: hidden;
    }

    &.mobile {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: unset;
      padding: 0;
      border-radius: unset;
      transition: none;
      &.open {
        height: -webkit-fill-available;
        height: 100vh;
        padding: 80px 0;
        overflow: auto;
        @supports (-webkit-touch-callout: none) {
          padding-bottom: 200px;
        }
      }
    }

    &-header {
      position: fixed;
      top: 0;
      width: 100%;
      height: 0;
      display: flex;
      align-items: center;
      gap: 18px;
      padding: 16px 16px 0;
      background: #fff;
      z-index: 15;
      &.open {
        height: 56px;
      }

      &__title {
        font-weight: 500;
        color: $mainBlack;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 16px;
      font-weight: 500;
      color: $mainBlack;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        font-weight: 700;
        background: $bckgrnd;
        color: $special;
      }
      &.active {
        font-weight: 700;
        color: $special;
      }
    }
  }
}
</style>
