export const methodRadioData = [
  {
    title: "Оплата через СБП",
    value: 1,
    icon: "sbp",
    action: "paySbp",
  },
  {
    title: "Оплата по счету",
    value: 2,
    icon: "bill",
    action: "payBill",
  },
  {
    title: "Другое",
    value: 3,
    icon: "others",
    action: "payOther",
  },
];

export const billRadioData = [
  {
    title: "Сформировать и скачать",
    value: 1,
    icon: "download",
  },
  {
    title: "Сформировать и отправить на почту",
    value: 2,
    icon: "email",
  },
];
