<template><component :is="currentView" /></template>

<script>
import DesktopContactsView from "@/views/СontactsView/DesktopContactsView";
import MobileContactsView from "@/views/СontactsView/MobileContactsView";

export default {
  name: "ContactsView",
  components: {},
  computed: {
    currentView() {
      return this.isMobile ? MobileContactsView : DesktopContactsView;
    },
  },
};
</script>
