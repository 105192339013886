<template>
  <section class="home" v-loading="loading">
    <div class="home__header font1-18-24">Добро пожаловать{{ userName }}!</div>
    <div class="home__page">
      <div class="home__page-left">
        <HomeMessage :content="message" />
        <WorkBanner :content="nrzBanner" class="work-banner" />
        <HomeTimeslots :timeslots="timeslots" />
        <SecondaryButton
          title="Получить таймслот"
          class="get-timeslots"
          @button-clicked="$router.push({ name: 'getTimeslots' })"
        />
        <ExpBanner :content="expBanner" class="exp-banner" />
      </div>
      <div class="home__page-right">
        <StatisticsNovoross />
      </div>
    </div>
  </section>
</template>

<script>
import StatisticsNovoross from "@/components/home/rightSide/StatisticsNovoross.vue";
import SecondaryButton from "@/components/UI/controls/buttons/SecondaryButton.vue";
import HomeMixin from "@/views/HomeView/HomeMixin";

export default {
  name: "DesktopHomeView",
  components: {
    SecondaryButton,
    StatisticsNovoross,
  },
  mixins: [HomeMixin],
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    height: 64px;
    padding: 0 32px;
    background: #fff;
    color: $textSecondary;
  }

  &__page {
    flex: 1;
    display: flex;
    padding: 24px 32px 0;

    &-left {
      flex: 1;
      min-width: calc(100vw - 680px);
      .work-banner {
        margin-bottom: 24px;
      }
      .get-timeslots {
        margin-top: 16px;
      }
      .exp-banner {
        margin-top: 24px;
        margin-bottom: 30px;
      }
    }
    &-right {
      width: 26.5vw;
      margin-left: 24px;
      background-color: #fff;
      border-radius: 20px;
    }
  }
}
</style>
