import CabinetProfile from "@/components/cabinet/CabinetProfile";
import { mapGetters } from "vuex";
import { GET_PROFILE } from "@/store/actions";

export default {
  components: { CabinetProfile },
  computed: {
    ...mapGetters({
      user: GET_PROFILE,
    }),
  },
};
