import { Request, getParamsUrl } from "@/core";
import { timeslots } from "@/constants/urls";

export default {
  // удаление подтвержденного таймслота (из любой Очереди)
  deleteTimeslot(data) {
    return Request.post(timeslots.deleteTimeslot, data);
  },

  getArchiveTimeslots(params) {
    return Request.get(`${timeslots.archiveTimeslots}${getParamsUrl(params)}`);
  },

  // запрос переноса таймслота
  rescheduleTimeslot(data) {
    return Request.post(timeslots.rescheduleTimeslot, data);
  },
  // подтверждение переноса таймслота
  submitRescheduleTimeslot(data) {
    return Request.post(timeslots.submitRescheduleTimeslot, data);
  },

  // список подсказок для ИНН перевозчика
  getCarrierAutofill(inn) {
    return Request.get(`${timeslots.getCarrierAutofill}?inn=${inn}`);
  },

  // проверка ИНН перевозчика
  checkCarrierInn(inn) {
    return Request.get(`${timeslots.checkCarrierInn}?inn=${inn}`);
  },

  // запрос на резервирование таймслота(-ов)
  requestTimeslots(data) {
    return Request.post(timeslots.requestTimeslots, data);
  },
  // данные для автозаполнения формы машин-водителей
  getAutofillsList(terminal) {
    return Request.get(`${timeslots.getAutofillList}?terminal_id=${terminal}`);
  },

  // подтверждение таймслота
  submitTimeslot(data) {
    return Request.post(timeslots.submitTimeslot, data);
  },

  // оплата таймслота (списание денег с кошелька за таймслот(-ы)
  payTimeslots(data) {
    return Request.post(timeslots.payTimeslots, { purchases: data });
  },
};
