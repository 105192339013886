<template>
  <component
    :is="currentView"
    :user="user"
    @submit="submit"
    @close="$emit('close')"
  />
</template>

<script>
import PrivacyMobile from "@/components/UI/modals/PrivacyPolicy/PrivacyMobile.vue";
import PrivacyDesktop from "@/components/UI/modals/PrivacyPolicy/PrivacyDesktop.vue";
import API from "@/api";

export default {
  name: "PrivacyPolicyModal",
  components: { PrivacyDesktop, PrivacyMobile },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentView() {
      return this.isMobile ? PrivacyMobile : PrivacyDesktop;
    },
  },
  methods: {
    async submit() {
      const data = new FormData();
      data.append("phone_number", this.user.phone_number);

      try {
        await API.auth.acceptTermAgreement(data);
        this.$emit("agreed");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
