<template>
  <el-form-item class="grid-content">
    <div class="input" @click="start">
      <div :class="['input__title', { inFocus: inFocus }]">
        {{ title }}
        <span v-if="!selectVal.length && !inFocus">...</span>
      </div>
      <el-input
        ref="textarea"
        type="textarea"
        :rows="rows"
        resize="none"
        :maxlength="length"
        show-word-limit
        v-model="selectVal"
        @input="handleSelect"
        @focus="onFocus"
        @blur="changeTitle"
      />
    </div>
  </el-form-item>
</template>

<script>
export default {
  name: "MainTextArea",

  props: {
    title: {
      type: String,
      default: "Select",
    },
    value: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: 3,
    },
    length: {
      type: String,
      default: "200",
    },
  },

  data() {
    return {
      inFocus: "",
    };
  },

  computed: {
    selectVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.selectVal ? (this.inFocus = "inFocus") : "";
  },

  methods: {
    start() {
      this.$refs.textarea.focus();
    },
    onFocus() {
      this.inFocus = "inFocus";
      this.$emit("editing");
    },
    handleSelect(event) {
      this.$emit("onChange", event);
    },
    changeTitle() {
      this.inFocus = this.selectVal.length ? "inFocus" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  position: relative;
  &__title {
    position: absolute;
    font-family: $fontSecondary;
    color: $greyText;
    font-size: 14px;
    top: 10px;
    left: 17px;
    transition: 0.3s all;
    z-index: 4;
    &.inFocus {
      font-size: 12px;
      transition: font-size 0.3s;
    }
  }
  .el-textarea {
    &::v-deep .el-textarea__inner {
      font-family: $fontSecondary;
      background-color: $input;
      border-radius: 12px;
      border: none;
      padding: 26px 16px 8px;
      color: $mainInputValue;
      &::placeholder {
        position: absolute;
        top: 26px;
        font-family: $fontSecondary;
        color: $greyText;
      }

      &:focus {
        border: 1px solid $select;
      }
    }
    &::v-deep .el-input__count {
      left: 0;
      bottom: -17px;
      line-height: 16px;
      color: $greyText;
    }
  }
}
</style>
