<!--чисто мобильный роут, поэтому нет деления на desktop/mobile-->
<template>
  <section class="balance">
    <MobileHeader
      title="Баланс"
      :has-chevron="true"
      @back="$router.push({ name: 'cabinet' })"
    />

    <div class="balance__account">
      <CabinetWallet class="balance__body-wallet" :user="user" />
    </div>

    <div class="balance__body">
      <CabinetOperations class="balance__body-operations" />
    </div>
  </section>
</template>

<script>
import MobileHeader from "@/components/mobileHeader/MobileHeader.vue";
import CabinetOperations from "@/components/cabinet/CabinetOperations/CabinetOperations.vue";
import CabinetWallet from "@/components/balance/CabinetWallet.vue";
import cabinetMixin from "@/views/CabinetView/cabinetMixin";

export default {
  name: "BalanceView",
  components: { CabinetWallet, CabinetOperations, MobileHeader },
  mixins: [cabinetMixin],
  created() {
    this.checkResolution();
    window.addEventListener("resize", this.checkResolution);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkResolution);
  },
  methods: {
    checkResolution() {
      if (!this.isMobile) {
        this.$router.push({ name: "cabinet" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.balance {
  background: #fff;
  padding: 48px 0 70px;

  &__account {
    position: fixed;
    top: 48px;
    width: 100%;
    padding: 16px 16px 0;
    background: #fff;
    z-index: 2;
  }

  &__body {
    padding: 104px 16px 0;
  }
}
</style>
