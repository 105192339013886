import HomeView from "@/views/HomeView/HomeView.vue";
import LoginView from "@/views/AuthViews/loginView/LoginView";
import ConfirmView from "@/views/AuthViews/confirmView/ConfirmView";
import GetTimeSlotsView from "@/views/GetTimeslotsView/GetTimeslotsView";
import CabinetView from "@/views/CabinetView/CabinetView";
import ContactsView from "@/views/СontactsView/ContactsView";
import TimeslotsView from "@/views/TimeslotsView/TimeslotsView";
import UnpaidView from "@/views/UnpaidView/UnpaidView.vue";
import MoreMenuView from "@/views/MoreMenuView/MoreMenuView";
import Page404View from "@/views/Page404View/Page404View";
import TrucksView from "@/views/TrucksView/TrucksView.vue";
import ArchiveTimeslotsView from "@/views/ArchiveTimeslotsView/ArchiveTimeslotsView.vue";
import StatisticsView from "@/views/StatisticsView/StatisticsView.vue";
import BalanceView from "@/views/BalanceView/BalanceView.vue";

import PrivacyPolicy from "@/views/Documents/PrivacyPolicy.vue";
import AdvertisingAgreement from "@/views/Documents/AdvertisingAgreement.vue";
import LicenseAgreement from "@/views/Documents/LicenseAgreement.vue";
import PublicOferta from "@/views/Documents/PublicOferta.vue";
import PersonalData from "@/views/Documents/PersonalData.vue";

const routes = [
  // роуты авторизации
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { layout: "AuthLayout" },
  },
  {
    path: "/confirm",
    name: "confirm",
    component: ConfirmView,
    meta: { layout: "AuthLayout" },
  },

  // роуты общих страниц
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/timeslots/list",
    name: "timeslots",
    component: TimeslotsView,
  },
  {
    path: "/timeslots/archive",
    name: "archiveTimeslots",
    component: ArchiveTimeslotsView,
  },
  {
    path: "/timeslots/get",
    name: "getTimeslots",
    component: GetTimeSlotsView,
  },

  {
    path: "/unpaid",
    name: "unpaid",
    component: UnpaidView,
  },

  {
    path: "/trucks",
    name: "trucks",
    component: TrucksView,
  },

  {
    path: "/cabinet",
    name: "cabinet",
    component: CabinetView,
  },

  {
    path: "/contacts",
    name: "contacts",
    component: ContactsView,
  },

  // роуты для мобильных страниц
  {
    path: "/more-menu",
    name: "moreMenu",
    component: MoreMenuView,
  },

  {
    path: "/statistics",
    name: "statistics",
    component: StatisticsView,
  },
  {
    path: "/balance",
    name: "balance",
    component: BalanceView,
  },

  // прочее
  {
    path: "*",
    name: "404",
    component: Page404View,
  },

  // роуты всяких документов
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { layout: "DocLayout" },
  },
  {
    path: "/advertising-agreement",
    name: "AdvertisingAgreement",
    component: AdvertisingAgreement,
    meta: { layout: "DocLayout" },
  },
  {
    path: "/license-agreement",
    name: "LicenseAgreement",
    component: LicenseAgreement,
    meta: { layout: "DocLayout" },
  },
  {
    path: "/personal-data",
    name: "PersonalData",
    component: PersonalData,
    meta: { layout: "DocLayout" },
  },
  {
    path: "/public-oferta",
    name: "PublicOferta",
    component: PublicOferta,
    meta: { layout: "DocLayout" },
  },
];

export default routes;
