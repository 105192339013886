<template>
  <section v-loading="isLoading" class="container">
    <div class="font1-18-24 contacts__header">Контакты</div>
    <!-- Title -->
    <div class="contacts">
      <div class="contacts__left">
        <div class="infoblock mod-details">
          <div class="font1-20-24 infoblock__title">Реквизиты</div>

          <div
            v-for="(item, index) in contactsFields.detail"
            :key="index"
            class="font2-14-18 infoblock__item"
          >
            <div class="infoblock__item-key">{{ item.key }}</div>
            <div class="infoblock__item-value">
              {{ contacts[item.value] }}
            </div>
          </div>
        </div>

        <div class="infoblock mod-bank">
          <div class="font1-20-24 infoblock__title">Банковские реквизиты</div>

          <div
            v-for="(item, index) in contactsFields.bank"
            :key="index"
            class="font2-14-18 infoblock__item"
          >
            <div class="infoblock__item-key">{{ item.key }}</div>
            <div class="infoblock__item-value">
              {{ contacts[item.value] }}
            </div>
          </div>
        </div>
      </div>

      <div class="contacts__right">
        <div class="infoblock mod-contacts">
          <div class="font1-20-24 infoblock__title">Связаться</div>

          <div class="connections">
            <div class="connections__item">
              <div class="connections__item-icon"><MailIcon /></div>
              <div>
                <div class="infoblock__item-key">Почта</div>
                <a
                  :href="`mailto:${contacts.contact_email}`"
                  class="font2-16-22 connections__item-value"
                >
                  {{ contacts.contact_email }}
                </a>
              </div>
            </div>

            <div class="connections__item">
              <div class="connections__item-icon"><PhoneIcon /></div>
              <div>
                <div class="infoblock__item-key">Телефон</div>
                <a
                  :href="`tel:${phoneRef}`"
                  class="font2-16-22 connections__item-value"
                >
                  {{ contacts.contact_phone }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="infoblock mod-feedback">
          <div class="font1-20-24 infoblock__title">Задайте вопрос</div>

          <ContactsForm v-if="user" @success="commentSent" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PhoneIcon from "@/assets/icons/svg/PhoneIcon";
import MailIcon from "@/assets/icons/svg/MailIcon";

import contactsMixin from "@/views/СontactsView/contactsMixin";

export default {
  name: "DesktopContactsView",
  components: { PhoneIcon, MailIcon },
  mixins: [contactsMixin],
};
</script>

<style lang="scss" scoped>
.contacts__header {
  display: flex;
  align-items: center;
  height: 64px;
  padding-left: 32px;
  border-bottom: 1px solid $backgroundGray;
  background: #fff;
  font-weight: 500;
  color: $mainBlack;
}
.contacts {
  display: flex;
  justify-content: space-between;
  padding: 32px;

  &__left {
    width: 44%;
  }
  &__right {
    width: 54%;
  }
  .infoblock {
    padding: 32px 2.2vw;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(132, 132, 132, 0.15);
    border-radius: 16px;
    &.mod-details {
      height: 280px;
      margin-bottom: 20px;
    }
    &.mod-bank {
      height: 260px;
    }
    &.mod-contacts {
      height: 156px;
      margin-bottom: 20px;
    }
    &.mod-feedback {
      display: flex;
      flex-direction: column;
      height: 384px;
    }

    &__title {
      margin-bottom: 24px;
      font-weight: 500;
      color: $mainBlack;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      font-size: 14px;
      &-key {
        width: 38%;
        color: $greyText;
      }
      &-value {
        width: 52%;
        font-weight: 500;
        color: $textSecondary;
      }
    }

    .connections {
      display: flex;
      align-items: center;
      &__item {
        display: flex;
        align-items: center;
        width: 48%;

        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 44px;
          height: 44px;
          margin-right: 16px;
          background: #f6f8f8;
          border-radius: 12px;
        }

        &-value {
          margin-top: 4px;
          font-weight: 700;
          color: $special;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
