<template>
  <section v-loading="isLoading" class="archive">
    <TimeslotsHeader active="archiveTimeslots" />
    <div class="archive__filters">
      <DatePicker
        :date="startDate"
        :options="startPickerOptions"
        placeholder="Период с"
        class="archive__filters-picker"
        @select="setStartDate"
      />

      <DatePicker
        :date="finishDate"
        :options="finishPickerOptions"
        :disabled="!startDate"
        placeholder="Период по"
        class="archive__filters-picker"
        @select="setFinishDate"
      />

      <CustomSelect
        :model="currentTerminalId"
        placeholder="Терминал"
        :options="terminalsOptions"
        :disabled="!startDate && !finishDate"
        class="archive__filters-select"
        @select="setTerminal"
      />

      <div
        :class="['archive__filters-button', { disabled: !isButtonEnabled }]"
        @click="sendQuery"
      >
        <img src="@/assets/images/SearchWhite.svg" alt="search" />
      </div>

      <div
        v-if="startDate"
        class="font2-14-18 archive__filters-clear"
        @click="dropFilters"
      >
        <span>Очистить фильтр</span>

        <img src="@/assets/images/close-icon.svg" alt="search" />
      </div>
    </div>
    <div class="archive__wrapper">
      <div
        v-if="timeslots.length && isAfterQuery"
        class="archive__wrapper-list"
      >
        <TimeslotCard
          v-for="timeslot in timeslots"
          :key="timeslot.id"
          :timeslot="timeslot"
        />
      </div>
      <ArchiveEmpty v-if="!isAfterQuery" />
      <ArchiveEmpty
        v-if="!timeslots.length && isAfterQuery"
        :is-after-search="true"
      />
    </div>
  </section>
</template>

<script>
import TimeslotsHeader from "@/components/timeslots/common/TimeslotsHeader";
import archiveTimeslotsMixin from "@/views/ArchiveTimeslotsView/archiveTimeslotsMixin";

export default {
  name: "DesktopArchiveView",
  components: { TimeslotsHeader },
  mixins: [archiveTimeslotsMixin],
};
</script>

<style lang="scss" scoped>
.archive {
  &__filters {
    position: fixed;
    top: 60px;
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 26px 32px 16px;
    background: #fff;
    border-bottom: 1px solid $backgroundGray;

    &-select {
      width: 376px;
    }

    &-picker {
      :deep .el-date-editor.el-input {
        width: 184px;
      }
    }

    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background: $special;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background: $hover;
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
          background: $special;
        }
      }
    }

    &-clear {
      display: flex;
      align-items: center;
      gap: 2px;
      font-weight: 500;
      color: $greyText;
      cursor: pointer;
    }
  }

  &__wrapper {
    min-height: calc(100vh - 157px);
    margin-top: 157px;
    padding: 32px;

    &-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
</style>
