<template>
  <section v-loading="isLoading" class="statistics">
    <div class="statistics__handler">
      <div class="font1-20-24 statistics__handler-title">
        Статистика перевозок
      </div>

      <div class="pseudo-select">
        <div>
          <div class="font2-12-16 pseudo-select__title">Регион</div>
          <div class="pseudo-select__value font2-16-20">Новороссийск</div>
        </div>
      </div>

      <CustomSelect
        :model="statForm.terminal_id"
        placeholder="Терминал"
        :options="terminalsOptions"
        class="terminal-select"
        @select="setTerminal"
      />

      <CustomSelect
        :model="statForm.status_id"
        placeholder="Статус"
        :options="statusesOptions"
        :disabled="!statForm.terminal_id"
        class="status-select"
        @select="sendQuery"
      />
    </div>
    <div class="statistics__body">
      <div v-if="currentStatistics.length && !isLoading" class="stat">
        <div class="font2-12-16 stat__header">
          <div :class="['stat__culture', { brief: hasTimeStatistics }]">
            Культура
          </div>

          <div class="stat__quantity">Кол-во</div>

          <div v-if="hasTimeStatistics" class="stat__time">
            Первый в очереди
          </div>
        </div>

        <div
          v-for="item in currentStatistics"
          :key="item.culture"
          class="font2-14-18 stat__item"
        >
          <div :class="['stat__culture', { brief: hasTimeStatistics }]">
            {{ item.culture }}
          </div>

          <div class="stat__quantity">{{ item.count }}</div>

          <div v-if="hasTimeStatistics" class="stat__time">
            <div>{{ item.first_in_queue_date }}</div>
            <div class="stat__time-time">{{ item.first_in_queue_time }}</div>
          </div>
        </div>
      </div>

      <div v-if="!statForm.status_id" class="nothing">
        <div class="nothing__icon">
          <img src="@/assets/images/Search.svg" alt="nothing" />
        </div>
        <div class="font2-16-20 nothing__description">
          Для просмотра статистики выберите терминал и статус
        </div>
      </div>

      <div
        v-if="statForm.status_id && !currentStatistics.length && !isLoading"
        class="nothing"
      >
        <div class="nothing__icon">
          <img src="@/assets/images/no-timeslots.svg" alt="nothing" />
        </div>
        <div class="font1-20-24 nothing__text">
          По заданным параметрам ничего не найдено
        </div>
        <div class="font2-14-18 nothing__description">
          Попробуйте ввести новые данные
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CustomSelect from "@/components/UI/controls/form/CustomSelect.vue";
import Api from "@/api";
export default {
  name: "StatisticsNovoross",
  components: {
    CustomSelect,
  },
  data() {
    return {
      terminalsOptions: [],
      statusesOptions: [],
      statForm: {
        terminal_id: "",
        status_id: "",
      },
      currentStatistics: [],
      isLoading: false,
    };
  },
  computed: {
    hasTimeStatistics() {
      return this.currentStatistics[0]?.first_in_queue_date;
    },
  },
  async mounted() {
    let cities = await Api.cities.getCities();

    let idNovoross = cities.data.find(
      (item) => item.name === "Новороссийск"
    ).id;
    let terminals = await Api.terminals.getTerminals({ city_id: idNovoross });
    this.terminalsOptions = terminals.data;

    let { data } = await Api.statistics.getStatuses();
    this.statusesOptions = data;
  },
  methods: {
    setTerminal(id) {
      this.statForm.terminal_id = id;
      this.currentStatistics = [];
      this.statForm.status_id = "";
    },
    async sendQuery(status) {
      this.statForm.status_id = status;
      if (status) {
        try {
          this.isLoading = true;
          let { data } = await Api.statistics.getStatistics(this.statForm);
          this.currentStatistics = data;
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading = false;
        }
      } else {
        this.currentStatistics = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  padding: 24px;
  &__handler {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &-title {
      font-weight: 500;
      color: $mainBlack;
    }
  }

  .pseudo-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 16px;
    background: $bckgrnd;
    border-radius: 10px;
    &__title {
      color: $greyText;
    }
    &__value {
      font-weight: 500;
      color: $mainBlack;
    }
  }

  &__body {
    margin-top: 24px;

    .stat__header {
      display: flex;
      align-items: center;
      height: 48px;
      border-bottom: 1px solid $backgroundGray;
      font-weight: 700;
      color: $greyText;
    }

    .stat__item {
      display: flex;
      padding: 12px 0;
      font-weight: 500;
      color: $mainBlack;
    }

    .stat__culture {
      width: 82%;
      &.brief {
        width: 58%;
      }
    }
    .stat__quantity {
      width: 18%;
      text-align: right;
    }
    .stat__time {
      width: 24%;
      text-align: right;
      &-time {
        color: $greyText;
      }
    }

    .nothing {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      padding: 40px 0;

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background: $bckgrnd;
        border-radius: 50%;
      }

      &__text {
        width: 260px;
        font-weight: 500;
        color: $mainBlack;
        text-align: center;
      }

      &__description {
        width: 260px;
        font-weight: 500;
        color: $greyText;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1279px) {
  .statistics {
    padding: 0;
    &__handler {
      padding: 72px 16px 32px;
      background: #fff;
    }
    &__body {
      margin-top: 16px;
      padding: 8px 16px 16px;
      background: #fff;
    }
  }
}
</style>
