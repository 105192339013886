<template>
  <ModalWrapper v-on="$listeners">
    <div class="confirmation">
      <div class="font1-24-30 confirmation__title">
        Ваши таймслоты для бронирования
      </div>

      <div class="confirmation__headers">
        <div class="font2-14-18 header">
          <div class="header__title">Терминал</div>
          <div class="header__value">{{ terminalName }}</div>
        </div>
        <div class="font2-14-18 header">
          <div class="header__title">Культура</div>
          <div class="header__value">{{ textValues.cultureName }}</div>
        </div>
        <div v-if="textValues.truckTypeName" class="font2-14-18 header">
          <div class="header__title">Тип авто</div>
          <div class="header__value">{{ textValues.truckTypeName }}</div>
        </div>
        <div v-if="textValues.supplierName" class="font2-14-18 header">
          <div class="header__title">Поставщик</div>
          <div class="header__value">{{ textValues.supplierName }}</div>
        </div>
      </div>

      <div class="confirmation__list">
        <div
          v-for="timeslot in confirmationList"
          :key="timeslot.timeslotId"
          class="timeslot"
        >
          <div class="font2-16-20 timeslot__truck">
            {{ timeslot.plate_truck }}
          </div>
          <RequestDateTime :timeslot="timeslot" styles="gray" />
        </div>
      </div>

      <div class="confirmation__price">
        <div class="font2-14-18 confirmation__price-title">
          Цена за таймслоты
        </div>
        <div class="font1-20-24 confirmation__price-value">
          {{ totalPrice }}&nbsp;₽
        </div>
      </div>

      <div class="confirmation__list mod-buttons">
        <MainButton
          v-if="postponed"
          title="Забронировать и оплатить"
          @button-clicked="$emit('confirm')"
        />
        <MainButton
          title="Забронировать"
          @button-clicked="$emit('confirm', postponed)"
        />
        <CancelButton title="Отменить" @button-clicked="$emit('close')" />
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import ModalWrapper from "@/components/UI/ModalWrapper.vue";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import CancelButton from "@/components/UI/controls/buttons/CancelButton.vue";
import RequestDateTime from "@/components/timeslots/common/RequestDateTime.vue";
import { formattedNumberRu } from "@/core";

export default {
  name: "ConfirmGeneralModal",
  components: { MainButton, ModalWrapper, CancelButton, RequestDateTime },
  props: {
    terminalName: {
      type: String,
      default: "",
    },
    timeslots: {
      type: Array,
      default: () => [],
    },
    postponed: {
      type: Boolean,
      default: false,
    },
    textValues: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    confirmationList() {
      return this.timeslots.filter(
        (item) => !item.isDeleted && !item.isConfirmed
      );
    },
    totalPrice() {
      return formattedNumberRu(
        this.confirmationList.reduce(
          (sum, item) => sum + item.timeslot_price_cop,
          0
        ) / 100
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 440px;

  &__title {
    color: $mainBlack;
  }

  &__headers {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .header {
      display: flex;
      &__title {
        width: 140px;
        font-weight: 500;
        color: $greyText;
      }
      &__value {
        font-weight: 700;
        color: $textSecondary;
      }
    }
  }

  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    border-top: 1px dashed $greyBanner;
    border-bottom: 1px dashed $greyBanner;
    &-title {
      font-weight: 500;
      color: $textSecondary;
    }
    &-value {
      font-weight: 500;
      color: $mainBlack;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .timeslot {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 12px 16px;
    gap: 16px;
    border-radius: 10px;
    border: 1px solid $greyBanner;
    &__truck {
      display: flex;
      align-items: center;
      width: 155px;
      height: 26px;
      padding-left: 36px;
      margin-right: 32px;
      font-weight: 500;
      color: $mainBlack;
      border-right: 1px solid $greyBanner;
    }
  }
}

@media (max-width: 1279px) {
  :deep .modal__content {
    width: 100%;
    height: 100%;
    border-radius: unset;
    overflow: auto;
  }
  .confirmation {
    width: 100%;
    gap: 16px;

    &__title {
      font-size: 20px;
      line-height: 26px;
    }

    &__headers {
      gap: 8px;

      .header {
        flex-direction: column;
        gap: 2px;
        padding-bottom: 8px;
        border-bottom: 1px solid $backgroundGray;

        &__title {
          width: 100%;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .timeslot {
      &__truck {
        width: 112px;
        padding-left: 0;
        margin-right: 0;
      }
    }

    .mod-buttons {
      padding-top: 8px;
    }
  }
}
</style>
