<template>
  <section v-loading="isLoading" :class="['trucks', { fixed: mapView }]">
    <MobileHeader
      title="Мои машины"
      :has-chevron="true"
      :has-refresh="true"
      @back="$router.push({ name: 'moreMenu' })"
    />
    <MobileTrucksTabs
      :active="mapView"
      class="trucks__tab"
      @change="changeView"
    />
    <div v-if="mapView" class="trucks__content">
      <YandexMapComponent
        v-if="yandexMapBounds.length"
        :points="yandexMapList"
        :bounds="yandexMapBounds"
        @item-selected="showDetail"
      />
      <EmptyTrucks
        v-if="!yandexMapBounds.length && !isLoading"
        title="У вас нет машин с позиционированием по ГЛОНАСС"
      />
    </div>
    <div v-else class="trucks__content">
      <div v-if="trucksList.length" class="trucks__content-list">
        <TruckCard
          v-for="truck in trucksList"
          :key="truck.truck_id"
          :truck="truck"
          :is-active="truck.truck_id === activeTruck"
        />
      </div>
      <EmptyTrucks
        v-if="!trucksList.length && !isLoading"
        title="У вас еще не добавлено ни одного автомобиля"
      />
    </div>
    <MobileTruckDetail
      v-if="mapView"
      :is-active="isShowDetail"
      :truck="detailTruck"
      @close="isShowDetail = false"
    />
  </section>
</template>

<script>
import MobileHeader from "@/components/mobileHeader/MobileHeader.vue";
import MobileTrucksTabs from "@/components/trucks/MobileTrucksTabs.vue";
import MobileTruckDetail from "@/components/trucks/MobileTruckDetail.vue";
import trucksMixin from "@/views/TrucksView/trucksMixin";

export default {
  name: "MobileTrucksView",
  components: {
    MobileHeader,
    MobileTrucksTabs,
    MobileTruckDetail,
  },
  mixins: [trucksMixin],
  data() {
    return {
      mapView: 0,
      isShowDetail: false,
      detailTruck: null,
    };
  },
  methods: {
    changeView(active) {
      this.mapView = active;
    },
    showDetail(id) {
      console.log(id);
      this.detailTruck = this.trucksList.find((item) => item.truck_id === id);
      this.isShowDetail = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.trucks {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  padding: 96px 0 7rem;
  background: $bckgrnd;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100dvh;
  }

  &__tab {
    position: fixed;
    top: 48px;
    left: 0;
    right: 0;
  }

  &__content {
    flex: 1;

    &-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px 16px 86px;
    }
  }
}
</style>
