<template>
  <yandex-map
    :bounds="bounds"
    :settings="settings"
    :coords="center"
    :controls="controls"
    :cluster-options="clusterOptions"
    ymap-class="ymap-driver"
    @map-was-initialized="mapLoaded"
  >
    <ymap-marker
      v-for="truck in currentList"
      :key="truck.truck_id"
      marker-type="placemark"
      :marker-id="truck.truck_id"
      :coords="truck.coords"
      :icon="{ ...markerIcon, content: truck.number }"
      cluster-name="cluster"
      @click="clickSingleBid(truck.truck_id)"
    />
  </yandex-map>
</template>

<script>
// структура данных, с которой работает компонент (prop points item)
// id: number - ИД авто, которое карта возвращает при клике
// coords: Array[string, string] - координаты точки погрузки
// number: string - госномер авто
import {
  MAP_CENTER,
  clusterBidsOptions,
  markerIcon,
  ymapSettings,
} from "./constants";

export default {
  name: "YandexMapComponent",
  props: {
    points: {
      type: Array,
      default: () => [],
    },
    bounds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      controls: ["zoomControl"],
      center: MAP_CENTER,
      settings: ymapSettings,
      clusterOptions: clusterBidsOptions,
      map: null,
      currentList: [],
      markerIcon,
    };
  },
  methods: {
    mapLoaded(e) {
      // сохраняем инстанс карты (вдруг кому пригодится)
      // хотя потом почему-то приходится использовать window.ymaps
      e.options.set("suppressMapOpenBlock", true);
      // а вообще теоретически инстанс карты будет здесь
      this.map = e;
      this.currentList = this.points;
    },

    // клик по одиночной метке
    // возвращаем числовое значение ИД
    clickSingleBid(id) {
      this.$emit("item-selected", Number(id));
    },
    refreshMap() {
      this.currentList = this.points;
    },
  },
};
</script>

<style lang="scss" scoped>
.ymap-container {
  height: 100%;
  :deep .ymap-driver {
    height: 100%;
  }
}
:deep .marker {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2px;
  width: fit-content;
  background: transparent;
  &__title {
    width: 75px;
    font-family: $fontSecondary;
    font-size: 12px;
    font-weight: 700;
    color: $mainBlack;
    -webkit-text-stroke: 1px #fff;
    paint-order: stroke fill;
  }
}
:deep .cluster {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(87, 152, 161, 0.2);

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: $visited;
    font-family: $fontSecondary;
    font-size: 12px;
    font-weight: 700;
    color: #fcfcfd;
    border-radius: 50%;
  }
}

:deep .no-hint {
  display: none;
}
</style>
