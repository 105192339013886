<template>
  <section class="modal" @click="close">
    <div class="modal__content" @click.stop>
      <div class="modal__content-close" @click="close">
        <img src="@/assets/images/modal-close.svg" alt="close" />
      </div>
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: "ModalWrapper",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(33, 40, 50, 0.22);
  z-index: 20;

  &__content {
    position: relative;
    padding: 40px;
    background: #fff;
    border-radius: 16px;
    @media (max-width: 450px) {
      padding: 24px 16px 16px 16px;
    }

    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      @media (max-width: 500px) {
        display: none;
      }
    }
  }
}
</style>
