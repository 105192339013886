<template>
  <section class="banner">
    <img src="@/assets/images/country.png" alt="bg" class="banner__picture" />

    <div class="banner__content">
      <div class="banner__content-money">
        <span class="money__text">На счету</span>
        <div class="money__number">{{ userBalance }}</div>
      </div>

      <AddMoneyIcon
        class="banner__content-icon"
        @icon-clicked="isPaymentModalOpen = true"
      />
    </div>

    <PaymentModal
      v-if="isPaymentModalOpen"
      @close="isPaymentModalOpen = false"
    />
  </section>
</template>

<script>
import AddMoneyIcon from "@/assets/icons/svg/AddMoneyIcon";
import PaymentModal from "@/components/cabinet/paymentModal/PaymentModal.vue";
import { formattedNumberRu } from "@/core";

export default {
  name: "CabinetWallet",
  components: {
    AddMoneyIcon,
    PaymentModal,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isPaymentModalOpen: false,
    };
  },
  computed: {
    userBalance() {
      if (!this.user) return null;
      return `${formattedNumberRu(this.user.balance_cop / 100)} ₽`;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  display: flex;
  align-items: center;
  height: 88px;
  padding: 14px 24px;
  background: linear-gradient(12.52deg, #1a9fad -16.1%, #2f93a2 110.1%);
  border-radius: 16px;
  overflow: hidden;

  &__picture {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #fff;
    &-money {
      .money__text {
        font-weight: 700;
        font-size: 12px;
      }
      .money__number {
        font-weight: 400;
        font-size: 32px;
      }
    }
    &-icon {
      z-index: 2;
    }
  }
}
</style>
