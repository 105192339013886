<template>
  <section class="banner">
    <template v-if="!isMobile">
      <div class="banner__left mod-one" />
      <div class="banner__left mod-two" />
      <div class="banner__left mod-three" />
    </template>

    <div class="banner__right mod-one" />
    <div class="banner__right mod-two" />
    <div class="banner__right mod-three" />

    <div class="banner__info">
      <div class="font2-14-18 title">Баланс</div>
      <div :class="['value', { few: !enoughMoney }]">{{ balance }}</div>
    </div>

    <MainButton
      class="button"
      title="Пополнить"
      @button-clicked="$emit('payment')"
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_PROFILE } from "@/store/actions";
import { formattedNumberRu } from "@/core";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";

export default {
  name: "UserBalance",
  components: { MainButton },
  props: {
    perTimeslot: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      profile: GET_PROFILE,
    }),
    balance() {
      return `${formattedNumberRu(this.profile.balance_cop / 100)} ₽`;
    },
    enoughMoney() {
      return this.profile.balance_cop / 100 >= this.perTimeslot;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 24px;
  background: $greyBanner;
  border-radius: 20px;
  overflow: hidden;
  @media (max-width: 1279px) {
    height: 72px;
  }
  &__left {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    &.mod-one {
      height: 170%;
      width: 21.4vw;
      border-radius: 0 0 200px 0;
    }
    &.mod-two {
      height: 110%;
      width: 16.7vw;
      border-radius: 0 0 130px 0;
    }
    &.mod-three {
      height: 40%;
      width: 11.9vw;
      border-radius: 0 0 60px 0;
    }
  }
  &__right {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    @media (max-width: 1279px) {
      top: 0;
      bottom: unset;
      border-top: none;
      border-bottom: 1px solid #fff;
    }
    &.mod-one {
      height: 170%;
      width: 21.4vw;
      border-radius: 160px 0 0 0;
      @media (max-width: 1279px) {
        width: 228px;
        height: 106px;
        border-radius: 0 0 0 100px;
      }
    }
    &.mod-two {
      height: 110%;
      width: 16.7vw;
      border-radius: 110px 0 0 0;
      @media (max-width: 1279px) {
        width: 194px;
        height: 72px;
        border-radius: 0 0 0 60px;
      }
    }
    &.mod-three {
      height: 50%;
      width: 11.9vw;
      border-radius: 60px 0 0 0;
      @media (max-width: 1279px) {
        width: 160px;
        height: 37px;
        border-radius: 0 0 0 30px;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    .title {
      position: relative;
      width: fit-content;
      font-weight: 500;
      color: $textSecondary;
      background: $greyBanner;
    }
    .value {
      position: relative;
      width: fit-content;
      font-family: $fontPrimary;
      font-size: 28px;
      font-weight: 500;
      line-height: 34px;
      color: $mainBlack;
      background: $greyBanner;
      @media (max-width: 1279px) {
        font-size: 22px;
        line-height: 30px;
      }
      &.few {
        color: $errorHover;
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    max-width: 112px;
    height: 36px;
    padding: 0;
  }
}
</style>
