<template>
  <section class="header">
    <div
      v-for="item in tabItems"
      :key="item"
      :class="[
        'font2-14-18 header__item',
        { activeClass: activeItem === item },
      ]"
      @click="activeItem = item"
    >
      {{ item }}
    </div>
  </section>
</template>

<script>
export default {
  name: "UnpaidHeader",
  props: {
    tabItems: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: "",
    },
  },
  computed: {
    activeItem: {
      get() {
        return this.active;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 64px;
  padding: 0 32px;
  background: #fff;
  border-bottom: 1px solid $backgroundGray;
  z-index: 1;
  @media (max-width: 1279px) {
    top: 48px;
    height: 48px;
    padding: 0;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 188px;
    height: 38px;
    font-weight: 700;
    color: $greyText;
    border-bottom: 1px solid $greyBanner;
    cursor: pointer;
    @media (max-width: 1279px) {
      width: 50%;
    }

    &.activeClass {
      color: $mainBlack;
      border-bottom: 2px solid $special;
    }
    @media (min-width: 1280px) {
      &:hover {
        color: $mainBlack;
        border-bottom: 2px solid $special;
      }
    }
  }
}
</style>
