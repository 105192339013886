<template><component :is="currentView" /></template>

<script>
import DesktopCabinetView from "@/views/CabinetView/DesktopCabinetView";
import MobileCabinetView from "@/views/CabinetView/MobileCabinetView";

export default {
  name: "CabinetView",
  components: {},
  computed: {
    currentView() {
      return this.isMobile ? MobileCabinetView : DesktopCabinetView;
    },
  },
};
</script>
