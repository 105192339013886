<template>
  <section class="banner">
    <div class="banner__roadLine">
      <div class="banner__roadLine-one"></div>
      <div class="banner__roadLine-two"></div>
      <div class="banner__roadLine-tree"></div>
    </div>

    <div class="banner__content">
      <h2 class="font1-32-40 banner__content-title">{{ content.title }}</h2>

      <span class="font2-16-20 banner__content-text">
        {{ content.description }}
      </span>
      <div class="banner__content-buttons">
        <MainButton
          v-if="!isMobile"
          styles="banner"
          :title="content.button"
          @button-clicked="goToWork"
        />
        <WorkBannerButtonMobile v-else @icon-clicked="goToWork" />
      </div>
    </div>

    <img :src="imageUrl(content.picture)" alt="icon" class="banner__truck" />
  </section>
</template>

<script>
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import WorkBannerButtonMobile from "@/assets/icons/svg/WorkBannerButtonMobile";

export default {
  name: "WorkBanner",
  components: {
    MainButton,
    WorkBannerButtonMobile,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    imageUrl(icon) {
      // eslint-disable-next-line no-undef
      return require(`@/assets/images/${icon}`);
    },
    goToWork() {
      window.open(this.content.link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  display: block;
  height: 240px;
  margin-bottom: 24px;
  background: $mainBlack;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  padding: 50px 40px;
  &__roadLine {
    &-one,
    &-two,
    &-tree {
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 1px solid $special;
      border-right: 1px solid $special;
    }
    &-one {
      height: 100%;
      width: clamp(340px, 50%, 500px);
      border-radius: 0 0 160px 0;
    }
    &-two {
      height: 75%;
      width: clamp(270px, 40%, 400px);
      border-radius: 0 0 110px 0;
    }
    &-tree {
      height: 50%;
      width: clamp(200px, 30%, 300px);
      border-radius: 0 0 60px 0;
    }
  }
  &__content {
    position: relative;
    color: #fff;
    max-width: 217px;
    &-title {
      position: relative;
      font-weight: 500;
      background: $mainBlack;
      margin-bottom: 8px;
    }
    &-text {
      position: relative;
      background: $mainBlack;
    }
    &-buttons {
      margin-top: 25px;
    }
  }
  &__truck {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
@media (max-width: 610px) {
  .banner__truck {
    right: -20px;
  }
}
@media (max-width: 600px) {
  .banner__truck {
    right: -40px;
  }
}
@media (max-width: 590px) {
  .banner__truck {
    right: -60px;
  }
}
@media (max-width: 560px) {
  .banner__truck {
    right: -80px;
  }
}
@media (max-width: 540px) {
  .banner {
    height: 220px;
    padding: 30px 20px;
    &__roadLine {
      &-one,
      &-two,
      &-tree {
        left: -40px;
      }
    }
    &__content-title {
      font-size: 28px;
    }
    &__content-text {
      font-size: 14px;
      color: $greyBanner;
    }
  }
}
@media (max-width: 500px) {
  .banner__content-title {
    font-size: 24px;
    margin-bottom: 6px;
  }
  .banner__truck {
    right: -100px;
  }
}
@media (max-width: 460px) {
  .banner__truck {
    right: -120px;
  }
}
@media (max-width: 440px) {
  .banner__truck {
    right: -140px;
  }
  .banner__roadLine {
    &-one,
    &-two,
    &-tree {
      left: -80px;
    }
  }
}
@media (max-width: 440px) {
  .banner__truck {
    right: -160px;
  }
  .banner__roadLine {
    &-one,
    &-two,
    &-tree {
      left: -100px;
    }
  }
}
@media (max-width: 400px) {
  .banner__truck {
    right: -170px;
  }
  .banner__roadLine {
    &-one,
    &-two,
    &-tree {
      left: -120px;
    }
  }
}
</style>
