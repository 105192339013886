export const GET_IS_AUTH = "[auth] GET_IS_AUTH";
export const SET_USER_DATA = "[auth] SET_USER_DATA";

export const GET_PROFILE = "[profile] GET_PROFILE";
export const GET_UPDATE_OPERATIONS = "[profile] GET_UPDATE_OPERATIONS";
export const FETCH_PROFILE = "[profile] FETCH_PROFILE";
export const SET_PROFILE = "[profile] SET_PROFILE";
export const SET_UPDATE_OPERATIONS = "[profile] SET_UPDATE_OPERATIONS";

export const GET_TERMINAL_CITIES = "[timeslots] GET_TERMINAL_CITIES";
export const FETCH_TERMINAL_CITIES = "[timeslots] FETCH_TERMINAL_CITIES";
export const SET_TERMINAL_CITIES = "[timeslots] SET_TERMINAL_CITIES";

export const GET_FULL_TERMINAL_LIST = "[terminal] GET_FULL_TERMINAL_LIST";
export const FETCH_FULL_TERMINAL_LIST = "[terminal] FETCH_FULL_TERMINAL_LIST";
export const SET_FULL_TERMINAL_LIST = "[terminal] SET_FULL_TERMINAL_LIST";

export const GET_LOADING = "[timeslots] GET_LOADING";
export const SET_LOADING = "[timeslots] SET_LOADING";

export const GET_IS_MOBILE = "[screenSize] GET_IS_MOBILE";
export const SET_IS_MOBILE = "[screenSize] SET_IS_MOBILE";
