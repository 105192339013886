<template>
  <section class="operations" v-loading="isLoading">
    <template v-if="list.length">
      <div class="font1-20-24 operations__title">Операции по счету</div>
      <div class="font2-12-16 operations__subtitle">
        Дата и время операций по Московскому времени
      </div>
      <div class="font1-14-16 operations__oferta">
        <a
          href="/public-oferta"
          class="operations__oferta-link"
          target="_blank"
        >
          Ознакомиться с Офертой
        </a>
      </div>
      <ul
        v-infinite-scroll="loadNextPage"
        :infinite-scroll-disabled="isLoadDisabled"
        infinite-scroll-distance="50"
      >
        <li
          v-for="day in operationsFormattedArray"
          :key="day.date"
          class="operations__date"
        >
          <div class="font2-12-16 operations__date-title">
            {{ day.formattedDate }}
          </div>
          <OperationsItem
            v-for="(operation, index) in day.operations"
            :key="index"
            :operation="operation"
            class="operations__date-item"
          />
        </li>
      </ul>
      <div v-if="isLoading" v-loading="isLoading" class="operations__loading" />
    </template>

    <div v-if="!list.length && !isLoading" class="operations__empty">
      <div class="operations__empty-icon">
        <img src="../../../assets/images/card-big.svg" alt="empty" />
      </div>
      <div class="font2-16-20 operations__empty-text">
        История операций по счету пуста
      </div>
    </div>
  </section>
</template>

<script>
import { dateForAccountOperations } from "@/core";
import OperationsItem from "./components/OperationsItem.vue";
import { mapGetters, mapMutations } from "vuex";
import { GET_UPDATE_OPERATIONS, SET_UPDATE_OPERATIONS } from "@/store/actions";

import Api from "@/api";
export default {
  name: "CabinetOperations",
  components: { OperationsItem },
  data() {
    return {
      list: [],
      currentPage: 1,
      perPage: 20,
      pageCount: 0,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      forceUpdate: GET_UPDATE_OPERATIONS,
    }),
    // массив операций, переформатированный и отсртированный по датам.
    // формат элемента: date - дата операций, operations - [ операции за эту дату ]
    operationsFormattedArray() {
      if (this.list.length) {
        const arr = this.list;
        let dateSortedArray = [
          {
            date: arr[0].date,
            formattedDate: dateForAccountOperations(arr[0].date),
            operations: [arr[0]],
          },
        ];
        let datesCounter = 0;
        for (let i = 1; i < arr.length; i++) {
          if (arr[i].date === dateSortedArray[datesCounter].date) {
            dateSortedArray[datesCounter].operations.push(arr[i]);
          } else {
            dateSortedArray[++datesCounter] = {
              date: arr[i].date,
              formattedDate: dateForAccountOperations(arr[i].date),
              operations: [arr[i]],
            };
          }
        }
        return dateSortedArray;
      }
      return [];
    },
    isLoadDisabled() {
      return this.isLoading || this.currentPage >= this.pageCount;
    },
  },
  watch: {
    async forceUpdate(value) {
      if (value) {
        this.list = [];
        this.currentPage = 1;
        await this.getOperationsList();
        this.clearForceUpdate(false);
      }
    },
  },
  async mounted() {
    await this.getOperationsList();
  },
  methods: {
    ...mapMutations({
      clearForceUpdate: SET_UPDATE_OPERATIONS,
    }),
    async getOperationsList() {
      this.isLoading = true;
      try {
        const response = await Api.profile.accountOperations({
          page: this.currentPage,
          "per-page": this.perPage,
        });
        this.list = this.list.concat(response.data);
        this.pageCount = Number(response.headers["x-pagination-page-count"]);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async loadNextPage() {
      this.currentPage++;
      await this.getOperationsList();
    },
  },
};
</script>

<style lang="scss" scoped>
.operations {
  max-height: calc(100vh - 260px);
  overflow: auto;
  padding: 24px;
  background: #fff;
  border-radius: 16px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: $backgroundGray;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e0e5e6;
    border-radius: 4px;
  }
  @media (max-width: 1279px) {
    max-height: 100vh;
    padding: 32px 0 100px;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    margin-bottom: 4px;
    font-weight: 500;
    color: $mainBlack;
    @media (max-width: 650px) {
      font-weight: 400;
    }
  }
  &__subtitle {
    margin-bottom: 12px;
    font-weight: 700;
    color: $greyText;
  }

  &__oferta {
    margin-bottom: 26px;
    font-weight: 500;
    &-link {
      color: $special;
    }
  }

  &__loading {
    height: 50px;
  }

  &__date {
    margin-bottom: 24px;

    &-title {
      margin-top: 24px;
      font-weight: 700;
      color: $greyText;
    }
    &-item {
      margin-top: 16px;
    }
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 392px);
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 66px;
      margin-bottom: 16px;
      background: $bckgrnd;
      border-radius: 50%;
    }
    &-text {
      color: $greyText;
    }
  }
}
</style>
