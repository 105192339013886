<template>
  <ModalWrapper @close="$emit('close')">
    <div class="content two-button-modal">
      <div class="font1-24-30 content__title">{{ title }}</div>

      <div v-if="description.length" class="font2-16-20 content__description">
        {{ description }}
      </div>

      <slot />

      <div class="content__buttons font1-14-16">
        <CancelButton
          :title="leftButton"
          class="content__buttons-item"
          @button-clicked="$emit('close')"
        />

        <Component
          :is="currentButtonComponent"
          :title="rightButton"
          :class="['content__buttons-item', { disabled: disabled }]"
          @button-clicked="handleSubmit"
        />
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
// универсальный компонент модалки с двумя кнопками
//! Принимает пропсами:
// title - заголовок кнопки
// description - строка уточнения/описания действия
// leftButton - надпись на левой кнопке отмены
// rightButton - надпись на правой кнопке подтверждения
// success - флаг формата отображения кнопки подтверждения:
//   при true будет зеленая
//   при false будет красная
// disabled - в случае true кпопка подтверждения затенена и недоступна
// после description есть слот, при необходимости можно вставить нужный контент
//
//! эмитит два события:
// close - при нажатии на кнопку отмены или закрытии модалки другим способом
// accept - при нажатии на кнопку подтверждения
import ModalWrapper from "@/components/UI/ModalWrapper";
import MainButton from "@/components/UI/controls/buttons/MainButton";
import AlarmButton from "@/components/UI/controls/buttons/AlarmButton.vue";
import CancelButton from "@/components/UI/controls/buttons/CancelButton.vue";
export default {
  name: "TwoButtonsModal",
  components: {
    AlarmButton,
    ModalWrapper,
    MainButton,
    CancelButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    leftButton: {
      type: String,
      default: "",
    },
    rightButton: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentButtonComponent() {
      return this.success ? "MainButton" : "AlarmButton";
    },
  },
  methods: {
    handleSubmit() {
      if (!this.disabled) {
        this.$emit("accept");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 368px;
  @media (max-width: 500px) {
    width: 304px;
  }
  &__title {
    margin-bottom: 14px;
    font-weight: 500;
    color: $mainBlack;
  }
  &__description {
    margin-bottom: 26px;
    font-weight: 500;
    color: $greyText;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 26px;

    &-item.el-button.is-plain,
    &-item.button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 47%;
      height: 44px;
      border-radius: 40px;
      font-size: 16px;
      cursor: pointer;
      transition: all 0.3s ease;
      &.mod-cancel {
        border: 1px solid $greyBanner;
        color: $greyText;
        &:hover {
          background: $greyBanner;
          color: #fff;
        }
        @media (max-width: 1279px) {
          border: 1px solid $special;
          color: $special;
          &:hover {
            background: #fff;
            color: $special;
          }
        }
      }
    }
    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
@media (max-width: 450px) {
  .content {
    width: 305px;
    &__title {
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 6px;
    }
    &__description {
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 18px;
    }
    &__buttons {
      margin-top: 0;
    }
  }
}
</style>
