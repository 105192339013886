<template>
  <el-form
    ref="commentForm"
    :model="commentForm"
    :rules="contactRules"
    :validate-on-rule-change="false"
    class="form"
    v-loading="loading"
  >
    <div class="form__person">
      <div class="form__person-item">
        <el-form-item prop="name" ref="commentForm.name">
          <MainInput
            v-model="commentForm.name"
            title="Ваше имя"
            @input="setFormField('commentForm.name', $event)"
            @clearValidation="resetField('commentForm.name')"
          />
          <template v-slot:error="{ error }">
            <div class="customErrors">
              <ValidationErrorIcon />
              <div class="customErrors__text">{{ error }}</div>
            </div>
          </template>
        </el-form-item>
      </div>

      <div class="form__person-item">
        <el-form-item prop="phone" ref="commentForm.phone">
          <PhoneInput
            title="Номер телефона"
            v-model="commentForm.phone"
            v-mask="'+7 (###) ###-##-##'"
            @input="setFormField('commentForm.phone', $event)"
            @clearValidation="resetField('commentForm.phone')"
          />
          <template v-slot:error="{ error }">
            <div class="customErrors">
              <ValidationErrorIcon />
              <div class="customErrors__text">{{ error }}</div>
            </div>
          </template>
        </el-form-item>
      </div>
    </div>

    <el-form-item ref="commentForm.message" prop="message" class="form__text">
      <MainTextArea
        v-model="commentForm.message"
        title="Комментарий"
        :rows="4"
        length="300"
        @editing="resetField('commentForm.message')"
      />
      <template v-slot:error="{ error }">
        <div class="customErrors mod-textarea">
          <ValidationErrorIcon />
          <div class="customErrors__text">{{ error }}</div>
        </div>
      </template>
    </el-form-item>

    <MainButton
      class="form__button"
      @button-clicked="validateMessage('commentForm')"
    >
      <span>Отправить</span>
    </MainButton>
  </el-form>
</template>

<script>
import MainInput from "@/components/UI/controls/form/MainInput";
import MainTextArea from "@/components/UI/controls/form/MainTextArea";
import MainButton from "@/components/UI/controls/buttons/MainButton";
import ValidationErrorIcon from "@/assets/icons/svg/ValidationErrorIcon";
import PhoneInput from "@/components/UI/controls/form/PhoneInput.vue";

import { contactRules } from "@/constants/rules/contactsRules";
import { GET_PROFILE } from "@/store/actions";
import { mapGetters } from "vuex";
import { parsedPhone } from "@/core";
import Api from "@/api";

export default {
  name: "ContactsForm",
  components: {
    PhoneInput,
    MainInput,
    MainTextArea,
    MainButton,
    ValidationErrorIcon,
  },
  data() {
    return {
      commentForm: {
        phone: "",
        name: "",
        message: "",
      },
      contactRules,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: GET_PROFILE,
    }),
  },
  created() {
    if (Object.keys(this.currentUser).length) {
      this.commentForm.name = this.currentUser.first_name || "";
      this.commentForm.phone = parsedPhone(
        this.currentUser.terms_acceptance.phone_number
      );
    }
  },
  methods: {
    setFormField(field, value) {
      this[field] = value;
    },
    // сброс валидации при начале редактирования элемента
    resetField(fieldName) {
      this.$refs[fieldName].clearValidate();
    },
    validateMessage(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendRequest();
        }
      });
    },
    async sendRequest() {
      this.loading = true;
      try {
        await Api.contacts.sendForm(this.commentForm);
        this.commentForm.message = "";
        this.$emit("success");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  flex: 1;
  position: relative;
  &__person {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      width: 48.7%;
    }
  }
  &__text {
    margin-top: 22px;
    margin-bottom: 32px;
    &.is-error {
      :deep .el-form-item__error {
        padding-top: 15px;
      }

      :deep .input {
        border-radius: 12px;
      }
    }
  }
  &__button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 38%;
  }
}
@media (max-width: 1279px) {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &__person {
      width: 100%;
    }
    &__text {
      width: 100%;
      margin-bottom: 32px;
    }
    &__button {
      max-width: 500px;
    }
  }
}
@media (max-width: 600px) {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__person {
      width: 100%;
      flex-direction: column;
      gap: 16px;
      &-item {
        width: 100%;
      }
    }
    &__button {
      width: 100%;
    }
  }
}
</style>
