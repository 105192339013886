<template>
  <section class="login">
    <h1 class="login__title font1-40-48">
      Добро пожаловать <br />
      в «Зерновозы»
    </h1>

    <span class="login__text font2-14-18">
      Бронирование и оплата таймслотов в электронной очереди Порт транзит
    </span>

    <div class="phoneForm">
      <span class="phoneForm__title font2-14-18">Вход или регистрация</span>

      <PhoneInput
        title="Введите номер телефона"
        v-model="phone"
        v-mask="'+7 (###) ###-##-##'"
        @input="setPhone"
      />

      <MainButton
        title="Продолжить"
        :disabled="!canLogin"
        class="login__button"
        @button-clicked="checkTerms"
      />
    </div>
  </section>
</template>

<script>
import loginMixin from "@/views/AuthViews/loginView/loginMixin";

export default {
  name: "DesktopLoginView",
  mixins: [loginMixin],
};
</script>

<style lang="scss" scoped>
.grid-content {
  display: inline-block;
}
.login {
  width: 450px;
  &__title {
    width: fit-content;
    color: #fff;
    background-color: $mainBlack;
    position: relative;
    line-height: 48px;
    margin-bottom: 16px;
  }
  &__text {
    position: relative;
    font-weight: 500;
    color: $bckgrnd;
    background-color: $mainBlack;
  }
  .phoneForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
    &__title {
      position: relative;
      width: fit-content;
      color: $greyText;
      background-color: $mainBlack;
    }
    &__privacyPolicy {
      background-color: $mainBlack;
      position: relative;
      &-content {
        display: inline-flex;
        align-items: center;
        color: $greyText;
        .privacyPolicy-link {
          color: $greyText;
          border-bottom: 1px solid $greyText;
          margin-left: 5px;
        }
      }
    }
  }
  &__button.el-button--primary.is-plain {
    width: 100%;
    height: 48px;
    font-size: 16px;
  }
}
</style>
