import { Request } from "@/core";
import { home, timeslots } from "@/constants/urls";

export default {
  getMessage() {
    return Request.get(home.getMessage);
  },

  getTimeslots() {
    return Request.get(timeslots.getTimeslots);
  },
};
