<template>
  <section class="steps">
    <div
      v-for="(step, index) in steps"
      :key="index"
      :class="[
        'steps__items',
        { currentPage: step.number === currentStep },
        { preveosPage: step.number < currentStep },
      ]"
    >
      <div class="steps__items-number">
        <span v-if="step.number >= currentStep">{{ step.number }}</span>
        <img
          v-if="step.number < currentStep"
          src="@/assets/images/CheckIcon.svg"
          alt="CheckIcon"
        />
      </div>
      <div class="steps__items-text">{{ step.text }}</div>
    </div>
  </section>
</template>

<script>
import { steps } from "@/constants/timeslots-steps";
export default {
  name: "TimeslotsSteps",
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      steps,
    };
  },
};
</script>

<style lang="scss" scoped>
.steps {
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  max-height: 220px;
  &__items {
    background-color: $bckgrnd;
    border-radius: 14px;
    padding: 12px 16px;
    margin-bottom: 8px;
    font-size: 16px;
    color: $greyText;
    display: flex;
    align-items: center;
    column-gap: 12px;
    &.preveosPage {
      .steps__items-number {
        color: $special;
        border-color: $special;
      }
    }
    &.currentPage {
      background: $backgroundBlue;
      .steps__items-number {
        color: $special;
        border-color: $special;
      }
    }
    &-number {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid $backgroundGray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-items:last-child {
    margin-bottom: 0;
  }
}
</style>
