<template>
  <section class="radio">
    <div
      v-for="item in variants"
      :key="item.value"
      :class="['radio__item', { active: isActive(item), disabled: disabled }]"
      @click="changeValue(item.value)"
    >
      <svg class="radio__item-icon">
        <use :xlink:href="`#${item.icon}`" />
      </svg>

      <div
        :class="['font2-14-18 radio__item-title', { active: isActive(item) }]"
      >
        {{ item.title }}
      </div>
      <div :class="['radio__item-button', { active: isActive(item) }]" />
    </div>
    <PaymentSvgSprite />
  </section>
</template>

<script>
import PaymentSvgSprite from "@/assets/icons/svg/PaymentSvgSprite.vue";
export default {
  name: "PaymentRadio",
  components: { PaymentSvgSprite },
  props: {
    variants: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    radioValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
  methods: {
    isActive(item) {
      return item.value === this.radioValue;
    },
    changeValue(value) {
      if (!this.disabled) {
        this.radioValue = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 52px;
    padding-left: 14px;
    padding-right: 55px;
    border: 1px solid $greyBanner;
    border-radius: 8px;
    cursor: pointer;
    &.active,
    &:hover {
      border: 1px solid $special;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        border: 1px solid $greyBanner;
      }
    }

    &-icon {
      width: 24px;
      height: 24px;
    }

    &-title {
      font-weight: 500;
      &.active {
        font-weight: 700;
      }
    }

    &-button {
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border: 2px solid $greyBanner;
      border-radius: 50%;
      transition: border 0.3s ease;
      &.active {
        border: 6px solid $special;
      }
    }
  }
}
</style>
