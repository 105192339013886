var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inn"},[_c('div',{staticClass:"inn__input",on:{"click":function($event){return _vm.$refs.carrier.focus()}}},[_c('div',{class:[
        'auto-prompt',
        { filled: _vm.carrierInn.length || _vm.isCarrierFocused },
      ]},[_vm._v(" ИНН поставщика/перевозчика ")]),_c('el-autocomplete',{directives:[{name:"mask",rawName:"v-mask",value:('############'),expression:"'############'"}],ref:"carrier",class:[
        'auto-input',
        { filled: _vm.carrierInn.length || _vm.isCarrierFocused },
      ],attrs:{"placement":"top-start","fetch-suggestions":_vm.querySearchInn},on:{"focus":function($event){_vm.isCarrierFocused = true},"blur":function($event){_vm.isCarrierFocused = false},"input":_vm.checkInnLength,"select":_vm.setCarrier},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.label)+" ")]),_c('b',[_vm._v(_vm._s(item.value))])]}}]),model:{value:(_vm.carrierInn),callback:function ($$v) {_vm.carrierInn=$$v},expression:"carrierInn"}})],1),_c('MainButton',{staticClass:"inn__button",attrs:{"title":_vm.isCarrierVerified ? 'Изменить' : 'Проверить',"disabled":_vm.isCarrierButtonDisabled},on:{"button-clicked":_vm.checkInn}}),(_vm.carrierName && _vm.isCarrierVerified)?_c('div',{staticClass:"font2-12-16 inn__name"},[_vm._v(" Поставщик: "+_vm._s(_vm.carrierName)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }