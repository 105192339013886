export const nrzBanner = {
  title: "Найти работу",
  description: "Работа зерновозам и зерновозы для работы",
  button: "Подробнее",
  picture: "MainTruck.png",
  link: "http://zernovozam.com/",
};

export const expBanner = {
  description: "Работай с проверенным экспедитором ",
  link: "https://exp.port-tranzit.ru/",
  button: "Подробнее",
};
