<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.00021 2.71682C1.00628 1.70677 1.85109 1 2.76483 1H4.22076C5.04903 1 5.77221 1.56296 5.97843 2.36826L6.40218 4.02307C6.5878 4.74793 6.31293 5.51301 5.70915 5.95206L3.58649 7.49563C4.49314 9.44803 6.09324 11.3045 8.77499 12.4228L10.3896 10.4123C10.8441 9.84628 11.5904 9.60577 12.2883 9.80042L13.671 10.1861C14.4565 10.4052 15 11.1231 15 11.9415V13.2021C15 14.2169 14.1566 15.0794 13.0946 14.9942C11.458 14.8629 10.0194 14.5492 8.75902 14.0912C5.02813 12.7355 2.94635 10.1494 1.90303 7.51908C1.23438 5.83337 0.991715 4.1317 1.00021 2.71682ZM10.3455 12.9553C11.206 13.1865 12.1605 13.3527 13.2186 13.4376C13.3216 13.4458 13.4444 13.3606 13.4444 13.2021V11.9415C13.4444 11.8246 13.3668 11.722 13.2546 11.6907L11.8718 11.305C11.7721 11.2772 11.6655 11.3115 11.6006 11.3924L10.3455 12.9553ZM3.01813 5.9807L4.7966 4.68742C4.88286 4.6247 4.92212 4.5154 4.89561 4.41185L4.47185 2.75704C4.4424 2.642 4.33908 2.56157 4.22076 2.56157H2.76483C2.69541 2.56157 2.6391 2.58792 2.60335 2.6214C2.57034 2.65232 2.55598 2.68662 2.55574 2.72623C2.54991 3.6966 2.67996 4.82666 3.01813 5.9807Z"
      fill="#3D4A5D"
    />
    <path
      d="M13.2186 13.4376C12.1605 13.3527 11.206 13.1865 10.3455 12.9553L11.6006 11.3924C11.6655 11.3115 11.7721 11.2772 11.8718 11.305L13.2546 11.6907C13.3668 11.722 13.4444 11.8246 13.4444 11.9415V13.2021C13.4444 13.3606 13.3216 13.4458 13.2186 13.4376Z"
      fill="#3D4A5D"
    />
    <path
      d="M4.7966 4.68742L3.01813 5.9807C2.67996 4.82666 2.54991 3.6966 2.55574 2.72623C2.55598 2.68662 2.57034 2.65232 2.60335 2.6214C2.6391 2.58792 2.69541 2.56157 2.76483 2.56157H4.22076C4.33908 2.56157 4.4424 2.642 4.47185 2.75704L4.89561 4.41185C4.92212 4.5154 4.88286 4.6247 4.7966 4.68742Z"
      fill="#3D4A5D"
    />
  </svg>
</template>

<script>
export default {
  name: "PhoneIcon",
};
</script>

<style scoped></style>
