<template>
  <section class="confirmCard">
    <div class="confirmCard__header">
      <div>
        <div class="font2-18-24 confirmCard__header-title">
          Авто №{{ index }}
        </div>
        <RequestDateTime :timeslot="timeslot" styles="gray" />
      </div>

      <FinalStatus v-if="timeslot.isConfirmed" />

      <img
        v-else-if="!timeslot.isDeleted"
        src="@/assets/images/delete.svg"
        class="confirmCard__header-button"
        alt="close"
        @click="isDeleteModalOpen = true"
      />
    </div>

    <div v-if="timeslot.isDeleted" class="truck-button mod-deleted">
      <div class="font2-16-20 truck-button__prompt">Запрос отменён</div>
    </div>

    <template v-else>
      <!--    для терминалов не Новоросса выводим типа кнопку для открытия модалки с формой -->
      <template v-if="hasModalTruckForm">
        <div
          :class="[
            'truck-button',
            { 'mod-error': timeslot.hasApiError },
            { 'mod-success': timeslot.isConfirmed },
          ]"
          @click="openTruckModal"
        >
          <div>
            <div
              :class="[
                'font2-16-20 truck-button__prompt',
                { active: timeslot.plate_truck },
              ]"
            >
              {{ timeslot.plate_truck ? "Номер авто" : "Данные авто" }}
            </div>
            <div
              v-if="timeslot.plate_truck"
              class="font2-16-20 truck-button__value"
            >
              {{ timeslot.plate_truck }}
            </div>
          </div>
          <img src="@/assets/images/angle-right-icon.svg" alt="close" />
        </div>
        <div v-if="timeslot.hasApiError" class="font2-12-16 apiError">
          <ValidationErrorIcon class="apiError__icon" />
          <div class="apiError__text">{{ timeslot.apiErrorText }}</div>
        </div>
        <div v-if="timeslot.isConfirmed" class="font2-12-16 apiSuccess">
          {{ successTitle }}
        </div>
      </template>

      <!--    для терминалов Новоросса чисто инпут с номером авто без всяких модалок -->
      <div v-else>
        <el-form
          ref="confirmForm"
          :model="timeslot"
          :rules="rulesFormAddTimeslot"
          :validate-on-rule-change="false"
          class="confirmCard__form"
        >
          <el-form-item prop="plate_truck" ref="plate_truck">
            <MobileAutoComplete
              placeholder="Номер авто"
              :disabled="timeslot.isConfirmed"
              :options="truckOptions"
              :class="[
                { error: timeslot.hasApiError },
                { success: timeslot.isConfirmed },
              ]"
              @input="setTruckField"
              @editing="resetMobileField('plate_truck')"
              @finished="validateField('plate_truck')"
            />

            <div v-if="showError.plate_truck" class="customErrors">
              <ValidationErrorIcon class="customErrors__icon" />
              <div class="customErrors__text">
                {{ errorMessages.plate_trailer }}
              </div>
            </div>
          </el-form-item>

          <div v-if="timeslot.hasApiError" class="font2-12-16 apiError">
            <ValidationErrorIcon class="apiError__icon" />
            <div class="apiError__text">{{ timeslot.apiErrorText }}</div>
          </div>

          <div v-if="timeslot.isConfirmed" class="font2-12-16 apiSuccess">
            {{ successTitle }}
          </div>
        </el-form>
      </div>
    </template>

    <FullscreenMobileModal
      v-show="isModalTruckOpen"
      title="Заполните данные по авто"
      right-button="Добавить"
      :success="true"
      :disabled="!canConfirmTruck"
      @accept="isModalTruckOpen = false"
      @close="dropOnModalClose"
    >
      <el-form
        ref="confirmForm"
        :model="timeslot"
        :validate-on-rule-change="false"
        class="confirmCard__form"
      >
        <el-form-item prop="plate_truck" ref="plate_truck">
          <MobileAutoComplete
            placeholder="Номер авто"
            :disabled="timeslot.isConfirmed"
            :options="truckOptions"
            :force-clear="forceClearModal"
            @input="setTruckField"
            @editing="resetMobileField('plate_truck')"
            @finished="validateField('plate_truck')"
          />

          <div v-if="showError.plate_truck" class="customErrors">
            <ValidationErrorIcon class="customErrors__icon" />
            <div class="customErrors__text">
              {{ errorMessages.plate_trailer }}
            </div>
          </div>
        </el-form-item>

        <template v-if="settings.timeslot_confirm_fields.plate_trailer">
          <el-form-item prop="plate_trailer" ref="plate_trailer">
            <MobileAutoComplete
              placeholder="Номер прицепа"
              :disabled="timeslot.isConfirmed || areInputsDisabled"
              :options="trailerOptions"
              :force-clear="clearTruckFields || forceClearModal"
              @input="setMobileField('plate_trailer', $event)"
              @editing="resetMobileField('plate_trailer')"
              @finished="validateField('plate_trailer')"
            />

            <div v-if="showError.plate_trailer" class="customErrors">
              <ValidationErrorIcon class="customErrors__icon" />
              <div class="customErrors__text">
                {{ errorMessages.plate_trailer }}
              </div>
            </div>
          </el-form-item>

          <el-form-item prop="axis_count" ref="axis_count">
            <CustomSelect
              :model="timeslot.axis_count"
              placeholder="Кол-во осей"
              :disabled="timeslot.isConfirmed || areInputsDisabled"
              :options="axisCount"
              @select="setMobileField('axis_count', $event)"
            />
          </el-form-item>
        </template>

        <template v-if="settings.timeslot_confirm_fields.driver_phone">
          <el-form-item prop="driver_name" ref="driver_name">
            <MobileAutoComplete
              placeholder="ФИО водителя"
              :disabled="timeslot.isConfirmed || areInputsDisabled"
              :options="nameOptions"
              :force-clear="clearTruckFields || forceClearModal"
              @input="setMobileField('driver_name', $event)"
              @editing="resetMobileField('driver_name')"
              @finished="validateField('driver_name')"
            />

            <div v-if="showError.driver_name" class="customErrors">
              <ValidationErrorIcon class="customErrors__icon" />
              <div class="customErrors__text">
                {{ errorMessages.driver_name }}
              </div>
            </div>
          </el-form-item>

          <el-form-item prop="driver_phone" ref="driver_phone">
            <MobileAutoComplete
              v-mask="'+7 (###) ###-##-##'"
              placeholder="Номер телефона водителя"
              :disabled="timeslot.isConfirmed || areInputsDisabled"
              :options="phoneOptions"
              :is-phone="true"
              :force-clear="clearTruckFields || forceClearModal"
              @input="setMobileField('driver_phone', $event)"
              @editing="resetMobileField('driver_phone')"
              @finished="validateField('driver_phone')"
            />

            <div v-if="showError.driver_phone" class="customErrors">
              <ValidationErrorIcon class="customErrors__icon" />
              <div class="customErrors__text">
                {{ errorMessages.driver_phone }}
              </div>
            </div>
          </el-form-item>
        </template>
      </el-form>
    </FullscreenMobileModal>

    <TwoButtonsModal
      v-if="isDeleteModalOpen"
      title="Отказ от таймслота"
      description="Вы действительно хотите отказаться от таймслота?"
      left-button="Нет, не хочу"
      right-button="Отказаться"
      :success="false"
      @close="isDeleteModalOpen = false"
      @accept="deleteTimeslot"
    />
  </section>
</template>

<script>
import FullscreenMobileModal from "@/components/UI/modals/FullscreenMobileModal.vue";
import {
  numAutoValidator,
  numTrailerValidator,
  confirmTimeslotMessages,
} from "@/constants/rules/GetTimeslotRules";
import confirmCardMixin from "./confirmCardMixin";
import MobileAutoComplete from "@/components/UI/controls/form/MobileAutoComplete.vue";

export default {
  name: "ConfirmCardMobile",
  components: { MobileAutoComplete, FullscreenMobileModal },
  mixins: [confirmCardMixin],
  data() {
    return {
      isModalTruckOpen: false,
      forceClearModal: false,
      showError: {
        plate_truck: false,
        plate_trailer: false,
        driver_phone: false,
        driver_name: false,
      },
      errorMessages: confirmTimeslotMessages,
      canConfirmTruck: false,
    };
  },
  computed: {
    // нужна ли модалка для заполнения данных по машине (по факту это - isNotNovoross)
    // на данном этапе у всех, кроме Новоросса, есть телефон водилы, поэтому проверка выглядит так
    hasModalTruckForm() {
      return this.settings.timeslot_confirm_fields.driver_phone;
    },
  },
  methods: {
    openTruckModal() {
      if (!this.timeslot.isConfirmed) {
        this.isModalTruckOpen = true;
        this.$emit("clearError");
      }
    },
    dropOnModalClose() {
      this.setTruckField("");
      this.forceClearModal = true;
      this.canConfirmTruck = false;
      this.$nextTick(() => {
        this.forceClearModal = false;
        this.resetMobileField("plate_truck");
        if (this.settings.timeslot_confirm_fields.driver_phone) {
          this.resetMobileField("driver_name");
          this.resetMobileField("driver_phone");
        }
        if (this.settings.timeslot_confirm_fields.plate_trailer) {
          this.resetMobileField("plate_trailer");
        }
        this.isModalTruckOpen = false;
      });
    },
    checkConfirmTruck() {
      this.canConfirmTruck = false;
      let hasError = false;
      if (
        !this.timeslot.plate_truck ||
        numAutoValidator(this.timeslot.plate_truck)
      ) {
        hasError = true;
      }
      if (this.settings.timeslot_confirm_fields.driver_phone) {
        if (
          !this.timeslot.driver_name ||
          this.timeslot.driver_name.length === 1 ||
          !this.timeslot.driver_phone ||
          this.timeslot.driver_phone.length !== 18
        ) {
          hasError = true;
        }
      }
      if (this.settings.timeslot_confirm_fields.plate_trailer) {
        if (
          !this.timeslot.plate_trailer ||
          numTrailerValidator(this.timeslot.plate_trailer) ||
          !this.timeslot.axis_count
        ) {
          hasError = true;
        }
      }
      this.canConfirmTruck = !hasError;
    },
    validateField(title) {
      switch (title) {
        case "plate_truck":
          if (numAutoValidator(this.timeslot.plate_truck)) {
            this.showError.plate_truck = true;
          }
          break;
        case "plate_trailer":
          if (
            this.timeslot.plate_trailer.length &&
            numTrailerValidator(this.timeslot.plate_trailer)
          ) {
            this.showError.plate_trailer = true;
          }
          break;
        case "driver_name":
          if (this.timeslot.driver_name.length === 1) {
            this.showError.driver_name = true;
          }
          break;
        case "driver_phone":
          if (
            this.timeslot.driver_phone.length &&
            this.timeslot.driver_phone.length !== 18
          ) {
            this.showError.driver_phone = true;
          }
          break;
      }
    },
    setTruckField(value) {
      this.$emit("set", { name: "plate_truck", value });
      if (this.settings.timeslot_confirm_fields.driver_phone) {
        this.$emit("set", { name: "driver_phone", value: "" });
        this.$emit("set", { name: "driver_name", value: "" });
        this.resetMobileField("driver_phone");
        this.resetMobileField("driver_name");
      }
      if (this.settings.timeslot_confirm_fields.plate_trailer) {
        this.$emit("set", { name: "plate_trailer", value: "" });
        this.resetMobileField("plate_trailer");
      }
      this.clearTruckFields = true;
      this.$nextTick(() => {
        this.clearTruckFields = false;
        this.checkConfirmTruck();
      });
    },
    setMobileField(name, value) {
      this.$emit("set", { name, value });
      this.checkConfirmTruck();
    },
    resetMobileField(field) {
      this.showError[field] = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.customErrors {
  position: relative;
  top: 0;
}
.confirmCard {
  padding: 16px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid $backgroundGray;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    &-title {
      font-weight: 700;
      color: $mainBlack;
      margin-bottom: 6px;
    }

    &-button {
      width: 24px;
      height: 24px;
    }
  }

  .truck-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    background: $bckgrnd;
    border-radius: 8px;
    &.mod-deleted,
    &.mod-error {
      border: 1px solid $errorHover;
    }
    &.mod-success {
      border: 1px solid $success;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &__prompt {
      font-weight: 500;
      color: $greyText;

      &.active {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
      }
    }

    &__value {
      font-weight: 500;
      color: $mainBlack;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .el-form-item {
      width: 100%;
    }
    .error ::v-deep .autocomplete {
      border: 1px solid $error;
    }
    .success ::v-deep .autocomplete {
      border: 1px solid $success;
    }
  }

  .apiError {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    font-weight: 500;
    color: $error;
    &__icon {
      flex-shrink: 0;
    }
    &__text {
      margin-top: 1px;
      line-height: 1;
    }
  }
  .apiSuccess {
    font-weight: 500;
    color: $success;
  }
}
</style>
