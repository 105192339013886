import TimeslotsTable from "@/components/timeslots/getTimeslots/TimeslotsTable.vue";
import TwoButtonsModal from "@/components/UI/modals/TwoButtonsModal.vue";
import { formattedNumberRu } from "@/core";
import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  FETCH_TERMINAL_CITIES,
  GET_LOADING,
  GET_PROFILE,
  SET_LOADING,
} from "@/store/actions";

export default {
  components: { TimeslotsTable, TwoButtonsModal },
  data() {
    return {
      formattedNumberRu,
      currentStep: 1,
      isLeaveModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: GET_PROFILE,
      isLoading: GET_LOADING,
    }),
  },
  async mounted() {
    this.setLoading(true);
    await this.fetchCities();
    this.setLoading(false);
  },
  methods: {
    ...mapMutations({
      setLoading: SET_LOADING,
    }),
    ...mapActions({
      fetchCities: FETCH_TERMINAL_CITIES,
    }),
    finish() {
      this.$emit("finish");
    },
    nextStep() {
      this.$emit("change-step", ++this.currentStep);
    },
    previousStep() {
      if (this.currentStep === 3) {
        this.isLeaveModalOpen = true;
      } else {
        this.$emit("change-step", --this.currentStep);
      }
    },
    forceBack() {
      this.isLeaveModalOpen = false;
      this.$emit("change-step", --this.currentStep);
    },
  },
};
