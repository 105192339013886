export const linksList = [
  {
    title: "Оплата ТС",
    icon: "unpaid",
    name: "unpaid",
  },
  {
    title: "Мои машины",
    icon: "trucks",
    name: "trucks",
  },
  {
    title: "Статистика перевозок",
    icon: "statistics",
    name: "statistics",
  },
  {
    title: "Наши контакты",
    icon: "contacts",
    name: "contacts",
  },
];
