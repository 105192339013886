export const steps = [
  {
    number: 1,
    text: "Выбор терминала",
  },
  {
    number: 2,
    text: "Запрос таймслотов",
  },
  {
    number: 3,
    text: "Подтверждение таймслотов",
  },
];
