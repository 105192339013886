import { mapGetters } from "vuex";
import { GET_IS_MOBILE } from "@/store/actions";

export default {
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
    }),
  },
  methods: {
    successInformer(message, offset = 20) {
      this.$message({
        message,
        type: "success",
        showClose: true,
        duration: 10000,
        offset,
      });
    },
    errorInformer(message, offset = 20) {
      this.$message({
        message,
        iconClass: "el-icon-warning",
        customClass: "el-message--error",
        showClose: true,
        duration: 10000,
        offset,
      });
    },
  },
};
