<template>
  <div>
    <div
      ref="autocomplete_wrapper"
      class="autocomplete"
      :class="{ disabled: disabled }"
      @keyup.tab="start"
    >
      <div v-if="isPhone" class="autocomplete__phone">
        <FlagIcon />
      </div>

      <div class="autocomplete__content" @click="start">
        <div
          class="autocomplete__content-prompt"
          :class="[stateClass, { disabled: disabled }]"
        >
          {{ placeholder }}
        </div>
        <el-autocomplete
          v-model="result"
          ref="autocomplete"
          :type="isPhone ? 'tel' : 'text'"
          class="autocomplete__content-select block-input"
          :class="stateClass"
          :fetch-suggestions="querySearch"
          :disabled="disabled"
          @focus="isEditing = true"
          @select="selectValue"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FlagIcon from "@/assets/icons/svg/FlagIcon.vue";

export default {
  name: "AutocompleteInput",
  components: { FlagIcon },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isPhone: {
      type: Boolean,
      default: false,
    },
    forceClear: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      result: "",
      isEditing: false,
    };
  },

  computed: {
    stateClass() {
      return this.isEditing || this.result.length ? "filled" : "";
    },
  },

  watch: {
    forceClear(value) {
      if (value) {
        this.result = "";
      }
    },
  },

  methods: {
    querySearch(queryString, cb) {
      let results = [];
      if (queryString) {
        results = this.options.filter(
          (item) =>
            item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      } else {
        results = this.options;
      }
      cb(results);
    },

    selectValue() {
      this.isEditing = false;
      this.$emit("input", this.result);
    },
    start() {
      this.$refs.autocomplete.focus();
      this.$emit("editing");
    },
    onChange() {
      this.isEditing = false;
      this.$emit("input", this.result);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item.disabled {
  cursor: not-allowed;
}

.autocomplete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 8px 10px 8px 16px;
  border-radius: 12px;
  background: $bckgrnd;
  @supports (-webkit-touch-callout: none) {
    @media (max-width: 767px) {
      height: 64px;
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &__phone {
    margin-right: 8px;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;

    &-select {
      width: 100%;
      height: 0;
      padding: 0;
      border-width: 0;
      outline: none;
      font-size: 0;
      background: $bckgrnd;
      transition: height 0.3s ease;
      color: $mainInputValue;
      position: relative;
      ::v-deep .el-input__inner {
        height: 0;
        padding-left: 1px;
        padding-right: 0;
        border-color: transparent !important;
        background-color: transparent;
        text-overflow: ellipsis;
        position: absolute;
      }
      ::v-deep .el-input.is-focus .el-input__inner {
        border-color: transparent !important;
        background-color: transparent;
      }
      &.filled {
        height: 18px;
        @supports (-webkit-touch-callout: none) {
          @media (max-width: 767px) {
            height: 32px;
          }
        }
        ::v-deep .el-input {
          font-size: 16px;
        }
        ::v-deep .el-input__inner {
          height: 18px;
          @supports (-webkit-touch-callout: none) {
            @media (max-width: 767px) {
              height: 30px;
            }
          }
        }
      }
    }

    &-prompt {
      padding-left: 1px;
      font-size: 14px;
      color: $greyText;
      transition: all 0.3s ease;
      &.disabled {
        opacity: 0.5;
      }
      &.filled {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
</style>
