import { getParamsUrl, Request } from "@/core";
import { profile } from "@/constants/urls";

export default {
  getProfile() {
    return Request.get(profile.getProfile);
  },

  uploadAvatar(data) {
    return Request.post(profile.uploadAvatar, data);
  },

  updateProfile(data) {
    return Request.post(profile.updateProfile, data);
  },

  deleteProfileData() {
    return Request.post(profile.deleteProfileData);
  },

  deleteAccount() {
    return Request.post(profile.deleteAccount);
  },

  getUserTrucks() {
    return Request.get(profile.getUserTrucks);
  },

  checkBillInn(inn) {
    return Request.get(`${profile.checkBillInn}?inn=${inn}`);
  },

  createBillRequest(data) {
    return Request.post(profile.createBillRequest, data);
  },

  downloadBill(id) {
    return Request.get(`${profile.downloadBill}?operation_id=${id}`);
  },

  sendBillEmail(data) {
    return Request.post(profile.sendBillEmail, data);
  },

  makeSbpPayment(data) {
    return Request.post(profile.makeSbpPayment, data);
  },

  makeYooCashPayment(data) {
    return Request.post(profile.makeYooCashPayment, data);
  },

  accountOperations(params) {
    return Request.get(`${profile.accountOperations}${getParamsUrl(params)}`);
  },
};
