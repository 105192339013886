import DatePicker from "@/components/UI/controls/form/DatePicker.vue";
import ArchiveEmpty from "@/components/archiveTimeslots/ArchiveEmpty.vue";
import TimeslotCard from "@/components/timeslots/list/TimeslotCard.vue";
import CustomSelect from "@/components/UI/controls/form/CustomSelect.vue";
import { unixDate } from "@/core";
import Api from "@/api";

export default {
  components: { DatePicker, ArchiveEmpty, TimeslotCard, CustomSelect },
  data() {
    return {
      timeslots: [],
      terminalsOptions: [],
      isLoading: false,
      startDate: "",
      finishDate: "",
      currentTerminalId: "",
      startPickerOptions: {
        disabledDate: (time) => time.getTime() > Date.now(),
      },
      finishPickerOptions: {
        disabledDate: (time) =>
          time.getTime() > Date.now() ||
          time.getTime() < unixDate(this.startDate),
      },
      isAfterQuery: false,
    };
  },
  computed: {
    isButtonEnabled() {
      return this.startDate && this.finishDate && this.currentTerminalId;
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      const { data } = await Api.terminals.getTerminals({ has_timeslots: 1 });
      this.terminalsOptions = data;
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    setStartDate(value) {
      this.startDate = value;
      this.finishDate = "";
    },
    setFinishDate(value) {
      this.finishDate = value;
    },
    setTerminal(id) {
      this.currentTerminalId = id;
    },
    dropFilters() {
      this.finishDate = "";
      this.startDate = "";
      this.currentTerminalId = "";
      this.isAfterQuery = false;
    },
    async sendQuery() {
      if (this.isButtonEnabled) {
        this.isLoading = true;
        const params = {
          terminal_id: this.currentTerminalId,
          date_from: this.startDate,
          date_to: this.finishDate,
        };

        try {
          const { data } = await Api.timeslots.getArchiveTimeslots(params);
          this.timeslots = data;
          this.isAfterQuery = true;
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
