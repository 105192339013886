<template>
  <div class="autocomplete">
    <div
      ref="autocomplete"
      :class="[
        'autocomplete__wrapper',
        { focused: isFocused },
        { disabled: disabled },
      ]"
    >
      <div v-if="isPhone" class="autocomplete__phone">
        <FlagIcon />
      </div>

      <div class="autocomplete__content" @click="start">
        <div :class="['autocomplete__content-prompt', { filled: isLabelTop }]">
          {{ placeholder }}
        </div>

        <input
          ref="input"
          :class="['autocomplete__content-input', { filled: isLabelTop }]"
          :type="isPhone ? 'tel' : 'text'"
          v-model="selectedItem"
          @focus="onFocus"
          @input="onChange"
        />
      </div>
    </div>

    <ul
      ref="options"
      :class="['autocomplete__dropdown', { open: showOptions }]"
    >
      <li
        v-for="(option, index) in matches"
        :key="index"
        class="autocomplete__dropdown-item"
        @click="optionClick(option)"
      >
        {{ option.value }}
      </li>
    </ul>
  </div>
</template>

<script>
import FlagIcon from "@/assets/icons/svg/FlagIcon.vue";

export default {
  name: "MobileAutoComplete",
  components: { FlagIcon },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    forceClear: {
      type: Boolean,
      default: false,
    },
    isPhone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
      current: 0,
      selectedItem: "",
    };
  },

  computed: {
    matches() {
      return this.options.filter((item) => {
        return (
          item.value.toLowerCase().indexOf(this.selectedItem.toLowerCase()) >= 0
        );
      });
    },
    showOptions() {
      return this.matches.length && this.isFocused === true;
    },
    isLabelTop() {
      return this.isFocused || this.selectedItem.length;
    },
  },

  watch: {
    forceClear(value) {
      if (value) {
        this.selectedItem = "";
      }
    },
  },

  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  methods: {
    handleClickOutside(event) {
      const inputElement = this.$refs.autocomplete;
      const dropdownElement = this.$refs.options;
      if (
        !inputElement.contains(event.target) &&
        !dropdownElement.contains(event.target)
      ) {
        this.isFocused = false;
        this.$emit("finished", this.selectedItem);
      }
    },

    start() {
      let input = this.$refs.input;
      let pos = this.selectedItem.length;
      input.focus();
      input.setSelectionRange(pos, pos);
    },

    onFocus() {
      this.isFocused = true;
      this.$emit("editing");
    },

    onChange() {
      this.$emit("input", this.selectedItem);
    },

    optionClick(option) {
      this.selectedItem = option.value;
      this.isFocused = false;
      this.$emit("input", this.selectedItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 50px;
    padding: 8px 10px 8px 16px;
    background: $bckgrnd;
    border-radius: 12px;
    cursor: pointer;
    @supports (-webkit-touch-callout: none) {
      @media (max-width: 767px) {
        height: 62px;
      }
    }

    &.focused {
      border: 1px solid $select;
    }
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-input {
      width: 100%;
      height: 0;
      padding: 0;
      border-width: 0;
      outline: none;
      font-size: 0;
      background: $bckgrnd;
      transition: height 0.3s ease;
      color: $mainInputValue;
      &.filled {
        height: 18px;
        font-size: 16px;
        font-weight: 500;
        @supports (-webkit-touch-callout: none) {
          @media (max-width: 767px) {
            height: 30px;
          }
        }
      }
    }
    &-prompt {
      font-size: 14px;
      color: $greyText;
      transition: all 0.3s ease;
      &.filled {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 2%;
    width: 96%;
    z-index: 1000;
    display: none;
    float: left;
    max-height: 200px;
    overflow: auto;
    padding: 10px 0;
    margin-top: 2px;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;

    &.open {
      display: block;
    }

    &-item {
      height: 35px;
      line-height: 35px;
      padding: 0 10px;
      font-size: 14px;
      color: $mainBlack;
    }
  }
}
</style>
