<template>
  <section v-loading="isLoading" class="mobileContactsView">
    <MobileHeader
      title="Контакты"
      :has-chevron="true"
      @back="$router.push({ name: 'moreMenu' })"
    />

    <div class="mobileContactsView__body">
      <div class="infoblock mod-details">
        <div class="infoblock__title">Реквизиты</div>

        <div
          v-for="(item, index) in contactsFields.detail"
          :key="index"
          class="infoblock__item"
        >
          <div class="infoblock__item-key">{{ item.key }}</div>
          <div class="infoblock__item-value">{{ contacts[item.value] }}</div>
        </div>
      </div>

      <div class="infoblock mod-bank">
        <div class="infoblock__title">Банковские реквизиты</div>

        <div
          v-for="(item, index) in contactsFields.bank"
          :key="index"
          class="infoblock__item"
        >
          <div class="infoblock__item-key">{{ item.key }}</div>
          <div class="infoblock__item-value">{{ contacts[item.value] }}</div>
        </div>
      </div>

      <div class="infoblock mod-contacts">
        <div class="infoblock__title">Контакты</div>

        <div class="infoblock__item">
          <div class="infoblock__item-key">Телефон</div>
          <a
            :href="`tel:${phoneRef}`"
            class="infoblock__item-value contacts-value"
          >
            {{ contacts.contact_phone }}
          </a>
        </div>

        <div class="infoblock__item">
          <div class="infoblock__item-key">Почта</div>
          <a
            :href="`mailto:${contacts.contact_email}`"
            class="infoblock__item-value contacts-value"
          >
            {{ contacts.contact_email }}
          </a>
        </div>
      </div>

      <div class="infoblock mod-feedback">
        <div class="font1-20-24 infoblock__title">Задайте вопрос</div>

        <ContactsForm
          v-if="user"
          v-loading="isLoading"
          @success="commentSent"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MobileHeader from "@/components/mobileHeader/MobileHeader";
import contactsMixin from "@/views/СontactsView/contactsMixin";

export default {
  name: "MobileContactsView",
  components: { MobileHeader },
  mixins: [contactsMixin],
  methods: {
    backToMore() {
      this.$router.push({ name: "more-menu" });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobileContactsView {
  &__body {
    margin: 50px 0 80px;
    padding: 16px;
    background: #fff;
    .mod-details {
    }
    .mod-bank {
    }
    .infoblock {
      margin-bottom: 20px;
      &__title {
        font-weight: 500;
        font-size: 16px;
        font-family: $fontPrimary;
        margin-bottom: 16px;
      }
      &__item {
        border-top: 1px solid $backgroundGray;
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-key {
          font-weight: 400;
          font-size: 14px;
          color: $greyText;
        }
        &-value {
          font-weight: 400;
          font-size: 14px;
          text-align: right;
        }
        .contacts-value {
          font-weight: 700;
          font-size: 14px;
          color: $special;
        }
      }
    }
  }
}
</style>
