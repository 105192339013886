import { Request, getParamsUrl } from "@/core";
import { auth } from "@/constants/urls";

export default {
  dispatchPhone(data) {
    return Request.post(auth.dispatchPhone, data);
  },

  getToken(params) {
    const paramsFormatted = getParamsUrl(params);
    return Request.get(`${auth.getToken}${paramsFormatted}`);
  },

  checkTermsAgreement(params) {
    const paramsFormatted = getParamsUrl(params);

    return Request.get(`${auth.checkTerms}${paramsFormatted}`);
  },

  acceptTermAgreement(data) {
    return Request.post(auth.acceptTerms, data);
  },
};
