var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"carrier",on:{"click":function($event){_vm.isModalOpen = true}}},[_c('div',[_vm._v(_vm._s(_vm.buttonTitle))]),(_vm.isCarrierVerified)?_c('div',[_vm._v("кликните для изменения")]):_vm._e()]),(_vm.isModalOpen)?_c('div',{staticClass:"modal"},[_c('div',{staticClass:"modal__header",on:{"click":function($event){_vm.isModalOpen = false}}},[_c('img',{attrs:{"src":require("@/assets/images/fat-close.svg"),"alt":"close"}}),_c('div',{staticClass:"font1-20-24 modal__header-title"},[_vm._v(" Информация о поставщике ")])]),_c('div',{staticClass:"modal__input",on:{"click":function($event){return _vm.$refs.carrier.focus()}}},[_c('div',{class:[
          'auto-prompt',
          { filled: _vm.carrierInn.length || _vm.isCarrierFocused },
        ]},[_vm._v(" ИНН поставщика/перевозчика ")]),_c('el-autocomplete',{directives:[{name:"mask",rawName:"v-mask",value:('############'),expression:"'############'"}],ref:"carrier",class:[
          'auto-input',
          { filled: _vm.carrierInn.length || _vm.isCarrierFocused },
        ],attrs:{"type":"tel","placement":"top-start","fetch-suggestions":_vm.querySearchInn},on:{"focus":function($event){_vm.isCarrierFocused = true},"blur":function($event){_vm.isCarrierFocused = false},"input":_vm.checkInnLength,"select":_vm.setCarrier},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.label)+" ")]),_c('b',[_vm._v(_vm._s(item.value))])]}}],null,false,949939781),model:{value:(_vm.carrierInn),callback:function ($$v) {_vm.carrierInn=$$v},expression:"carrierInn"}})],1),_c('MainButton',{staticClass:"modal__button",attrs:{"title":"Проверить","disabled":_vm.isCarrierButtonDisabled},on:{"button-clicked":_vm.checkInn}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }