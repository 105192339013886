<template>
  <section class="home" v-loading="loading">
    <div class="home__header">
      <div class="home__header-userItem">
        <div v-if="user && user.profile_picture_link" class="photo">
          <img :src="user.profile_picture_link" alt="photo" />
        </div>
        <AvatarIcon v-else />
        <div class="name font2-18-24">{{ userName }}</div>
      </div>

      <div
        class="font1-14-14 home__header-wallet"
        @click="$router.push({ name: 'balance' })"
      >
        <span>{{ formattedNumberRu(user?.balance_cop / 100) }}&nbsp;₽</span>
      </div>
    </div>

    <div class="home__body">
      <HomeMessage :content="message" />

      <WorkBanner :content="nrzBanner" class="work-banner" />

      <HomeTimeslots :timeslots="timeslots" class="homeTimeslots" />

      <MainButton
        title="Получить таймслот"
        styles="mobile"
        class="get-timeslots"
        @button-clicked="$router.push({ name: 'getTimeslots' })"
      >
        <template v-slot:right>
          <ChevronIcon class="get-timeslots__icon" />
        </template>
      </MainButton>

      <ExpBanner :content="expBanner" class="exp-banner" />
    </div>

    <OneButtonMobileModal
      v-if="openConnectionModal"
      title="Ошибка"
      description="Отсутствует интернет
    соединение. Проверьте подключение к Wi-Fi или сотовой сети и повторите
    попытку."
      button-title="OK"
      @accept="openConnectionModal = false"
      @close="openConnectionModal = false"
    />
  </section>
</template>

<script>
import AvatarIcon from "@/assets/icons/svg/AvatarIcon";
import ChevronIcon from "@/assets/icons/svg/ChevronIconGrey";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import OneButtonMobileModal from "@/components/UI/modals/OneButtonMobileModal.vue";
import HomeMixin from "@/views/HomeView/HomeMixin";
import { formattedNumberRu } from "@/core";

export default {
  name: "MobileHomeView",
  components: {
    OneButtonMobileModal,
    MainButton,
    AvatarIcon,
    ChevronIcon,
  },
  mixins: [HomeMixin],
  data() {
    return {
      formattedNumberRu,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $bckgrnd;
  &__header {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: #fff;
    padding: 0 16px;
    margin-bottom: 16px;
    z-index: 5;
    &-userItem {
      display: flex;
      align-items: center;
      cursor: pointer;
      .name {
        margin: 0 8px;
      }
      .photo {
        width: 32px;
        height: 32px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &-wallet {
      display: flex;
      align-items: center;
      height: 23px;
      color: #ffffff;
      background-color: $special;
      padding: 0 10px;
      border-radius: 50px;
      span {
        margin-top: 2px;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    padding: 0 16px;
    margin-bottom: 90px;
    .work-banner {
      margin-bottom: 32px;
    }
    .homeTimeslots {
      margin-bottom: 16px;
    }
    .get-timeslots {
      margin-bottom: 32px;
      &__icon {
        margin-left: 6px;
        :deep(path) {
          stroke: white;
        }
      }
    }
  }
}
</style>
