<template>
  <MainButton
    v-if="isMobile"
    :title="buttonTitle"
    styles="mobile"
    :disabled="isDisabled"
    @button-clicked="clicked"
  />
  <basic-button
    v-else
    :title="buttonTitle"
    :styles="buttonStyle"
    :disabled="isDisabled"
    @button-clicked="clicked"
  />
</template>

<script>
import BasicButton from "@/components/UI/controls/buttons/BasicButton.vue";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";

export default {
  name: "TimerButton",
  components: { MainButton, BasicButton },
  props: {
    timerInterval: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      isButtonTimer: true,
      timerId: null,
      timerValue: 0,
    };
  },
  computed: {
    buttonTitle() {
      if (this.isButtonTimer) {
        let minutes = Math.trunc(this.timerValue / 60);
        let minutesText = minutes < 10 ? "0" + String(minutes) : minutes;
        let seconds = this.timerValue % 60;
        let secondsText = seconds < 10 ? "0" + String(seconds) : seconds;

        return this.isMobile
          ? `Запросить через ${minutesText}:${secondsText}`
          : `Запросить код повторно можно через ${minutesText}:${secondsText}`;
      }
      return this.isMobile ? "Запросить код еще раз" : "Выслать код повторно";
    },
    buttonStyle() {
      return this.isButtonTimer ? "secondary not-allowed" : "primary";
    },
    isDisabled() {
      return this.isButtonTimer;
    },
  },
  watch: {
    timerInterval() {
      if (this.timerInterval !== 0) {
        this.startTimer();
      }
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
  methods: {
    startTimer() {
      this.timerValue = this.timerInterval;
      this.isButtonTimer = true;
      this.timerId = setInterval(() => {
        this.timerValue--;
      }, 1000);
      setTimeout(() => {
        clearInterval(this.timerId);
        this.isButtonTimer = false;
      }, this.timerValue * 1000);
    },
    clicked() {
      if (!this.isDisabled) {
        this.$emit("button-clicked");
      }
    },
  },
};
</script>
