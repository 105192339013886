<template>
  <div class="confirm">
    <TimeslotConfirmationTimer
      :duration="confirmationDuration"
      v-on="$listeners"
    />
    <div class="font1-20-24 confirm__title">Подтвердить таймслоты</div>
    <div class="font2-14-18 confirm__description">
      Введите информацию по авто и нажмите "Подтвердить"
    </div>

    <div class="confirm__list">
      <div
        v-for="(timeslot, index) in timeslotsList"
        :key="index"
        class="confirm__list-item"
      >
        <ConfirmCardMobile
          :timeslot="timeslot"
          :settings="settings"
          :index="index + 1"
          :autofill="autofillList"
          :axis-count="axisCountList"
          @set="setField(index, $event)"
          @delete="timeslot.isDeleted = true"
          @clearError="clearError(index)"
        />
      </div>
    </div>

    <div class="confirm__buttons">
      <MainButton
        title="Подтвердить"
        :disabled="!canConfirmAll"
        class="confirm__buttons-item"
        @button-clicked="isGeneralModalOpen = true"
      />
    </div>

    <ConfirmGeneralModal
      v-if="isGeneralModalOpen"
      :terminal-name="terminal.name"
      :timeslots="timeslotsList"
      :postponed="settings.has_postponed_payments"
      :text-values="textValues"
      @confirm="toConfirm"
      @close="isGeneralModalOpen = false"
    />
  </div>
</template>

<script>
import ConfirmCardMobile from "./components/ConfirmCardMobile.vue";
import confirmTimeslotsMixin from "./confirmTimeslotsMixin";

export default {
  name: "ConfirmTimeslotsMobile",
  components: { ConfirmCardMobile },
  mixins: [confirmTimeslotsMixin],

  methods: {
    clearError(index) {
      this.timeslotsList[index].hasApiError = false;
      this.timeslotsList[index].apiErrorText = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  padding-top: 56px;
  padding-bottom: 120px;
  &__title {
    margin-top: 24px;
    margin-bottom: 8px;
    font-weight: 500;
    color: $mainBlack;
  }
  &__description {
    margin-bottom: 20px;
    color: $textSecondary;
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }
  &__buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 88px;
    padding: 16px 16px;
    background: #fff;
    z-index: 6;
    &-item {
      width: 100%;
    }
  }
}
</style>
