<template>
  <section>
    <div class="cabinetProfile__wrapper">
      <div class="cabinetProfile__wrapper-header">
        <el-upload
          class="avatar__box"
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG"
          :on-change="avatarSelected"
        >
          <div class="avatar__box-wrapper">
            <img
              v-if="displayAvatar"
              :src="displayAvatar"
              class="image"
              alt="avatar"
            />
            <AddAvatarIcon v-else class="icon" />
          </div>

          <BasicButton
            v-if="isMobile"
            class="avatar__box-text"
            :title="new_avatar ? 'Изменить фото' : 'Загрузить фото'"
          />
          <el-popover
            v-else
            placement="bottom"
            width="332"
            trigger="hover"
            content="Фотографии должны быть в формате jpeg, jpg или png, при этом размер фотографии не должен превышать 5 мегабайт"
            popper-class="custom-tooltip"
          >
            <BasicButton
              slot="reference"
              class="avatar__box-text"
              :title="new_avatar ? 'Изменить фото' : 'Загрузить фото'"
            />
          </el-popover>
        </el-upload>

        <span class="exit" @click="exitModal = true">Выйти</span>
      </div>

      <div class="cabinetProfile__wrapper-body">
        <el-form
          ref="profile"
          :rules="profileRules"
          :model="profile"
          class="user-form"
        >
          <el-form-item ref="first_name" prop="first_name">
            <MainInput
              class="name"
              title="Ваше имя"
              v-model="profile.first_name"
              @clearValidation="resetName"
            />
            <template v-slot:error="{ error }">
              <div class="customErrors">
                <ValidationErrorIcon />
                <div class="customErrors__text">{{ error }}</div>
              </div>
            </template>
          </el-form-item>

          <el-form-item>
            <MainInput
              class="org"
              title="Ваша фамилия"
              v-model="profile.last_name"
            />
          </el-form-item>

          <PhoneInput
            title="Номер телефона"
            v-model="formattedPhone"
            v-mask="'+7 (###) ###-##-##'"
            :disabled="true"
          />
        </el-form>

        <MainButton
          class="button"
          title="Сохранить"
          styles="profile"
          :disabled="disabledButton"
          @button-clicked="saveProfile('userForm')"
        />

        <CabinetAccount v-if="isMobile" :user="user" class="account" />

        <span v-if="isMobile" class="exit" @click="exitModal = true">
          Выйти из аккаунта
        </span>

        <MainButton
          v-if="isMobile && !disabledButton"
          class="button"
          title="Сохранить изменения"
          styles="mobile"
          @button-clicked="saveProfile('userForm')"
        />
      </div>
    </div>

    <template v-if="!isMobile">
      <div class="cabinetProfile__button">
        <span
          class="cabinetProfile__button-title"
          @click="handleDelete('full')"
        >
          Удалить аккаунт
        </span>
      </div>

      <div class="cabinetProfile__button">
        <span
          class="cabinetProfile__button-title"
          @click="handleDelete('data')"
        >
          Удалить данные аккаунта
        </span>
      </div>
    </template>

    <TwoButtonsModal
      v-if="exitModal"
      title="Выйти из аккаунта"
      description="Вы уверены, что хотите выйти из аккаунта?"
      leftButton="Отменить"
      rightButton="Выйти"
      @close="exitModal = false"
      @accept="exitProfile"
    />

    <TwoButtonsModal
      v-if="deleteModal"
      :title="deleteTitle"
      description="Для подтверждения удаления, введите номер телефона данного аккаунта"
      leftButton="Отменить"
      rightButton="Подтвердить"
      :disabled="!enableDeleteButton"
      @close="deleteModal = false"
      @accept="executeDelete"
    >
      <el-form
        ref="deletedForm"
        class="deletedForm"
        :rules="rules"
        :model="deletedForm"
      >
        <el-form-item class="item" ref="confirmedPhone" prop="confirmedPhone">
          <PhoneInput
            title="Номер телефона"
            v-model="deletedForm.confirmedPhone"
            v-mask="'+7 (###) ###-##-##'"
            @input="setPhone"
            @clearValidation="resetPhone"
          />
          <template v-slot:error="{ error }">
            <div class="customErrors">
              <ValidationErrorIcon />
              <div class="customErrors__text">{{ error }}</div>
            </div>
          </template>
        </el-form-item>
      </el-form>
    </TwoButtonsModal>
  </section>
</template>

<script>
import MainInput from "@/components/UI/controls/form/MainInput.vue";
import PhoneInput from "@/components/UI/controls/form/PhoneInput.vue";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import BasicButton from "@/components/UI/controls/buttons/BasicButton.vue";
import AddAvatarIcon from "@/assets/icons/svg/AddAvatarIcon.vue";
import ValidationErrorIcon from "@/assets/icons/svg/ValidationErrorIcon.vue";
import TwoButtonsModal from "@/components/UI/modals/TwoButtonsModal";
import CabinetAccount from "@/components/cabinet/CabinetAccount";

import { GET_PROFILE, SET_PROFILE, SET_LOADING } from "@/store/actions";
import { mapGetters, mapMutations } from "vuex";
import { removeUserAccess, purePhone, parsedPhone } from "@/core";
import Api from "@/api";

const MAXIMUM_FILE_SIZE = 5; // допустимый размер аватара в МБ
export default {
  name: "CabinetProfile",
  components: {
    PhoneInput,
    ValidationErrorIcon,
    MainInput,
    MainButton,
    BasicButton,
    AddAvatarIcon,
    TwoButtonsModal,
    CabinetAccount,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const phoneRules = (rule, value, callback) => {
      if (
        purePhone(value) !==
        purePhone(this.userProfile.terms_acceptance.phone_number)
      ) {
        callback(
          new Error(
            "Номер телефона не совпадает с номером авторизованного пользователя"
          )
        );
      } else {
        callback();
      }
    };

    return {
      disabledButton: true,
      exitModal: false,
      deleteModal: false,
      new_avatar: null,
      profile: {
        first_name: "",
        last_name: "",
      },

      deletedForm: {
        confirmedPhone: "",
      },
      deleteType: "",
      deleteTitle: "",
      rules: {
        confirmedPhone: {
          validator: phoneRules,
          trigger: "submit",
        },
      },
      profileRules: {
        first_name: {
          required: true,
          message: "Укажите имя",
          trigger: "submit",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      userProfile: GET_PROFILE,
    }),
    displayAvatar() {
      return this.new_avatar ? this.new_avatar : null;
    },
    enableDeleteButton() {
      return this.deletedForm.confirmedPhone.length === 18;
    },
    formattedPhone: {
      get() {
        return parsedPhone(this.userProfile.terms_acceptance.phone_number);
      },
      set() {
        console.log("Phone never changes!");
      },
    },
  },
  watch: {
    profile: {
      handler(newVal) {
        this.checkForChanges(newVal);
      },
      deep: true,
    },
  },
  created() {
    this.updateProfile();
  },
  methods: {
    ...mapMutations({
      setLoading: SET_LOADING,
      setProfile: SET_PROFILE,
    }),

    checkForChanges(val) {
      if (
        this.userProfile.first_name !== val.first_name ||
        this.userProfile.last_name !== val.last_name
      ) {
        this.disabledButton = false;
      }
    },

    avatarSelected(file) {
      const isCorrectType = ["image/png", "image/jpeg"].includes(file.raw.type);
      const isNormalSize = file.size / 1024 / 1024 < MAXIMUM_FILE_SIZE;
      if (isCorrectType && isNormalSize) {
        this.handleAvatarUpload(file.raw);
      } else {
        let message = isCorrectType
          ? "Размер файла - не более 5 Мбайт"
          : "Допустимые форматы: jpeg, png";
        this.errorInformer(message, 65);
      }
    },

    async handleAvatarUpload(file) {
      let formData = new FormData();
      formData.append("file", file);
      this.setLoading(true);
      try {
        let { data } = await Api.profile.uploadAvatar(formData);
        this.setProfile(data);
        this.updateProfile();
      } catch (error) {
        this.errorInformer(error.data[0].message, 65);
      } finally {
        this.setLoading(false);
      }
    },
    saveProfile() {
      this.$refs.profile.validate((valid) => {
        if (valid) {
          let formData = new FormData();
          for (let key in this.profile) {
            formData.append(key, this.profile[key]);
          }
          this.setLoading(true);
          Api.profile
            .updateProfile(formData)
            .then((res) => {
              this.setProfile(res.data);
            })
            .then(() => {
              this.updateProfile();
              this.successInformer("Данные успешно обновлены");
              this.disabledButton = true;
            })
            .catch((err) => console.log(err))
            .finally(() => this.setLoading(false));
        }
      });
    },
    exitProfile() {
      this.setProfile(null);
      removeUserAccess();
      this.$router.push({ name: "login" });
    },
    updateProfile() {
      this.new_avatar = this.userProfile.profile_picture_link || null;
      this.profile.first_name = this.userProfile.first_name || "";
      this.profile.last_name = this.userProfile.last_name || "";
    },
    handleDelete(type) {
      this.deleteType = type;
      this.deleteTitle =
        type === "full" ? "Удаление аккаунта" : "Удаление данных аккаунта";
      this.deleteModal = true;
    },
    executeDelete() {
      this.$refs.deletedForm.validate(async (valid) => {
        if (valid) {
          try {
            this.deleteType === "full"
              ? await this.deleteAccount()
              : await this.deleteProfileData();
            this.deleteModal = false;
            this.deleteType = "";
            this.deleteTitle = "";
            this.deletedForm.confirmedPhone = "";
          } catch (error) {
            this.errorInformer(error.data[0].message, 65);
          }
        }
      });
    },
    async deleteAccount() {
      await Api.profile.deleteAccount();
      this.exitProfile();
    },
    async deleteProfileData() {
      await Api.profile.deleteProfileData();
      window.location.reload();
    },
    setPhone(value) {
      this.deletedForm.confirmedPhone = value;
    },
    resetPhone() {
      this.$refs.deletedForm.clearValidate("confirmedPhone");
    },
    resetName() {
      this.$refs.profile.clearValidate("first_name");
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinetProfile__wrapper {
  background: #ffffff;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 16px;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: 14px;
    .avatar__box {
      :deep .el-upload {
        display: flex;
        align-items: center;
      }
      &-wrapper {
        height: 48px;
        width: 48px;
        margin-right: 12px;
        border-radius: 50%;
        object-position: center;
        object-fit: cover;
        overflow: hidden;
        .image {
          height: 100%;
          width: 100%;
        }
        .icon {
          cursor: pointer;
        }
      }
      &-text {
        color: $special;
      }
    }
    .exit {
      font-weight: 500;
      cursor: pointer;
      color: $greyText;
      &:hover {
        color: $error;
      }
    }
  }
  &-body {
    .name {
      margin-bottom: 20px;
    }
    .org {
      margin-bottom: 20px;
    }
    .button {
      margin-top: 30px;
    }
  }
}
.cabinetProfile__button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-family: $fontPrimary;
  font-size: 14px;
  font-weight: 500;
  &-title {
    color: $greyText;
    cursor: pointer;
    &:hover {
      color: $error;
    }
  }
}

.deletedForm {
  margin-bottom: 36px;
}

@media (max-width: 1279px) {
  .cabinetProfile__wrapper {
    padding: 0;
    &-header {
      justify-content: center;
      margin-bottom: 38px;
      .avatar__box {
        :deep .el-upload {
          flex-direction: column;
          justify-content: center;
        }
        &-wrapper {
          margin: 0 0 14px;
          height: 56px;
          width: 56px;
          .icon {
            height: 56px;
            width: 56px;
          }
        }
      }
      .exit {
        display: none;
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .user-form {
        width: 100%;
      }
      .name {
        width: 100%;
      }
      .org {
        width: 100%;
      }
      .account {
        width: 100%;
        margin-bottom: 40px;
      }
      .exit {
        font-weight: 500;
        font-size: 14px;
        font-family: $fontPrimary;
        color: $error;
        margin-bottom: 40px;
      }
    }
  }
}
</style>
