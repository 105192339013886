<template>
  <section class="filter">
    <MainInput
      :value="plateTruck"
      title="Номер авто"
      class="filter__input"
      @input="plateTruck = $event"
    />

    <MainButton
      v-if="isMobile"
      title="Поиск"
      :disabled="!isPlateLegal"
      @button-clicked="sendQuery"
    />

    <div
      v-else
      :class="['filter__button', { disabled: !isPlateLegal }]"
      @click="sendQuery"
    >
      <img src="@/assets/images/SearchWhite.svg" alt="search" />
    </div>

    <div
      v-if="isPlateLegal && !isMobile"
      class="font2-14-18 filter__clear"
      @click="dropFilter"
    >
      <span>Очистить фильтр</span>

      <img src="@/assets/images/close-icon.svg" alt="search" />
    </div>
  </section>
</template>

<script>
import MainInput from "@/components/UI/controls/form/MainInput.vue";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import { numAutoValidator } from "@/constants/rules/GetTimeslotRules";

export default {
  name: "UnpaidFilter",
  components: { MainButton, MainInput },
  data() {
    return {
      plateTruck: "",
    };
  },
  computed: {
    isPlateLegal() {
      return this.plateTruck && !numAutoValidator(this.plateTruck);
    },
  },
  methods: {
    dropFilter() {
      this.plateTruck = "";
      this.$emit("clear");
    },
    sendQuery() {
      if (this.isPlateLegal) {
        this.$emit("search", this.plateTruck);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 26px 32px 16px;
  background: #fff;
  border-bottom: 1px solid $backgroundGray;
  @media (max-width: 1279px) {
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px;
  }

  &__input :deep .input {
    width: 300px;
    @media (max-width: 1279px) {
      width: 100%;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: $special;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: $hover;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        background: $special;
      }
    }
  }

  &__clear {
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: 500;
    color: $greyText;
    cursor: pointer;
  }
}
</style>
