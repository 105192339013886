<template>
  <section :class="['confirm', { deleted: timeslot.isDeleted }]">
    <div class="confirm__header">
      <div>
        <div class="font1-20-24 confirm__header-title">Авто №{{ index }}</div>
        <RequestDateTime :timeslot="timeslot" styles="gray" />
      </div>
      <FinalStatus v-if="timeslot.isConfirmed" :title="successTitle" />
      <FinalStatus
        v-else-if="timeslot.isDeleted"
        title="Запрос отменен"
        :is-deleted="true"
      />
      <CancelButton
        v-else
        title="Удалить"
        class="confirm__header-button"
        @button-clicked="isDeleteModalOpen = true"
      />
    </div>
    <el-form
      v-if="!timeslot.isDeleted"
      ref="confirmForm"
      :model="timeslot"
      :rules="rulesFormAddTimeslot"
      :validate-on-rule-change="false"
      class="confirm__form"
    >
      <el-form-item prop="plate_truck" ref="plate_truck">
        <AutocompleteInput
          placeholder="Номер авто"
          :disabled="timeslot.isConfirmed"
          :options="truckOptions"
          :class="{ error: timeslot.hasApiError }"
          @input="setTruckField"
          @editing="resetField('plate_truck')"
        />
        <template v-slot:error="{ error }">
          <div class="customErrors">
            <ValidationErrorIcon class="customErrors__icon" />
            <div class="customErrors__text">{{ error }}</div>
          </div>
        </template>
      </el-form-item>
      <div v-if="timeslot.hasApiError" class="apiError">
        <ValidationErrorIcon class="apiError__icon" />
        <div class="apiError__text">{{ timeslot.apiErrorText }}</div>
      </div>

      <template v-if="settings.timeslot_confirm_fields.plate_trailer">
        <el-form-item prop="plate_trailer" ref="plate_trailer" class="brief">
          <AutocompleteInput
            placeholder="Номер прицепа"
            :disabled="timeslot.isConfirmed || areInputsDisabled"
            :options="trailerOptions"
            :force-clear="clearTruckFields"
            @input="setField('plate_trailer', $event)"
            @editing="resetField('plate_trailer')"
          />
          <template v-slot:error="{ error }">
            <div class="customErrors">
              <ValidationErrorIcon class="customErrors__icon" />
              <div class="customErrors__text">{{ error }}</div>
            </div>
          </template>
        </el-form-item>

        <el-form-item prop="axis_count" ref="axis_count" class="brief">
          <CustomSelect
            :model="timeslot.axis_count"
            placeholder="Кол-во осей"
            :disabled="timeslot.isConfirmed || areInputsDisabled"
            :options="axisCount"
            :not-clearable="true"
            @select="setField('axis_count', $event)"
          />
          <template v-slot:error="{ error }">
            <div class="customErrors">
              <ValidationErrorIcon class="customErrors__icon" />
              <div class="customErrors__text">{{ error }}</div>
            </div>
          </template>
        </el-form-item>
      </template>

      <template v-if="settings.timeslot_confirm_fields.driver_phone">
        <el-form-item prop="driver_name" ref="driver_name" class="brief">
          <AutocompleteInput
            placeholder="ФИО водителя"
            :disabled="timeslot.isConfirmed || areInputsDisabled"
            :options="nameOptions"
            :force-clear="clearTruckFields"
            @input="setField('driver_name', $event)"
            @editing="resetField('driver_name')"
          />
          <template v-slot:error="{ error }">
            <div class="customErrors">
              <ValidationErrorIcon class="customErrors__icon" />
              <div class="customErrors__text">{{ error }}</div>
            </div>
          </template>
        </el-form-item>

        <el-form-item prop="driver_phone" ref="driver_phone" class="brief">
          <AutocompleteInput
            v-mask="'+7 (###) ###-##-##'"
            placeholder="Номер телефона водителя"
            :disabled="timeslot.isConfirmed || areInputsDisabled"
            :options="phoneOptions"
            :is-phone="true"
            :force-clear="clearTruckFields"
            @input="setField('driver_phone', $event)"
            @editing="resetField('driver_phone')"
          />
          <template v-slot:error="{ error }">
            <div class="customErrors">
              <ValidationErrorIcon class="customErrors__icon" />
              <div class="customErrors__text">{{ error }}</div>
            </div>
          </template>
        </el-form-item>
      </template>
    </el-form>

    <TwoButtonsModal
      v-if="isDeleteModalOpen"
      title="Отказ от таймслота"
      description="Вы действительно хотите отказаться от таймслота?"
      left-button="Нет, не хочу"
      right-button="Отказаться"
      :success="false"
      @close="isDeleteModalOpen = false"
      @accept="deleteTimeslot"
    />
  </section>
</template>

<script>
import AutocompleteInput from "@/components/UI/controls/form/AutocompleteInput.vue";
import CancelButton from "@/components/UI/controls/buttons/CancelButton.vue";
import confirmCardMixin from "./confirmCardMixin";
import { rulesFormAddTimeslot } from "@/constants/rules/GetTimeslotRules";

export default {
  name: "ConfirmCardDesktop",
  components: { CancelButton, AutocompleteInput },
  mixins: [confirmCardMixin],
  data() {
    return {
      rulesFormAddTimeslot,
    };
  },
  methods: {
    setField(name, value) {
      this.$emit("set", { name, value });
      this.$nextTick(() => {
        this.$refs.confirmForm.validateField(name);
      });
    },
    setTruckField(value) {
      this.$emit("set", { name: "plate_truck", value });
      if (this.settings.timeslot_confirm_fields.driver_phone) {
        this.$emit("set", { name: "driver_phone", value: "" });
        this.$emit("set", { name: "driver_name", value: "" });
        this.resetField("driver_phone");
        this.resetField("driver_name");
      }
      if (this.settings.timeslot_confirm_fields.plate_trailer) {
        this.$emit("set", { name: "plate_trailer", value: "" });
        this.resetField("plate_trailer");
      }
      this.clearTruckFields = true;
      this.$nextTick(() => {
        this.$refs.confirmForm.validateField("plate_truck");
        this.clearTruckFields = false;
      });
    },
    resetField(field) {
      this.$refs[field].clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
.customErrors {
  top: 50px;
}
.confirm {
  padding: 24px;
  border-radius: 16px;
  border: 1px solid $greyBanner;
  &.deleted {
    border: 1px solid $error;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    &-title {
      font-weight: 500;
      color: $mainBlack;
      margin-bottom: 4px;
    }

    &-button.el-button.is-plain.mod-cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      padding: 0 16px;
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 24px;
    margin-top: 20px;

    .el-form-item {
      width: 100%;
      &.brief {
        width: 49%;
      }
    }
    .error ::v-deep .autocomplete {
      border: 1px solid $error;
    }

    .apiError {
      display: flex;
      align-items: center;
      gap: 5px;
      width: 100%;
      margin-top: -20px;
      color: $error;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      &__icon {
        flex-shrink: 0;
      }
      &__text {
        margin-top: 1px;
        margin-left: 2px;
        line-height: 1;
      }
    }
  }
}
</style>
