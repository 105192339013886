<template>
  <div :class="['picker', { disabled: disabled }]">
    <el-date-picker
      ref="picker"
      v-model="value"
      type="date"
      :placeholder="placeholder"
      value-format="yyyy-MM-dd"
      :editable="false"
      :clearable="false"
      :picker-options="pickerOptions"
      :disabled="disabled"
      :append-to-body="false"
      @focus="onFocus"
      @blur="onBlur"
      format="dd.MM.yyyy"
      :class="[{ filled: value }, { mobile: isMobile }]"
    />
    <div v-if="value" class="font2-12-16 picker__title">
      {{ placeholder }}
    </div>

    <ModalWrapper v-if="isMobile && isFocused" />
  </div>
</template>

<script>
import ModalWrapper from "@/components/UI/MobileModalWrapper";
export default {
  name: "DatePicker",
  components: { ModalWrapper },
  props: {
    date: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1,
        ...this.options,
      },
      isFocused: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.date;
      },
      set(value) {
        this.$emit("select", value);
      },
    },
  },
  methods: {
    onBlur() {
      this.isFocused = false;
    },
    onFocus() {
      this.isFocused = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.picker {
  position: relative;
  &.disabled {
    opacity: 0.5;
  }
  &__title {
    position: absolute;
    top: 10px;
    left: 38px;
    color: $greyText;
  }
  :deep .el-date-editor.el-input {
    display: block;
    padding-left: 0;
  }
  :deep
    .el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date {
    .el-input__inner {
      height: 50px;
      line-height: 50px;
      padding-left: 38px;
      background: $bckgrnd;
      border: none;
      border-radius: 10px;
      color: $mainBlack;
      font-family: "TT Firs Neue", sans-serif;

      &::placeholder {
        font-family: $fontSecondary;
        color: $greyText;
        padding-left: -30px;
      }
    }
    .el-input__prefix {
      width: 14px;
      height: 50px;
      border-radius: 10px 0 0 10px;
      left: 12px;

      .el-input__icon {
        line-height: 50px;
      }
    }
    &.filled {
      .el-input__inner {
        padding-top: 20px;
        font-size: 16px;
        font-weight: 500;
        font-family: $fontSecondary;
      }
    }
    &.mobile {
      .el-picker-panel.el-date-picker.el-popper {
        position: fixed !important;
        top: 40% !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0;
        width: 100%;
        border-radius: 16px 16px 0 0;
        .el-picker-panel__content {
          width: 90%;
          margin: 0 auto;
          .el-date-table th {
            text-align: center;
          }
        }
        .popper__arrow {
          display: none;
        }
      }
    }
  }
  :deep .el-picker-panel.el-date-picker.el-popper {
    border-radius: 16px;
    box-shadow: 0 0 10px rgba(132, 132, 132, 0.15);
    .el-date-picker__header {
      padding-top: 14px;
      &-label {
        color: $special;
        padding: 0 2px;
      }
      &-label + .el-date-picker__header-label {
        font-weight: 400;
      }
      .el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-d-arrow-left,
      .el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-d-arrow-right {
        display: none;
      }
      .el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-arrow-left,
      .el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-arrow-right {
        color: $special;
        font-size: 18px;
        font-weight: 600;
        padding: 6px;
        border: 1px solid #eef0f4;
        border-radius: 10px;
        margin: 0;
      }
    }
    .el-date-table th {
      font-weight: 400;
      font-size: 16px;
      &:nth-child(6),
      &:last-child {
        color: $error;
      }
    }
    .el-picker-panel {
      line-height: 14px;
    }
    .el-date-table td {
      &.available {
        span {
          font-weight: 400;
          font-size: 16px;
        }
        &:hover {
          color: $special;
        }
      }
      &.current span {
        background-color: $special;
      }
      &.today {
        background-color: transparent;
        span {
          color: $special;
          font-weight: 400;
          font-size: 16px;
        }
        &.current span {
          color: #fff;
        }
      }
      &.next-month,
      &.prev-month {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}
</style>
