<template>
  <section class="mobile__modal">
    <slot />
  </section>
</template>

<script>
export default {
  name: "FullscreenModalWrapper",
};
</script>

<style lang="scss" scoped>
.mobile__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 70px;
  background: #fff;
  z-index: 20;
}
</style>
