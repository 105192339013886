<template>
  <section class="mobileHeader">
    <div v-if="hasChevron" class="mobileHeader__back" @click="$emit('back')">
      <img src="@/assets/images/chevron-left.svg" alt="back" />
    </div>

    <div class="mobileHeader__title">{{ title }}</div>

    <div v-if="hasRefresh" class="mobileHeader__refresh" @click="refresh">
      Обновить
    </div>
  </section>
</template>

<script>
export default {
  name: "MobileHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
    // для страниц из доп. меню - показать иконку возврата
    hasChevron: {
      type: Boolean,
      default: false,
    },
    // для страниц с принудительным обновление
    hasRefresh: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    refresh() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.mobileHeader {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: #fff;
  z-index: 2;
  &__back {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
  }
  &__title {
    font-size: 16px;
    font-weight: 700;
    color: $mainBlack;
  }
  &__refresh {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    font-family: $fontPrimary;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: $special;
  }
}
</style>
