<template>
  <section class="banner">
    <template v-if="!isMobile">
      <div class="banner__left mod-one" />
      <div class="banner__left mod-two" />
      <div class="banner__left mod-three" />
    </template>

    <div class="banner__right mod-one" />
    <div class="banner__right mod-two" />
    <div class="banner__right mod-three" />

    <div class="banner__info">
      <div class="font2-14-18 title">{{ currentTitle }}</div>
      <div class="value">{{ userBalance }}</div>
    </div>

    <MainButton
      :title="isMobile ? 'Подробнее' : 'Пополнить'"
      class="banner__button"
      @button-clicked="handleButtonClick"
    />

    <PaymentModal
      v-if="isPaymentModalOpen"
      @close="isPaymentModalOpen = false"
    />
  </section>
</template>

<script>
import PaymentModal from "@/components/cabinet/paymentModal/PaymentModal.vue";
import MainButton from "@/components/UI/controls/buttons/MainButton";
import { formattedNumberRu } from "@/core";

export default {
  name: "CabinetAccount",
  components: {
    PaymentModal,
    MainButton,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isPaymentModalOpen: false,
    };
  },
  computed: {
    currentTitle() {
      return this.isMobile ? "Баланс" : "На счету";
    },
    userBalance() {
      if (!this.user) return null;
      return `${formattedNumberRu(this.user.balance_cop / 100)} ₽`;
    },
  },
  methods: {
    handleButtonClick() {
      this.isMobile
        ? this.$router.push({ name: "balance" })
        : (this.isPaymentModalOpen = true);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 86px;
  padding: 24px;
  background: $greyBanner;
  border-radius: 20px;
  overflow: hidden;
  &__left {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;

    &.mod-one {
      height: 170%;
      width: 21.4vw;
      border-radius: 0 0 200px 0;
    }
    &.mod-two {
      height: 110%;
      width: 16.7vw;
      border-radius: 0 0 130px 0;
    }
    &.mod-three {
      height: 40%;
      width: 11.9vw;
      border-radius: 0 0 60px 0;
    }
  }
  &__right {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    @media (max-width: 1279px) {
      top: 0;
      bottom: unset;
      border-top: none;
      border-bottom: 1px solid #fff;
    }

    &.mod-one {
      height: 170%;
      width: 21.4vw;
      border-radius: 160px 0 0 0;
      @media (max-width: 1279px) {
        width: 228px;
        height: 106px;
        border-radius: 0 0 0 100px;
      }
    }
    &.mod-two {
      height: 110%;
      width: 16.7vw;
      border-radius: 110px 0 0 0;
      @media (max-width: 1279px) {
        width: 194px;
        height: 72px;
        border-radius: 0 0 0 60px;
      }
    }
    &.mod-three {
      height: 50%;
      width: 11.9vw;
      border-radius: 60px 0 0 0;
      @media (max-width: 1279px) {
        width: 160px;
        height: 37px;
        border-radius: 0 0 0 30px;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      position: relative;
      width: fit-content;
      font-weight: 500;
      color: $textSecondary;
      background: $greyBanner;
    }
    .value {
      position: relative;
      width: fit-content;
      font-family: $fontPrimary;
      font-size: 28px;
      font-weight: 500;
      line-height: 34px;
      color: $mainBlack;
      background: $greyBanner;
      @media (max-width: 1279px) {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    border-radius: 56px !important;
  }
}

@media (max-width: 1279px) {
  .banner {
    padding: 12px 24px;
    height: 69px;
    &__roadLineRight {
      &-one {
        width: 33.4vw;
      }
      &-two {
        width: 26.7vw;
      }
      &-tree {
        width: 18.9vw;
      }
    }
    &__content {
      &-info {
        .info__title {
          font-size: 14px;
          line-height: 16px;
        }
        .info__amount {
          font-size: 24px;
          line-height: 32px;
        }
      }
      &-button {
        width: 108px;
        height: 32px !important;
      }
    }
  }
}
</style>
