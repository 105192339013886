<template>
  <section class="trucks" v-loading="isLoading">
    <div class="font1-18-24 trucks__header">Мои машины</div>
    <div class="trucks__body">
      <template v-if="trucksList.length">
        <div class="trucks__body-aside">
          <div
            :ref="truck.truck_id"
            v-for="truck in trucksList"
            :key="truck.truck_id"
          >
            <TruckCard
              :truck="truck"
              :is-active="truck.truck_id === activeTruck"
            />
          </div>
        </div>
        <div class="trucks__body-map">
          <YandexMapComponent
            v-if="yandexMapBounds.length"
            :points="yandexMapList"
            :bounds="yandexMapBounds"
            @item-selected="scrollToTruck"
          />
          <EmptyTrucks
            v-if="!yandexMapBounds.length && !isLoading"
            title="У вас нет машин с позиционированием по ГЛОНАСС"
          />
        </div>
      </template>
      <EmptyTrucks
        v-if="!trucksList.length && !isLoading"
        title="У вас еще не добавлено ни одного автомобиля"
      />
    </div>
  </section>
</template>

<script>
import trucksMixin from "@/views/TrucksView/trucksMixin";

export default {
  name: "DesktopTrucksView",
  mixins: [trucksMixin],
  methods: {
    scrollToTruck(active) {
      this.activeTruck = active;
      let el = this.$refs[active];
      el[0].scrollIntoView({ behavior: "smooth", block: "center" });
    },
  },
};
</script>

<style lang="scss" scoped>
.trucks {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  &__header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 64px;
    padding-left: 32px;
    border-bottom: 1px solid $backgroundGray;
    background: #fff;
    font-weight: 500;
    color: $mainBlack;
  }

  &__body {
    flex: 1;
    display: flex;
    overflow: auto;

    &-aside {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 400px;
      padding: 16px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: $backgroundGray;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #e0e5e6;
        border-radius: 4px;
      }
    }

    &-map {
      flex: 1;
    }
  }
}
</style>
