<template>
  <div class="empty">
    <div class="empty__icon">
      <Component :is="iconComponent" />
    </div>
    <div class="font2-16-22 empty__title">{{ title }}</div>
  </div>
</template>

<script>
import EmptyTruckIcon from "@/assets/icons/svg/EmptyTruckIcon.vue";
import SearchIcon from "@/assets/icons/svg/SearchIcon.vue";
export default {
  name: "EmptyUnpaid",
  components: { EmptyTruckIcon, SearchIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
    isSearchPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconComponent() {
      return this.isSearchPage ? SearchIcon : EmptyTruckIcon;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 320px;
  height: 100%;
  margin: 30vh auto 0;
  @media (max-width: 1279px) {
    margin-top: 15vh;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
  }

  &__title {
    font-weight: 500;
    color: $greyText;
    text-align: center;
  }
}
</style>
