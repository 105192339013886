<template><component :is="currentView" /></template>

<script>
import DesktopArchiveView from "@/views/ArchiveTimeslotsView/DesktopArchiveView.vue";
import MobileArchiveView from "@/views/ArchiveTimeslotsView/MobileArchiveView.vue";

export default {
  name: "ArchiveTimeslotsView",
  computed: {
    currentView() {
      return this.isMobile ? MobileArchiveView : DesktopArchiveView;
    },
  },
};
</script>
