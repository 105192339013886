<template>
  <el-button
    round
    plain
    type="primary"
    :class="['font1-14-16', styles, { disabled: disabled }]"
    @click="clicked"
  >
    <slot />
    {{ title }}

    <slot name="right" />
  </el-button>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    styles: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked() {
      if (!this.disabled) {
        this.$emit("button-clicked");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  padding: 12px 40px;
  font-weight: 500;
  &--primary.is-plain {
    background-color: $special !important;
    color: #fff;
    border-color: $special !important;
    height: 44px;
    position: relative;
    &:hover {
      background-color: $hover !important;
      border-color: $hover !important;
    }
    &:focus {
      background-color: $visited !important;
      border-color: $visited !important;
    }
    &.enter {
      width: 216px;
      height: 44px;
    }
    &.enter-empty {
      background-color: $mainBlack !important;
      color: $special;
      width: 216px;
      height: 44px;
      &:hover {
        background-color: $hover !important;
        border-color: $hover !important;
      }
      &:focus {
        background-color: $visited !important;
        border-color: $visited !important;
      }
    }
    &.next {
      background-color: transparent !important;
      color: $special;
      padding: 0 25px;
      height: 44px;
      ::v-deep span {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 13px;
      }
      &:hover {
        background-color: $hover !important;
        border-color: $hover !important;
      }
      &:focus {
        background-color: $visited !important;
        border-color: $visited !important;
      }
    }

    &.back {
      background-color: transparent !important;
      color: $greyText;
      border-color: $backgroundGray !important;
      padding: 0 25px;
      height: 44px;
      ::v-deep span {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 13px;
      }
      &:hover {
        color: $greyText !important;
        border-color: $greyText !important;
      }
      &:focus {
        color: $greyText !important;
        border-color: $visited !important;
      }
    }
    &.upBalance {
      width: 150px;
      height: 28px;
      padding: 0;
    }
    &.banner {
      width: 105px;
      min-width: 0px;
      max-width: 105px;
      padding: 6px 14px;
      height: 30px;
      font-weight: 500;
    }
    &.profile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-weight: 500;
      @media (max-width: 1279px) {
        margin-bottom: 32px;
      }
    }
    &.mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      border-radius: 50px;
      font-weight: 500;
      padding: unset;
    }
    &.mobile-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      border-radius: 50px;
      color: $special !important;
      background-color: #fff !important;
      padding: unset;
      &:hover,
      &:focus {
        color: #ffffff !important;
        border-color: $special !important;
        background: $special !important;
      }
    }
    &.mobile-transparent {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      border-radius: 50px;
      font-weight: 500;
      color: $special !important;
      background-color: transparent !important;
      padding: unset;
      &:hover,
      &:focus {
        color: #ffffff !important;
        border-color: $special !important;
        background: $special !important;
      }
    }
  }
}
.el-button--primary:focus,
.el-button--primary:hover {
  background-color: $special !important;
  color: #fff !important;
}

.el-button.el-button--primary.is-plain.disabled {
  border-color: $special !important;
  color: #fff !important;
  opacity: 0.4;
  cursor: not-allowed;
  &:hover,
  &:focus {
    background: $special !important;
  }
}
</style>
