import {
  GET_FULL_TERMINAL_LIST,
  FETCH_FULL_TERMINAL_LIST,
  SET_FULL_TERMINAL_LIST,
} from "@/store/actions";
import Api from "@/api";

export default {
  state: {
    fullTerminalList: [],
  },
  getters: {
    [GET_FULL_TERMINAL_LIST]: (state) => state.fullTerminalList,
  },
  actions: {
    [FETCH_FULL_TERMINAL_LIST]: async ({ commit }) => {
      try {
        const { data } = await Api.terminals.getTerminals();
        commit(SET_FULL_TERMINAL_LIST, data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    [SET_FULL_TERMINAL_LIST]: (state, list) => {
      state.fullTerminalList = list;
    },
  },
};
