<template>
  <section class="bill" v-loading="isLoading">
    <div v-if="!isMobile" class="font1-24-30 bill__title">Оплата по счету</div>

    <div class="bill__verify">
      <MainInput
        title="ИНН"
        v-mask="'############'"
        :value="clientInn"
        class="bill__verify-input"
        @input="setInn"
      />
      <MainButton
        :title="isClientVerified ? 'Изменить' : 'Проверить'"
        :disabled="isCheckInnDisabled"
        class="bill__verify-button"
        @button-clicked="verifyInn"
      />
    </div>

    <MainInput
      title="Название организации"
      :value="clientName"
      :disabled="true"
      class="bill__client"
    />

    <PaymentRadio
      :variants="variants"
      :value="currentVariant"
      :disabled="isRadioDisabled"
      @change="setVariant"
    />

    <el-collapse-transition>
      <div v-if="currentVariant === 2">
        <MainInput
          title="Почта"
          :value="clientEmail"
          class="bill__email"
          @input="setEmail"
          @clearValidation="hasEmailError = false"
        />
        <div v-if="hasEmailError" class="bill__validation">
          <ValidationErrorIcon class="bill__validation-icon" />
          <div class="bill__validation-text">{{ emailErrorText }}</div>
        </div>
      </div>
    </el-collapse-transition>

    <div class="bill__buttons">
      <CancelButton
        v-if="!isMobile"
        title="Назад"
        class="bill__buttons-item"
        @button-clicked="$emit('back')"
      />
      <MainButton
        title="Сформировать"
        :disabled="!isBillEnabled"
        class="bill__buttons-item"
        @button-clicked="submitBill"
      />
    </div>
  </section>
</template>

<script>
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import CancelButton from "@/components/UI/controls/buttons/CancelButton.vue";
import PaymentRadio from "./PaymentRadio.vue";
import MainInput from "@/components/UI/controls/form/MainInput.vue";
import ValidationErrorIcon from "@/assets/icons/svg/ValidationErrorIcon.vue";
import { emailValidate } from "@/constants/rules/emailRule";
import { billRadioData } from "../data/payment-data";

import Api from "@/api";

export default {
  name: "BillForm",
  components: {
    ValidationErrorIcon,
    MainButton,
    CancelButton,
    PaymentRadio,
    MainInput,
  },
  data() {
    return {
      variants: billRadioData,
      currentVariant: 0,
      clientInn: "",
      clientName: "",
      clientEmail: "",
      hasEmailError: false,
      emailErrorText: "",
      isLoading: false,
    };
  },
  computed: {
    isClientVerified() {
      return this.clientName.length;
    },
    isCheckInnDisabled() {
      return ![10, 12].includes(this.clientInn.length);
    },
    isRadioDisabled() {
      return !this.clientName.length;
    },
    isBillEnabled() {
      return Boolean(this.currentVariant) && this.clientName.length;
    },
  },
  methods: {
    setInn(value) {
      this.clientInn = value;
      this.clientName = "";
      this.currentVariant = 0;
    },
    setVariant(value) {
      this.currentVariant = value;
    },
    setEmail(value) {
      this.clientEmail = value;
    },
    async verifyInn() {
      if (this.isClientVerified) {
        this.setInn("");
      } else {
        this.isLoading = true;
        try {
          const { data } = await Api.profile.checkBillInn(this.clientInn);
          this.clientName = data.name;
        } catch (error) {
          this.errorInformer(error.data[0].message, 65);
        } finally {
          this.isLoading = false;
        }
      }
    },
    submitBill() {
      if (this.currentVariant === 1) {
        const params = {
          org_inn: this.clientInn,
          org_name: this.clientName,
        };
        this.$emit("download", params);
      } else {
        if (emailValidate(this.clientEmail)) {
          const params = {
            org_inn: this.clientInn,
            org_name: this.clientName,
            email: this.clientEmail,
          };
          this.$emit("send", params);
        } else {
          this.hasEmailError = true;
          this.emailErrorText = this.clientEmail.length
            ? "Некорректный формат адреса"
            : "Укажите электронную почту";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bill {
  width: 100%;

  &__title {
    margin-bottom: 24px;
    color: $mainBlack;
  }

  &__verify {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 16px;
    background: $bckgrnd;
    border-radius: 12px;
    @supports (-webkit-touch-callout: none) {
      @media (max-width: 767px) {
        height: 62px;
      }
    }

    &-input {
      position: relative;
      width: 100%;
      z-index: 1;
    }

    &-button {
      position: absolute !important;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 105px;
      height: 32px !important;
      padding: 0;
      z-index: 3;
    }
  }

  &__client {
    margin-bottom: 16px;
  }

  &__email {
    margin-top: 16px;
  }

  &__validation {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    color: $error;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    &-icon {
      flex-shrink: 0;
    }
    &-text {
      margin-top: 1px;
      margin-left: 2px;
      line-height: 1;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-top: 32px;
    &-item {
      width: 50%;
      @media (max-width: 1279px) {
        width: 100%;
      }
    }
  }
}
</style>
