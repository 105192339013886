import { Request } from "@/core";
import { handbook } from "@/constants/urls";

export default {
  // список терминалов
  getTruckTypes() {
    return Request.get(handbook.getTruckTypes);
  },
  // настройки терминала (поля для получения/подтверждения таймслота)
  getAxisCount() {
    return Request.get(handbook.getAxisCount);
  },
};
