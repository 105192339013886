<template>
  <div id="app">
    <VueExtendLayouts />
  </div>
</template>

<script>
import VueExtendLayouts from "vue-extend-layout";
import layoutWidthMixin from "@/layouts/layoutWidthMixin";
export default {
  name: "App",
  metaInfo: {
    title: "ЭО Порт транзит",
    titleTemplate: "ЭО Порт транзит | %s",
  },
  components: { VueExtendLayouts },
  mixins: [layoutWidthMixin],
};
</script>
