<!--чисто мобильный роут, поэтому нет деления на desktop/mobile-->
<template>
  <section class="mobileContactsView">
    <MobileHeader
      title="Статистика"
      :has-chevron="true"
      @back="$router.push({ name: 'moreMenu' })"
    />

    <StatisticsNovoross />
  </section>
</template>

<script>
import MobileHeader from "@/components/mobileHeader/MobileHeader.vue";
import StatisticsNovoross from "@/components/home/rightSide/StatisticsNovoross.vue";

export default {
  name: "StatisticsView",
  components: { StatisticsNovoross, MobileHeader },
  created() {
    this.checkResolution();
    window.addEventListener("resize", this.checkResolution);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkResolution);
  },
  methods: {
    checkResolution() {
      if (!this.isMobile) {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>
