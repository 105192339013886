<template>
  <section class="font2-16-20">
    <div v-if="showBriefPeriod" class="times">
      <div class="times__item">
        <svg :class="['times__item-icon', fill]">
          <use xlink:href="#dater" />
        </svg>
        <div>{{ dateStart }}</div>
      </div>
      <div class="times__item">
        <svg :class="['times__item-icon', fill]">
          <use xlink:href="#clocker" />
        </svg>
        <div>{{ interval }}</div>
      </div>
    </div>
    <div v-else class="times">
      <div class="times__date">
        <div class="times__item">
          <svg :class="['times__item-icon', fill]">
            <use xlink:href="#dater" />
          </svg>
          <div>{{ dateStart }}</div>
        </div>
        <div class="times__item">
          <svg :class="['times__item-icon', fill]">
            <use xlink:href="#clocker" />
          </svg>
          <div>{{ timeStart }}</div>
        </div>
        <div>-</div>
      </div>
      <div class="times__date">
        <div class="times__item">
          <svg :class="['times__item-icon', fill]">
            <use xlink:href="#dater" />
          </svg>
          <div>{{ dateEnd }}</div>
        </div>
        <div class="times__item">
          <svg :class="['times__item-icon', fill]">
            <use xlink:href="#clocker" />
          </svg>
          <div>{{ timeEnd }}</div>
        </div>
      </div>
    </div>
    <div class="sprite">
      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol
          id="dater"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0833 3.33331H3.91667C3.27233 3.33331 2.75 3.85565 2.75 4.49998V12.6666C2.75 13.311 3.27233 13.8333 3.91667 13.8333H12.0833C12.7277 13.8333 13.25 13.311 13.25 12.6666V4.49998C13.25 3.85565 12.7277 3.33331 12.0833 3.33331Z"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.3333 2.16669V4.50002"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.66669 2.16669V4.50002"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.75 6.83331H13.25"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </symbol>
        <symbol
          id="clocker"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3863_5219)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 4.66669V8.22224L10 10"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3863_5219">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </symbol>
      </svg>
    </div>
  </section>
</template>

<script>
import { parsedDateToTz, DATE_BRIEF, GET_TIME } from "@/core";
import { mapGetters } from "vuex";
import { GET_FULL_TERMINAL_LIST } from "@/store/actions";

export default {
  name: "TimeslotDateTime",
  props: {
    timeslot: {
      type: Object,
      default: () => ({}),
    },
    fill: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({ terminals: GET_FULL_TERMINAL_LIST }),
    currentTerminal() {
      return (
        this.terminals.find(
          (item) => item.id === this.timeslot.terminal_id
        ) || { timezone_utc_offset: 3 }
      );
    },
    dateStart() {
      return parsedDateToTz(
        this.timeslot.date_from,
        this.currentTerminal.timezone_utc_offset,
        DATE_BRIEF
      );
    },
    dateEnd() {
      return parsedDateToTz(
        this.timeslot.date_to,
        this.currentTerminal.timezone_utc_offset,
        DATE_BRIEF
      );
    },
    showBriefPeriod() {
      return this.dateStart === this.dateEnd;
    },
    timeStart() {
      return parsedDateToTz(
        this.timeslot.date_from,
        this.currentTerminal.timezone_utc_offset,
        GET_TIME
      );
    },
    timeEnd() {
      return parsedDateToTz(
        this.timeslot.date_to,
        this.currentTerminal.timezone_utc_offset,
        GET_TIME
      );
    },
    interval() {
      return `${this.timeStart}-${this.timeEnd}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.times {
  display: flex;
  align-items: center;
  gap: 0 8px;
  font-weight: 500;
  color: $hover;
  flex-wrap: wrap;

  &__date {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 2px;
    &-icon {
      width: 16px;
      height: 16px;
      stroke: $hover;
      &.dark {
        stroke: $hover;
      }
    }
  }
}
.sprite {
  display: none;
}
</style>
