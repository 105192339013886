import { getTerminalTimezone } from "@/core";
import moment from "moment-timezone";

moment.locale("ru");
export default moment;

export const textTimezones = {
  2: "Europe/Kaliningrad",
  3: "Europe/Moscow",
  4: "Europe/Astrakhan",
  5: "Asia/Yekaterinburg",
  6: "Asia/Omsk",
  7: "Asia/Novosibirsk",
  8: "Asia/Irkutsk",
  9: "Asia/Chita",
  10: "Asia/Vladivostok",
  11: "Asia/Magadan",
  12: "Asia/Anadyr",
};

// часовой пояс по умолчанию = мск
export const DEFAULT_TIMEZONE = 3;

// используемые форматы дат
export const DATE_BRIEF = "DD.MM.YY";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_FULL = "YYYY-MM-DD HH:mm";
export const GET_TIME = "HH:mm";

export const OPERATION_NEAREST = "DD MMMM, dddd";
export const OPERATION_EARLIER = "DD MMMM YYYY";
export const MILLISECOND_IN_DAY = 1000 * 60 * 60 * 24;

export const parsedDateTime = (date) => {
  let parsed = date ? moment(date) : moment();
  return `${parsed.format("HH:mm")} | ${parsed.format("DD MMMM YYYY")}г.`;
};

export const parsedDateToTz = (date, zone, format) => {
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE]);
  let dateUnix = moment(date).format("X");
  moment.tz.setDefault(textTimezones[zone]);
  let dateTz = moment(dateUnix, "X");

  return dateTz.format(format);
};

export const currentHourTz = () => {
  moment.tz.setDefault(textTimezones[getTerminalTimezone()]);
  return Number(moment().format("HH"));
};

export const unixDate = (date) => Number(moment(date).format("x"));

export const nowUnix = () => unixDate(moment());

export const nowTz = () => {
  moment.tz.setDefault(textTimezones[getTerminalTimezone()]);
  return moment();
};

export const pickerOptions = {
  disabledDate(time) {
    moment.tz.setDefault();
    let terminalDate = moment(time).format(DATE_FORMAT);

    moment.tz.setDefault(textTimezones[getTerminalTimezone()]);

    return unixDate(terminalDate) < nowUnix() - MILLISECOND_IN_DAY;
  },
};

export const isPickerDateToday = (date) => {
  moment.tz.setDefault(textTimezones[getTerminalTimezone()]);

  return date === nowTz().format(DATE_FORMAT);
};

export const defaultFromTzDate = (date) => {
  moment.tz.setDefault(textTimezones[getTerminalTimezone()]);
  let tzDate = moment(date, DATE_FORMAT_FULL).format("x");
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE]);

  return moment(tzDate, "x").format(DATE_FORMAT_FULL);
};

export const addHoursToDate = (date, quantity) => {
  return moment(date, DATE_FORMAT_FULL)
    .add(quantity, "hours")
    .format(DATE_FORMAT_FULL);
};

export const diffDefaultFromNow = (date) => {
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE]);
  return moment(date).diff(moment(), "seconds");
};

export const getCurrentYear = () => {
  return moment().year();
};

export const sendSmsInterval = (from, to) =>
  moment(to).diff(moment(from), "seconds");

export const fromFullToBrief = (date) =>
  moment(date, "DD.MM.YYYY").format(DATE_BRIEF);

export const dateForAccountOperations = (date) => {
  moment.tz.setDefault(textTimezones[DEFAULT_TIMEZONE]);
  let now = moment();
  let operationDate = moment(date, "DD.MM.YYYY");
  if (now.diff(operationDate, "days") < 7) {
    return operationDate.format(OPERATION_NEAREST);
  } else {
    return operationDate.format(OPERATION_EARLIER) + " г.";
  }
};
