<template>
  <div class="culture">
    <div class="culture__icon">
      <img src="@/assets/images/Terminal.svg" alt="icon" />
    </div>
    <div class="font2-14-18 culture__name">
      {{ terminalName }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeslotTerminalName",
  props: {
    terminalName: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.culture {
  display: flex;
  align-items: center;
  &__icon {
    margin-right: 10px;
    margin-bottom: 3px;
  }
  &__name {
    font-weight: 500;
    color: $mainBlack;
  }
}
</style>
