<template>
  <section class="cabinet">
    <div class="font1-18-24 cabinet__header">Профиль</div>

    <div class="cabinet__content">
      <div class="cabinet__content-profile">
        <CabinetProfile v-if="user" />
      </div>

      <div class="cabinet__content-account">
        <CabinetAccount :user="user" class="account" />

        <CabinetOperations />
      </div>
    </div>
  </section>
</template>

<script>
import CabinetAccount from "@/components/cabinet/CabinetAccount";
import CabinetOperations from "@/components/cabinet/CabinetOperations/CabinetOperations.vue";
import cabinetMixin from "@/views/CabinetView/cabinetMixin";

export default {
  name: "DesktopCabinetView",
  components: { CabinetAccount, CabinetOperations },
  mixins: [cabinetMixin],
};
</script>

<style lang="scss" scoped>
.cabinet {
  height: 100%;
  &__header {
    display: flex;
    align-items: center;
    height: 64px;
    padding-left: 32px;
    border-bottom: 1px solid $backgroundGray;
    background: #fff;
    font-weight: 500;
    color: $mainBlack;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    padding: 32px;
    &-profile {
      width: 32%;
    }
    &-account {
      width: 66%;
      .account {
        margin-bottom: 24px;
      }
    }
  }
}
@import "@/assets/scss/common/custom-pagination.scss";
</style>
