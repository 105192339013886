export const truckTabs = [
  {
    name: "Списком",
    value: 0,
  },
  {
    name: "На карте",
    value: 1,
  },
];
