import UnpaidHeader from "@/components/unpaid/UnpaidHeader.vue";
import UnpaidSearchTruck from "@/components/unpaid/UnpaidSearchTruck.vue";
import UnpaidTrucksList from "@/components/unpaid/UnpaidTrucksList.vue";
export default {
  components: {
    UnpaidHeader,
    UnpaidSearchTruck,
    UnpaidTrucksList,
  },
  data() {
    return {
      tabItems: ["Поиск авто", "Мои авто"],
      activeTab: "",
    };
  },
  computed: {
    isSearchMode() {
      return this.activeTab === this.tabItems[0];
    },
  },
  created() {
    this.changeTab(this.tabItems[0]);
  },
  methods: {
    changeTab(value) {
      this.activeTab = value;
    },
  },
};
