<template>
  <div class="request" v-loading="loading">
    <el-form>
      <el-form-item class="request__datepicker">
        <DatePicker
          :date="newTimeslotDate.newDate"
          :options="pickerOptions"
          placeholder="Дата прибытия"
          @select="setStartDate"
        />
      </el-form-item>

      <el-form-item class="request__timepicker">
        <TimeSelect
          :time="newTimeslotDate.newTime"
          :options="timeOptions"
          :disabled="isTimeDisabled"
          placeholder="Время прибытия"
          @select="setStartTime"
        />
      </el-form-item>

      <el-collapse-transition>
        <div v-if="notFoundQuota" class="quota-error">
          <img src="@/assets/images/red-attention.svg" alt="error" />
          <span class="quota-error__text font2-14-18">
            Нет подходящих квот, измените данные
          </span>
        </div>
      </el-collapse-transition>

      <div class="form__buttons">
        <CancelButton
          v-if="!isMobile"
          title="Отменить"
          class="form__buttons-item"
          @button-clicked="$emit('close')"
        />

        <MainButton
          title="Запросить таймслот"
          :disabled="!isButtonEnabled"
          class="form__buttons-item"
          @button-clicked="tryReschedule"
        />
      </div>
    </el-form>
  </div>
</template>

<script>
import DatePicker from "@/components/UI/controls/form/DatePicker";
import MainButton from "@/components/UI/controls/buttons/MainButton";
import CancelButton from "@/components/UI/controls/buttons/CancelButton.vue";
import TimeSelect from "@/components/UI/controls/form/TimeSelect";
import {
  currentHourTz,
  defaultFromTzDate,
  pickerOptions,
  isPickerDateToday,
} from "@/core";
import Api from "@/api";

export default {
  name: "RescheduleRequestForm",
  components: {
    DatePicker,
    MainButton,
    CancelButton,
    TimeSelect,
  },
  props: {
    timeslot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      newTimeslotDate: {
        newDate: "",
        newTime: "",
      },
      notFoundQuota: false,
      loading: false,
      pickerOptions,
    };
  },
  computed: {
    // опции селекта выбора времени
    timeOptions() {
      if (!this.newTimeslotDate.newDate) {
        return [];
      }
      let options = [];
      let startHour = isPickerDateToday(this.newTimeslotDate.newDate)
        ? currentHourTz()
        : 0;
      for (let i = startHour; i < 24; i++) {
        let start = i < 10 ? "0" + String(i) : i;
        let finish = i + 1 < 10 ? "0" + String(i + 1) : i + 1;
        options.push({
          value: `${start}:00-${finish}:00`,
          label: `${start}:00-${finish}:00`,
        });
      }
      return options;
    },
    // флаг запрета выбора времени
    isTimeDisabled() {
      return !this.newTimeslotDate.newDate;
    },
    // возможность отправки запроса
    isButtonEnabled() {
      return this.newTimeslotDate.newDate && this.newTimeslotDate.newTime;
    },
  },
  methods: {
    setStartDate(value) {
      this.newTimeslotDate.newDate = value;
    },
    setStartTime(value) {
      this.newTimeslotDate.newTime = value;
    },
    async tryReschedule() {
      this.loading = true;
      try {
        let request = new FormData();
        request.append("timeslot_id", this.timeslot.id);
        request.append("terminal_id", this.timeslot.terminal_id);
        request.append(
          "time",
          defaultFromTzDate(
            `${
              this.newTimeslotDate.newDate
            } ${this.newTimeslotDate.newTime.slice(0, 6)}`
          )
        );
        // эти два поля нужны только для терминалов Новороссийска
        request.append("exporter_id", this.timeslot.exporter_id);
        request.append("culture_id", this.timeslot.culture_id);

        const { data } = await Api.timeslots.rescheduleTimeslot(request);
        this.$emit("found", data);
      } catch (error) {
        this.errorInformer(error.data[0].message, 65);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.request {
  &__datepicker {
    border-radius: 10px;
    margin-bottom: 24px;
    &.is-error {
      border: 1px solid $error;
      :deep
        .el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date
        .el-input__prefix {
        background: #f8edee;
        .el-input__icon {
          color: $error;
        }
      }
    }
    :deep .el-date-editor.el-input {
      width: 100%;
    }
  }
  .quota-error {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &__text {
      margin-left: 4px;
      color: $error;
    }
  }
  &__timepicker {
    margin-bottom: 24px;
    border-radius: 10px;
    &.is-error {
      border: 1px solid $error;
    }
  }
}
</style>
