<template>
  <section>
    <UnpaidHeader
      :tab-items="tabItems"
      :active="activeTab"
      @change="changeTab"
    />

    <UnpaidSearchTruck v-if="isSearchMode" />

    <UnpaidTrucksList v-else />
  </section>
</template>

<script>
import unpaidMixin from "@/views/UnpaidView/unpaidMixin";
export default {
  name: "DesktopUnpaidView",
  mixins: [unpaidMixin],
};
</script>
