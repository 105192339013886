<template>
  <section :class="styles === 'gray' ? 'font2-14-18' : 'font2-16-20'">
    <div :class="['times', styles]">
      <div class="times__item">
        <svg class="times__item-icon">
          <use xlink:href="#dater" />
        </svg>
        <div>{{ dateStart }}</div>
      </div>
      <div class="times__item">
        <svg class="times__item-icon">
          <use xlink:href="#clocker" />
        </svg>
        <div>{{ interval }}</div>
      </div>
    </div>
    <div class="sprite">
      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol
          id="dater"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0833 3.33331H3.91667C3.27233 3.33331 2.75 3.85565 2.75 4.49998V12.6666C2.75 13.311 3.27233 13.8333 3.91667 13.8333H12.0833C12.7277 13.8333 13.25 13.311 13.25 12.6666V4.49998C13.25 3.85565 12.7277 3.33331 12.0833 3.33331Z"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.3333 2.16669V4.50002"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.66669 2.16669V4.50002"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.75 6.83331H13.25"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </symbol>
        <symbol
          id="clocker"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_3863_5219)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 4.66669V8.22224L10 10"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_3863_5219">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </symbol>
      </svg>
    </div>
  </section>
</template>

<script>
import { addHoursToDate, parsedDateToTz, DATE_BRIEF, GET_TIME } from "@/core";
import { mapGetters } from "vuex";
import { GET_FULL_TERMINAL_LIST } from "@/store/actions";

export default {
  name: "RequestDateTime",
  props: {
    timeslot: {
      type: Object,
      default: () => ({}),
    },
    styles: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({ terminals: GET_FULL_TERMINAL_LIST }),
    currentTerminal() {
      return (
        this.terminals.find(
          (item) => item.id === this.timeslot.terminal_id
        ) || { timezone_utc_offset: 3 }
      );
    },
    // date from RU into EURO
    formattedDate() {
      return this.timeslot.date.split(".").reverse().join("-");
    },
    dateStart() {
      return parsedDateToTz(
        `${this.formattedDate} ${this.timeslot.time}`,
        this.currentTerminal.timezone_utc_offset,
        DATE_BRIEF
      );
    },
    timeStart() {
      return parsedDateToTz(
        `${this.formattedDate} ${this.timeslot.time}`,
        this.currentTerminal.timezone_utc_offset,
        GET_TIME
      );
    },
    timeEnd() {
      return parsedDateToTz(
        addHoursToDate(`${this.formattedDate} ${this.timeslot.time}`, 1),
        this.currentTerminal.timezone_utc_offset,
        GET_TIME
      );
    },
    interval() {
      return `${this.timeStart}-${this.timeEnd}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.times {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: $textSecondary;

  &__item {
    display: flex;
    align-items: center;
    gap: 2px;
    &-icon {
      width: 16px;
      height: 16px;
      stroke: $textSecondary;
    }
  }

  &.gray {
    color: $greyText;
    .times__item-icon {
      stroke: $greyText;
    }
  }
}
.sprite {
  display: none;
}
</style>
