<template>
  <div class="empty">
    <div class="empty__icon">
      <img
        v-if="isAfterSearch"
        src="@/assets/images/no-timeslots.svg"
        alt="empty"
      />
      <img v-else src="@/assets/images/Search.svg" alt="empty" />
    </div>
    <div class="font2-16-22 empty__text">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "ArchiveEmpty",
  props: {
    isAfterSearch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.isAfterSearch
        ? "По заданным параметрам не найдено таймслотов"
        : "Для просмотра истории таймслотов выберите период и терминал";
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 235px;
  @media (max-width: 1279px) {
    padding-top: 65px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 66px;
    height: 66px;
    background: #fff;
    border-radius: 50%;
  }

  &__text {
    width: 320px;
    color: $greyText;
    text-align: center;
  }
}
</style>
