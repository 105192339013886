<template>
  <section class="header">
    <div
      v-for="item in truckTabs"
      :key="item.name"
      :class="['font2-14-18 header__item', { active: active === item.value }]"
      @click="$emit('change', item.value)"
    >
      {{ item.name }}
    </div>
  </section>
</template>

<script>
import { truckTabs } from "@/constants/trucks-tabs";

export default {
  name: "MobileTrucksTabs",
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      truckTabs,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: flex-end;
  height: 48px;
  background: #fff;
  border-bottom: 1px solid $backgroundGray;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 38px;
    font-size: clamp(14px, 1.2rem, 16px);
    font-weight: 700;
    color: $greyText;
    border-bottom: 2px solid transparent;
    &.active {
      color: $mainBlack;
      border-bottom: 2px solid $special;
    }
  }
}
</style>
