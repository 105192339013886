<template>
  <div :class="['font2-12-16 badge', styles]">{{ title }}</div>
</template>

<script>
export default {
  name: "TimeslotStatus",
  props: {
    title: {
      type: String,
      default: "",
    },
    styles: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 10px;
  font-weight: 700;
  border-radius: 20px;

  &.special {
    color: $special;
    background: $specialBackground;
  }
  &.green {
    color: $success;
    background: $successBackground;
  }
  &.red {
    color: $errorHover;
    background: $errorBackground;
  }
  &.purple {
    color: $select;
    background: $purpleBackground;
  }
  &.grey {
    color: $textSecondary;
    background: $greyBackground;
  }
}
</style>
