import TimerButton from "@/components/UI/controls/buttons/TimerButton.vue";
import ValidationErrorIcon from "@/assets/icons/svg/ValidationErrorIcon.vue";
import {
  getUserPhone,
  setUserPhone,
  removeUserPhone,
  sendSmsInterval,
  setUserAccess,
} from "@/core";
import { AuthCodeLength } from "@/constants/auth";

import API from "@/api";

export default {
  components: {
    TimerButton,
    ValidationErrorIcon,
  },
  data() {
    return {
      codeLength: AuthCodeLength,
      validResponse: true,
      trueCode: "",
      phone: "",
      timer: 0,
      hasSuccess: false,
      mobileHasError: false,
      isLoading: false,
    };
  },
  computed: {
    parsedPhone() {
      return `+7 (${this.phone.slice(1, 4)})-${this.phone.slice(
        4,
        7
      )}-${this.phone.slice(7, 9)}-${this.phone.slice(9)}`;
    },
  },
  created() {
    if (!getUserPhone()) {
      this.$router.push({ name: "login" });
    } else {
      this.initCode();
    }
  },
  methods: {
    initCode() {
      this.trueCode = getUserPhone().code;
      this.phone = getUserPhone().phone;
      this.timer = sendSmsInterval(
        getUserPhone().code_sent_at,
        getUserPhone().sms_blocked_until
      );
      this.successInformer("Код подтверждения отправлен", 15);
    },

    async dispatchCode(code) {
      this.isLoading = true;
      const user = { code, phone: this.phone };

      try {
        const { data } = await API.auth.getToken(user);
        setUserAccess(data);
        this.hasSuccess = true;
        removeUserPhone();
        setTimeout(() => {
          this.$router.push({ name: "home" });
        }, 300);
      } catch (err) {
        console.log(err);
        if (err.status === 422) {
          this.validResponse = false;
          this.mobileHasError = true;
        }
      } finally {
        this.isLoading = false;
      }
    },

    async getCodeAgain() {
      this.isLoading = true;
      const phone = new FormData();
      phone.append("phone", this.phone);
      try {
        this.timer = 0;
        const { data } = await API.auth.dispatchPhone(phone);
        setUserPhone(data);
        this.initCode();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    backToNumber() {
      removeUserPhone();
      this.$router.push({ name: "login" });
    },
  },
};
