<template>
  <ModalWrapper @close="$emit('close')">
    <div class="content">
      <div class="font1-24-30 content__title">
        Политика конфиденциальности и Оферта
      </div>
      <div class="font2-16-20 content__terms">
        Выбрав вариант “Принимаю”, вы соглашаетесь с
        <a href="/privacy-policy" class="content__terms-link" target="_blank">
          Политикой конфиденциальности
        </a>
        приложения и подтверждаете, что ознакомлены с
        <a href="/public-oferta" class="content__terms-link" target="_blank">
          Офертой
        </a>
      </div>

      <MainButton
        title="Принимаю"
        :styles="isMobile ? 'mobile' : ''"
        @button-clicked="$emit('submit')"
      />
    </div>
  </ModalWrapper>
</template>

<script>
import ModalWrapper from "@/components/UI/ModalWrapper.vue";
import privacyMixin from "@/components/UI/modals/PrivacyPolicy/privacyMixin";

export default {
  name: "PrivacyPolicyModal",
  components: { ModalWrapper },
  mixins: [privacyMixin],
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 440px;

  &__title {
    margin-bottom: 12px;
    padding-right: 20px;
    font-weight: 500;
    color: $mainBlack;
  }

  &__terms {
    margin-bottom: 32px;
    padding-right: 20px;
    font-weight: 500;
    color: $textSecondary;
    &-link {
      font-family: $fontPrimary;
      color: $special;
    }
  }
}
</style>
