<template>
  <section class="unpaid" v-loading="isLoading">
    <div v-if="trucksList.length" class="unpaid__body">
      <PayAllTimeslots
        v-if="unpaid.count"
        class="unpaid__total"
        :unpaid-text="unpaidText"
        @mass-pay="preparePayment(null, unpaid.timeslots, unpaid.sum)"
      />

      <UnpaidTruckCard
        v-for="truck in trucksList"
        :key="truck.plate_truck"
        :ref="truck.plate_truck"
        :truck="truck"
        @toggle-favorites="beforeToggleFavorites(truck)"
        @pay="preparePayment(truck.plate_truck, ...arguments)"
      />
    </div>

    <EmptyUnpaid
      v-if="!trucksList.length && !isLoading"
      title="У вас нет добавленных в Избранные машин"
    />

    <TwoButtonsModal
      v-if="openFavoritesModal"
      title="Удалить из Избранных?"
      description="Данные по авто не будут отображаться в этом списке"
      left-button="Отмена"
      right-button="Удалить"
      :success="false"
      @close="cancelToggleFavorites"
      @accept="toggleFavorites"
    />

    <ConfirmPaymentModal
      v-if="openPaymentModal"
      :sum-text="paymentSumText"
      @close="openPaymentModal = false"
      @accept="payTimeslots"
    />

    <NoMoneyModal v-if="notEnoughMoney" @close="notEnoughMoney = false" />
  </section>
</template>

<script>
import EmptyUnpaid from "@/components/unpaid/EmptyUnpaid.vue";
import PayAllTimeslots from "@/components/timeslots/common/PayAllTimeslots.vue";
import UnpaidTruckCard from "@/components/unpaid/UnpaidTruckCard.vue";
import ConfirmPaymentModal from "@/components/UI/modals/ConfirmPaymentModal.vue";
import NoMoneyModal from "@/components/UI/modals/NoMoneyModal.vue";
import { formattedNumberRu } from "@/core";
import { mapActions } from "vuex";
import { FETCH_PROFILE } from "@/store/actions";

import Api from "@/api";
import TwoButtonsModal from "@/components/UI/modals/TwoButtonsModal.vue";
export default {
  name: "UnpaidTrucksList",
  components: {
    TwoButtonsModal,
    NoMoneyModal,
    ConfirmPaymentModal,
    UnpaidTruckCard,
    PayAllTimeslots,
    EmptyUnpaid,
  },
  data() {
    return {
      trucksList: [],
      unpaid: {
        count: 0,
        sum: 0,
        timeslots: [],
      },
      paidTimeslots: [],
      paymentSumText: "",
      activePlateTruck: null,
      isLoading: false,
      openPaymentModal: false,
      openFavoritesModal: false,
      notEnoughMoney: false,
    };
  },
  computed: {
    unpaidText() {
      return this.unpaid.count
        ? `${this.unpaid.count} шт | Сумма: ${formattedNumberRu(
            this.unpaid.sum / 100
          )}₽`
        : "";
    },
  },
  async created() {
    await this.getTrucksList();
  },
  methods: {
    ...mapActions({
      refreshBalance: FETCH_PROFILE,
    }),
    // загрузка списка избранных
    async getTrucksList() {
      try {
        this.isLoading = true;
        const { data } = await Api.unpaid.getTrucksList();
        this.trucksList = data.items;

        // считаем неоплаченные ТС
        this.unpaid = {
          count: 0,
          sum: 0,
          timeslots: [],
        };
        this.trucksList.forEach((truck) => {
          truck.timeslots.forEach((timeslot) => {
            this.unpaid.count++;
            this.unpaid.sum += timeslot.timeslot_price_cop;
            this.unpaid.timeslots.push({
              terminal_id: timeslot.terminal_id,
              timeslot_id: timeslot.id,
            });
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    // обработка удаления из Избранных
    beforeToggleFavorites(truck) {
      this.activePlateTruck = truck;
      this.openFavoritesModal = true;
    },
    cancelToggleFavorites() {
      this.activePlateTruck = null;
      this.openFavoritesModal = false;
    },
    async toggleFavorites() {
      try {
        this.isLoading = true;
        await Api.unpaid.removeFromFavorites({
          plate_truck: this.activePlateTruck.plate_truck,
        });
        this.activePlateTruck = null;
        await this.getTrucksList();
      } catch (error) {
        console.log(error.data);
      } finally {
        this.isLoading = false;
        this.openFavoritesModal = false;
      }
    },
    // обработка списания за таймслот(-ы)
    preparePayment(plateTruck, timeslots, sum) {
      this.paidTimeslots = timeslots;
      this.paymentSumText = `${formattedNumberRu(sum / 100)} ₽`;
      this.openPaymentModal = true;
      this.activePlateTruck = plateTruck;
    },
    async payTimeslots() {
      try {
        this.openPaymentModal = false;
        this.isLoading = true;
        // оплачиваем таймслоты
        await Api.timeslots.payTimeslots(this.paidTimeslots);
        // обновляем остаток баланса
        await this.refreshBalance();
        // перезагружем список машин
        await this.getTrucksList();
        let info =
          this.paidTimeslots.length > 1
            ? "Таймслоты успешно оплачены"
            : "Таймслот успешно оплачен";
        this.successInformer(info, 65);
        // если оплачивался конкретный таймслот/машина - прокручиваем к этой машине
        this.$nextTick(() => {
          if (this.activePlateTruck) {
            let el = this.$refs[this.activePlateTruck];
            el[0].$el.scrollIntoView({ behavior: "smooth", block: "center" });
            this.activePlateTruck = null;
          }
        });
      } catch (error) {
        if (error.data[0].field === "balance") {
          this.notEnoughMoney = true;
        } else if (error.data[0].field === "result") {
          // обработка варианта, при котором кто-то уже оплатил таймлот(-ы),
          // а мы с тех пор не обновляли экран
          // обновляем остаток баланса
          await this.refreshBalance();
          // перезагружем список машин
          await this.getTrucksList();
          this.$nextTick(() => {
            if (this.activePlateTruck) {
              let el = this.$refs[this.activePlateTruck];
              if (el)
                el[0].$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              this.activePlateTruck = null;
            }
          });
          this.errorInformer(error.data[0].message, 65);
        } else {
          this.errorInformer(error.data[0].message, 65);
        }
      } finally {
        this.isLoading = false;
        this.paidTimeslots = [];
        this.paymentSumText = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unpaid {
  padding-top: 64px;
  @media (max-width: 1279px) {
    padding-top: 96px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 32px;
    @media (max-width: 1279px) {
      padding: 24px 16px 102px;
    }
  }
}
</style>
