<template>
  <section class="timeslots">
    <div class="timeslots__header">
      <div class="font1-20-24 timeslots__header-title">
        <div>Таймслоты</div>
        <div v-if="timeslotsCount" class="font2-12-16 badge">
          {{ timeslotsCount }}
        </div>
      </div>
      <div v-if="haveTimeslots" class="swiper-control">
        <div slot="button-prev" class="swiper-button-prev">
          <svg class="button__svg">
            <use xlink:href="#left-angle" />
          </svg>
        </div>
        <div slot="button-next" class="swiper-button-next">
          <svg class="button__svg">
            <use xlink:href="#right-angle" />
          </svg>
        </div>
      </div>
    </div>
    <div v-if="haveTimeslots">
      <swiper :options="swiperOptions" class="timeslots__swiper">
        <swiper-slide
          v-for="(timeslot, index) in preparedTimeslots"
          :key="index"
        >
          <TimeslotCard
            :timeslot="timeslot"
            class="timeslots__swiper-slide"
            @toTimeslots="toTimeslots(timeslot.id)"
          />
        </swiper-slide>
      </swiper>
    </div>

    <!--  нет забронированных таймслотов  -->
    <div v-else class="timeslots__nothing">
      <div class="timeslots__nothing-icon">
        <img src="@/assets/images/no-timeslots.svg" alt="nothing" />
      </div>
      <div class="font2-16-20 timeslots__nothing-text">
        У вас пока нет забронированных таймслотов
      </div>
      <div class="timeslots__nothing-description">
        Оформите запрос на получение ТС
      </div>
    </div>

    <div class="timeslots__sprite">
      <svg xmlns="http://www.w3.org/2000/svg">
        <symbol id="left-angle" viewBox="0 0 24 24" fill="none">
          <path
            d="M16 20L8 12L16 4"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </symbol>
        <symbol id="right-angle" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_3231_1373)">
            <path
              d="M8 20L16 12L8 4"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </symbol>
      </svg>
    </div>
  </section>
</template>

<script>
import TimeslotCard from "@/components/timeslots/list/TimeslotCard.vue";
import { setActiveTimeslot } from "@/core";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "HomeTimeslots",
  components: {
    Swiper,
    SwiperSlide,
    TimeslotCard,
  },
  props: {
    timeslots: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 20,
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        },
        breakpoints: {
          375: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          800: {
            slidesPerView: 2.1,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1700: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          2000: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      },
    };
  },
  computed: {
    haveTimeslots() {
      return this.timeslots.length;
    },
    timeslotsCount() {
      if (!this.timeslots.length) {
        return 0;
      }
      return this.timeslots.reduce(
        (sum, item) => (sum += item.timeslots_count),
        0
      );
    },
    preparedTimeslots() {
      let timeslotsList = [];
      this.timeslots.forEach((terminal) => {
        terminal.list.forEach((timeslot) => timeslotsList.push(timeslot));
      });
      return timeslotsList;
    },
  },
  methods: {
    toTimeslots(id) {
      setActiveTimeslot(id);
      this.$router.push({ name: "timeslots" });
    },
  },
};
</script>

<style lang="scss" scoped>
.timeslots {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    &-title {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: $mainBlack;
      .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 16px;
        padding: 2px 3px 0;
        margin-left: 8px;
        font-weight: 700;
        background: $special;
        color: #fff;
        border-radius: 8px;
      }
    }
  }
  .swiper-control {
    display: flex;
    justify-content: space-between;
    width: 64px;
    height: 28px;
    .swiper-button-next,
    .swiper-button-prev {
      position: relative;
      top: 0;
      right: 0;
      left: auto;
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      background: #fff;
      border-radius: 8px;
      &::after {
        display: none;
      }
      .button__svg {
        width: 16px;
        height: 16px;
        stroke: $mainBlack;
      }
      &:hover {
        background: $special;
        .button__svg {
          stroke: #fff;
        }
      }
    }
    .swiper-button-disabled {
      opacity: 1;
      cursor: default;
      .button__svg {
        stroke: $greyBanner;
      }
    }
  }

  &__swiper-slide {
    cursor: pointer;
  }

  &__nothing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    height: 192px;
    background: #fff;
    border-radius: 20px;
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      background: $bckgrnd;
      border-radius: 50%;
    }
    &-text {
      width: 300px;
      color: $greyText;
      text-align: center;
    }
    &-description {
      display: none;
    }
    &-button {
      width: clamp(180px, 20%, 250px);
    }
    &-buttonMobile {
      display: none !important;
    }
  }
  &__sprite {
    display: none;
  }
}

@media (max-width: 600px) {
  .timeslots {
    &__header {
      .swiper-control {
        display: none;
      }
    }
  }
}
@media (max-width: 500px) {
  .timeslots {
    &__nothing {
      gap: 4px;
      height: 94px;
      padding: 0 16px;
      &-icon {
        display: none;
      }
      &-text {
        width: 100%;
        text-align: left;
        font-weight: 700;
        font-size: 16px;
        color: $textSecondary;
      }
      &-description {
        align-self: flex-start;
        display: block;
        color: $greyText;
        font-weight: 500;
        font-size: 14px;
      }
      &-button {
        display: none;
      }
      &-buttonMobile {
        display: block !important;
      }
    }
  }
}
</style>
